import styled from 'styled-components';

import TextS from './TextS';
import TextXS from './TextXS';
import Icon from './Icon';
import { isNumeric } from './helpers';

import { Card } from 'components/common';
import { colors } from 'styles';

const neutralColors = [
  'transparent',
  'white',
  'tint',
  'gray2',
  'gray2',
  'gray4',
  'gray6',
  'gray6',
  'gray8'
];

const SmallCard = ({
  color,
  progress,
  progressColor,
  leftComponent,
  avatarComponent,
  title,
  subtitle,
  subtitleIconName,
  rightComponentOne,
  rightComponentTwo,
  onClick,
  selected,
  level,
  className
}) => {
  let progressValue = null;
  if (isNumeric(progress) && progress >= 0 && progress <= 100) {
    progressValue = progress;
  } else if (progress !== undefined) {
    console.error(
      'Progress prop in SmallCard component needs to be a number from 0 to 100'
    );
  }

  const hasSideIndicator =
    leftComponent &&
    leftComponent.type &&
    leftComponent.type.displayName === 'SideIndicator';

  const progressComponent = progressValue !== null && (
    <ProgressContainer
      progress={progressValue}
      progressColor={progressColor}
      hasSideIndicator={hasSideIndicator}
    />
  );

  return (
    <StyledCard
      onClick={onClick}
      selected={selected}
      level={level}
      color={color}
      className={className}>
      {/* SideIndicator is the only component which doesn't need the margin */}
      {leftComponent && (
        <Margin right={hasSideIndicator ? 0 : 1.5}>
          <Left>{leftComponent}</Left>
        </Margin>
      )}

      <Center>
        {avatarComponent && <Margin right={1}>{avatarComponent}</Margin>}
        <Column>
          {typeof title === 'string' ? (
            <TextS
              color={
                color && !neutralColors.includes[color] ? 'white' : 'bodyColor'
              }>
              {title}
            </TextS>
          ) : (
            React.cloneElement(title, { textColor: color })
          )}

          {subtitle && subtitleIconName && (
            <IconSubtitleContainer>
              <IconContainer>
                <Icon
                  name={subtitleIconName}
                  color={
                    color && !neutralColors.includes[color]
                      ? `${color}`
                      : 'gray4'
                  }
                />
              </IconContainer>
              <SubtitleContainer>
                <TextXS
                  color={
                    color && !neutralColors.includes[color]
                      ? `${color}`
                      : 'gray4'
                  }>
                  {subtitle}
                </TextXS>
              </SubtitleContainer>
            </IconSubtitleContainer>
          )}

          {subtitle &&
            !subtitleIconName &&
            (typeof subtitle === 'string' ? (
              <TextXS
                color={
                  color && !neutralColors.includes[color] ? `${color}` : 'gray6'
                }>
                {subtitle}
              </TextXS>
            ) : (
              React.cloneElement(subtitle, { textColor: color })
            ))}
        </Column>
      </Center>

      {rightComponentOne && rightComponentTwo && (
        <Margin right={0.5}>
          <Right>{rightComponentTwo}</Right>
        </Margin>
      )}
      {rightComponentOne && <Right>{rightComponentOne}</Right>}
      {progressComponent}
    </StyledCard>
  );
};

const ProgressContainer = styled.div`
  position: absolute;
  height: 0.5rem;
  width: ${({ progress, hasSideIndicator }) =>
    hasSideIndicator && progress > 50
      ? `calc(${progress}% - 0.5rem)`
      : `${progress}%`};
  background-color: ${({ progressColor }) =>
    progressColor ? colors[`${progressColor}`] : colors['info']};
  left: ${({ hasSideIndicator }) => (hasSideIndicator ? '0.5rem' : '0rem')};
  bottom: 0;
`;

const boxShadowLevel0 = 'var(--shadow)';
const boxShadowLevel0hover = 'var(--shadow4)';
const boxShadowLevel1 = 'var(--shadow2)';
const boxShadowLevel2 = 'var(--shadow3)';
const boxShadowLevel3 = 'var(--shadow4)';

SmallCard.Title = ({ textColor, children }) => (
  <StyledTitle textColor={textColor}>
    {React.Children.map(children, child =>
      child.type === SmallCard.TitleLink
        ? React.cloneElement(child, { textColor: textColor })
        : child
    )}
  </StyledTitle>
);

SmallCard.Subtitle = ({ textColor, children }) => (
  <StyledSubtitle textColor={textColor}>
    {React.Children.map(children, child =>
      child.type === SmallCard.SubtitleLink
        ? React.cloneElement(child, { textColor: textColor })
        : child
    )}
  </StyledSubtitle>
);

const StyledTitle = styled(TextS)`
  color: ${({ textColor }) =>
    textColor && !neutralColors.includes[textColor]
      ? 'var(--white)'
      : colors.bodyText};
`;

const StyledSubtitle = styled(TextXS)`
  color: ${({ textColor }) =>
    textColor && !neutralColors.includes[textColor]
      ? colors[`${textColor}`]
      : 'var(--gray-light)'};
`;

SmallCard.TitleLink = styled.span`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
  font-weight: 500;
  color: ${({ textColor }) =>
    textColor && !neutralColors.includes[textColor]
      ? 'var(--white)'
      : colors.bodyText};

  &:hover {
    color: ${({ textColor, onClick }) =>
      textColor && !neutralColors.includes[textColor]
        ? 'var(--white)'
        : !onClick
        ? 'var(--gray)'
        : 'var(--gray-dark)'};
  }
`;

SmallCard.SubtitleLink = styled.span`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
  font-weight: 500;
  color: ${({ textColor }) =>
    textColor && !neutralColors.includes[textColor]
      ? colors[`${textColor}`]
      : 'var(--gray-light)'};
  border-bottom: 2px solid
    ${({ textColor }) =>
      textColor ? colors[`${textColor}`] : 'var(--gray-lighter)'};

  &:hover {
    color: ${({ textColor, onClick }) =>
      textColor && !neutralColors.includes[textColor]
        ? 'var(--white)'
        : !onClick
        ? 'var(--gray)'
        : 'var(--gray-dark)'};
    border-bottom: 2px solid
      ${({ textColor }) => (textColor ? 'var(--white)' : 'var(--gray)')};
  }
`;

SmallCard.Separator = () => <span> &bull; </span>;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
  min-height: 6rem;
  height: 6rem;
  box-shadow: ${({ level }) => {
    switch (level) {
      case 0:
        return boxShadowLevel0;
      case 1:
        return boxShadowLevel1;
      case 2:
        return boxShadowLevel2;
      case 3:
        return boxShadowLevel3;
      default:
        return boxShadowLevel0;
    }
  }};
  border: ${({ selected }) =>
    selected ? `2px solid var(--gray)` : `1px solid var(--border-color)`};
  background: ${({ color }) => {
    if (color) {
      return colors[color];
    }
    return 'var(--white)';
  }};
  padding: 1rem 1.5rem;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};

  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  -webkit-transition: all 2.5s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1);
  /* easeOutQuint */
  -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  /* easeOutQuint */

  &:hover {
    box-shadow: ${({ level }) =>
      level === 0 ? boxShadowLevel0hover : boxShadowLevel3};
    z-index: ${({ level }) => (level !== 0 ? 1 : 0)};
    transform: ${({ level }) =>
      level !== 0 ? 'translateY(-0.25rem)' : 'translateY(0rem)'};
  }
`;

const Left = styled.div`
  display: flex;
  background-color: ${({ color }) => colors[color]};
  align-self: center;
`;

const Center = styled.div`
  display: flex;
  flex: 1;
  background-color: ${({ color }) => colors[color]};
  align-self: center;
  padding-right: 1.5rem;
  overflow: hidden;
`;

const Right = styled.div`
  display: flex;
  background-color: ${({ color }) => colors[color]};
  align-self: center;
`;

const Column = styled.div`
  align-self: center;
  flex-direction: column;
`;

const Margin = styled.div`
  align-self: center;
  margin: ${({ top, right, bottom, left }) =>
    `${top || 0}rem ${right || 0}rem ${bottom || 0}rem ${left || 0}rem`};
`;

const IconSubtitleContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

const SubtitleContainer = styled.div`
  margin-left: 1.25rem;
`;

const IconContainer = styled.div`
  position: absolute;
  left: -0.25rem;
`;

SmallCard.defaultProps = {
  title: '',
  subtitle: ''
};

export default SmallCard;
