const initialState = {
  tubSearchQuery: '',
  selectedTopicUnitId: null
};

const tub = (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_TUB_SEARCH_QUERY':
      return {
        ...state,
        tubSearchQuery: action.searchQuery
      };
    case 'SET_SELECTED_TOPIC_UNIT':
      return {
        ...state,
        selectedTopicUnitId: action.selectedTopicUnitId
      };
    default:
      return state;
  }
};

export default tub;
