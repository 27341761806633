import * as React from 'react';
const SvgPartialTick = ({ size = '16', color, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.404 8.26c.494.367.573 1.038.176 1.496l-5.364 6.18a1.182 1.182 0 0 1-.844.396 1.2 1.2 0 0 1-.88-.324l-3.217-3.09a1.01 1.01 0 0 1 .03-1.505 1.216 1.216 0 0 1 1.625.027l2.312 2.22 4.545-5.238c.398-.458 1.121-.531 1.617-.163Z"
      fill={color ?? 'currentColor'}
    />
    <path
      d="M11.41 23.399c0 .289.228.527.517.527 6.587 0 11.927-5.34 11.927-11.926 0-6.587-5.34-11.926-11.927-11.926-.29 0-.52.24-.52.53v.95c0 .283.237.507.52.507 5.49 0 9.939 4.45 9.939 9.939s-4.45 9.939-9.939 9.939a.514.514 0 0 0-.52.508l.002.952Z"
      fill={color ?? 'currentColor'}
    />
    <circle cx={7.182} cy={21.842} r={0.975} fill={color ?? 'currentColor'} />
    <circle cx={7.368} cy={2.058} r={0.975} fill={color ?? 'currentColor'} />
    <circle cx={3.363} cy={5.23} r={0.975} fill={color ?? 'currentColor'} />
    <circle cx={1.254} cy={9.612} r={0.975} fill={color ?? 'currentColor'} />
    <circle cx={1.36} cy={14.747} r={0.975} fill={color ?? 'currentColor'} />
    <circle cx={3.416} cy={18.851} r={0.975} fill={color ?? 'currentColor'} />
  </svg>
);
export default SvgPartialTick;
