import { useState, useContext, useCallback } from 'react';
import { getSignalRContext } from 'helpers/getSignalRContext';
import { cache } from 'store';

const useHubAction = (
  hubAction = 'JoinAssignmentGroup',
  hubName = 'LessonHub',
  identifier = null
) => {
  const signalRConnection = useContext(getSignalRContext(hubName));
  const [currentIdentifier, setCurrentIdentifier] = useState(null);

  const action = useCallback(
    async identifier => {
      if (!identifier) return;
      const cacheKey = `useHubAction:${hubName}:${hubAction}:${identifier}`;
      const isAlreadyCalled = cache.get({ key: cacheKey }) || null;
      if (!currentIdentifier && signalRConnection && !isAlreadyCalled) {
        try {
          await signalRConnection.invoke(hubAction, `${identifier}`);
        } catch (e) {
          console.error('useHubAction invoked error', hubAction, identifier, e);
        }

        cache.set({
          key: cacheKey,
          value: true,
          ttlMinutes: 60,
          typename: 'useHubAction'
        });

        setCurrentIdentifier(identifier);
      }
    },
    [currentIdentifier, hubAction, hubName, signalRConnection]
  );

  return {
    action: () => action(identifier),
    customAction: identifier => action(identifier)
  };
};

export default useHubAction;
