import { FUNCTIONS_API_URL, getAuthToken } from 'config/settings';

export class Api {
  async trackApi(event, data) {
    try {
      const token = getAuthToken();
      const body = {
        category: data && data.category ? data.category : 'All',
        action: event,
        label: data && data.label ? data.label : undefined,
        value: data && data.value ? data.value : undefined,
        values: data && data.values ? data.values : undefined,
        source: 'EediClassroom'
      };
      await fetch(`${FUNCTIONS_API_URL}/api/v3/user-events`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      });
    } catch (e) {
      console.error(e);
    }
  }
}
