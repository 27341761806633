const AssistantIcon = ({ width = 27, height = 27, ...props }) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M4.37838 0H22.6216C25.0397 0 27 1.96027 27 4.37838V22.6216C27 25.0397 25.0397 27 22.6216 27H4.37838C1.96027 27 0 25.0397 0 22.6216V4.37838C0 1.96027 1.96027 0 4.37838 0ZM4.37838 2.18919C3.16932 2.18919 2.18919 3.16932 2.18919 4.37838V22.6216C2.18919 23.8307 3.16932 24.8108 4.37838 24.8108H22.6216C23.8307 24.8108 24.8108 23.8307 24.8108 22.6216V4.37838C24.8108 3.16932 23.8307 2.18919 22.6216 2.18919H4.37838Z"
        fill="#8D34FF"
      />
      <g clipPath="url(#clip0_12625_79330)">
        <path
          fillRule="evenodd"
          clip-rule="evenodd"
          d="M12.0642 5.84003L9.19092 5.84003L9.19092 14.46L12.0642 14.46L12.0642 5.84003ZM17.8108 5.84003L14.9375 5.84003L14.9375 14.46L17.8109 14.46L17.8108 5.84003Z"
          fill="#8D34FF"
        />
      </g>
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M5.52639 20.4231C5.52605 20.423 5.52572 20.4229 6.00007 18.9998C6.47441 17.5768 6.47411 17.5767 6.47382 17.5766L6.48873 17.5814C6.50482 17.5865 6.5321 17.595 6.5702 17.6065C6.64642 17.6295 6.76586 17.6641 6.92562 17.7067C7.24527 17.792 7.72552 17.9087 8.34323 18.0263C9.57988 18.2619 11.3598 18.4998 13.5001 18.4998C15.6403 18.4998 17.4203 18.2619 18.6569 18.0263C19.2746 17.9087 19.7549 17.792 20.0745 17.7067C20.2343 17.6641 20.3537 17.6295 20.4299 17.6065C20.468 17.595 20.4953 17.5865 20.5114 17.5814L20.5268 17.5765C20.5266 17.5765 20.5257 17.5768 21.0001 18.9998C21.4744 20.4229 21.4741 20.423 21.4737 20.4231L21.4714 20.4239L21.4672 20.4253L21.4555 20.4291L21.4191 20.4408C21.3891 20.4503 21.3475 20.4633 21.2948 20.4791C21.1894 20.5109 21.0393 20.5543 20.8475 20.6055C20.464 20.7077 19.913 20.841 19.2182 20.9734C17.8299 21.2378 15.8598 21.4998 13.5001 21.4998C11.1403 21.4998 9.17025 21.2378 7.7819 20.9734C7.08712 20.841 6.53611 20.7077 6.15264 20.6055C5.96083 20.5543 5.81074 20.5109 5.70532 20.4791C5.65261 20.4633 5.61104 20.4503 5.58099 20.4408L5.54459 20.4291L5.53295 20.4253L5.52878 20.4239L5.52639 20.4231Z"
        fill="#8D34FF"
      />
      <defs>
        <clipPath id="clip0_12625_79330">
          <rect
            width="15.3243"
            height="15.3243"
            fill="white"
            transform="translate(5.83887 21.1641) rotate(-90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AssistantIcon;
