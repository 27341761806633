import { FlowTypeToSlug, FlowTypes } from './helpers/flowItemHelpers';
import FlowPage from './FlowPage';

const TopicFlowPage = ({ templateSlug = '' }) => {
  return (
    <FlowPage
      flowType={FlowTypes.topicPathwayQuiz}
      templateSlug={templateSlug || FlowTypeToSlug.D}
    />
  );
};

export default TopicFlowPage;
