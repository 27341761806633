import React, { useState } from 'react';
import P from 'react-popover';
import Tappable from 'react-tappable';
import styled from 'styled-components';

const Popover = ({
  isOpen: defaultIsOpen = false,
  children,
  onOuterAction,
  withModal,
  disabled,
  overlayComponent
}) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  const toggle = newIsOpen => {
    if (disabled) return;
    setIsOpen(prevIsOpen =>
      typeof newIsOpen === 'boolean' ? newIsOpen : !prevIsOpen
    );
  };

  const renderOverlay = () => <Box>{overlayComponent}</Box>;

  return (
    <StyledTappable onTap={() => toggle()}>
      <StyledP
        style={{ position: withModal ? 'fixed' : 'absolute' }}
        isOpen={isOpen}
        preferPlace="below"
        place={null}
        onOuterAction={() => {
          onOuterAction && onOuterAction();
          toggle(false);
        }}
        body={renderOverlay()}
        tipSize={0.001}>
        {children}
      </StyledP>
    </StyledTappable>
  );
};

const StyledP = styled(P)`
  z-index: 1050;
`;

const StyledTappable = styled(Tappable)`
  width: 100%;
`;

const Box = styled.div`
  box-shadow: 0rem 0.25rem 0.625rem 0rem rgba(55, 52, 65, 0.1);
  border-radius: 0.125rem;
  background: var(--white);
`;

export default Popover;
