import * as React from 'react';
const SvgAdd = ({ size = '16', color, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M3.193 13.527h7.173v7.387h3.232v-7.387h7.21v-3.232h-7.21V3.086h-3.232v7.21H3.193v3.231Z"
      fill={color ?? 'currentColor'}
    />
  </svg>
);
export default SvgAdd;
