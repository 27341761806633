import { createContext } from 'react';
import { SignalRProvider } from './SignalRProvider';

export const LessonHubSignalRConnectionContext = createContext();

export const LessonHubSignalRProvider = ({ children }) => (
  <SignalRProvider
    hubName="LessonHub"
    context={LessonHubSignalRConnectionContext}>
    {children}
  </SignalRProvider>
);
