const TickInCircleIcon = ({
  color = 'var(--gray-dark)',
  width = '1.5rem',
  height = '1.5rem'
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none">
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M22 11.086v.92a10 10 0 11-5.93-9.14"></path>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M22 4L12 14.01l-3-3"></path>
  </svg>
);

export default TickInCircleIcon;
