import { SignalRConnectionContext } from 'providers/SignalRProvider';
import { LessonHubSignalRConnectionContext } from 'providers/LessonHubSignalRProvider';

export const getSignalRContext = hubName => {
  switch (hubName) {
    case 'LessonHub':
      return LessonHubSignalRConnectionContext;
    case 'SignalRHub':
      return SignalRConnectionContext;
    default:
      throw new Error(`${hubName} not mapped`);
  }
};
