import stringToColor from 'helpers/stringToColor';

const School = ({ name, size = '2rem', ...props }) => {
  const color = stringToColor(name);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: size }}
      viewBox="0 0 40 31"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          fill={color}
          d="M20 0l-5 4H1.905L0 8v1h2v22h36V9h2V8l-1.905-4H25z"></path>
        <path
          fill="#FFF"
          fillOpacity="0.501"
          fillRule="nonzero"
          d="M2 8.912L12 9l7.969-7L28 9l10-.088V31H2z"></path>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M12 9l7.902-7L28 9v22h-2v-9h1v-2H13v2h1v9h-2z"
          opacity="0.44"></path>
        <circle
          cx="20"
          cy="13.5"
          r="5.5"
          fill={color}
          fillRule="nonzero"></circle>
        <circle
          cx="20"
          cy="13.5"
          r="4.5"
          fill="#FFF"
          fillRule="nonzero"></circle>
        <path
          fill={color}
          fillRule="nonzero"
          d="M17.5 13h3v1h-3zm3-3v3h-1v-3z"></path>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M15.5 22h4v9h-4zm5 0h4v9h-4zM4 23h6v8H4zm0-11h6v8H4zm26 11h6v8h-6zm0-11h6v8h-6z"
          opacity="0.6"></path>
        <path
          fill="#000"
          fillOpacity="0.1"
          fillRule="nonzero"
          d="M0 8h12v1H0zm28 0h12v1H28z"></path>
      </g>
    </svg>
  );
};

export default School;
