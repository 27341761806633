import FullPageError from './FullPageError';
import { Component } from 'react';

class ErrorBoundary extends Component {
  state = {
    hasError: false,
    error: null,
    info: null
  };

  static getDerivedStateFromError(error) {
    console.error(error);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error, info });
  }

  render() {
    if (this.state.hasError) {
      return (
        <FullPageError
          title="Something went wrong"
          description="Please refresh your browser and try again"
          isLogoutOption={true}
          error={this.state.error}
          info={this.state.info}
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
