import styled from 'styled-components';
import { Card } from 'components/common';

/*
Fable == Fake Table
(Apologies for the terrible name, Table was already taken)

Fable acts as a table at large screen sizes and as plain divs at low screen sizes allowing us to create responsive tables
*/

const Fable = styled.div`
  width: 100%;
  display: table;
`;

Fable.Thead = styled.div`
  display: table-header-group;
`;

Fable.Tbody = styled(Card)`
  margin-bottom: 3rem;
  display: table-row-group;
  border: 1px solid var(--border-color);
  box-shadow: none;
`;

Fable.Th = styled.div`
  display: table-cell;
  border-bottom: 1px solid var(--border-color) !important;
  padding: 1rem 0.75rem;
  font-weight: 500;
  color: var(--black);
  &:first-child {
    padding-left: 1rem;
  }
  &:last-child {
    padding-right: 1rem;
  }
`;

Fable.Tr = styled.div`
  border-bottom: 1px solid var(--border-color);
  padding: 0;
  display: table-row;
  border: 0;

  ${({ onClick }) =>
    onClick &&
    `
    &:hover {
      cursor: pointer;
      background-color: var(--highlight-color);
    }
  `}
  &:last-child > div {
    border-bottom: none;
  }
`;

Fable.Td = styled.div`
  padding: 0.75rem;
  display: table-cell;
  vertical-align: middle;
  border-bottom: 1px solid var(--border-color);
  &:first-child {
    padding-left: 1.75rem;
  }
  &:last-child {
    padding-right: 1.75rem;
  }
`;

Fable.Tfoot = styled.div`
  display: table-footer-group;
`;

export default Fable;
