import { Button, Container, Row, Col } from 'components/common';

const OnboardingQuestion = () => {
  return (
    <Container className="container-lg">
      <div className="text-center mb-4">
        <h1>
          Before we get started,
          <br />
          Something something?
        </h1>
      </div>
      <Row>
        <Col md={4} className="text-center">
          Something
        </Col>
        <Col md={4} className="text-center">
          Something
        </Col>
        <Col md={4} className="text-center">
          Something
        </Col>
      </Row>

      <div className="text-center mt-4">
        <Button to="/schools" color="primary" size="large">
          Next
        </Button>
      </div>
    </Container>
  );
};

export default OnboardingQuestion;
