const Ellipsis = ({ fill = '#C4C4C4', ...props }) => {
  return (
    <svg
      width="35"
      height="7"
      viewBox="0 0 35 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <circle cx="4.36618" cy="3.49704" r="3.49704" fill={fill} />
      <circle cx="17.7158" cy="3.49704" r="3.49704" fill={fill} />
      <circle cx="31.0654" cy="3.49704" r="3.49704" fill={fill} />
    </svg>
  );
};

export default Ellipsis;
