import * as React from 'react';
const SvgDownload = ({ size = '16', color, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.899c.538 0 .974.436.974.974v11.346l4.734-4.143a.974.974 0 1 1 1.283 1.467l-6.35 5.555a.974.974 0 0 1-1.282 0l-6.35-5.555a.974.974 0 0 1 1.283-1.466l4.734 4.142V2.873c0-.538.436-.974.974-.974ZM3.883 21.127c0-.538.436-.974.974-.974h14.286a.974.974 0 0 1 0 1.949H4.857a.974.974 0 0 1-.974-.975Z"
      fill={color ?? 'currentColor'}
    />
  </svg>
);
export default SvgDownload;
