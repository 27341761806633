const find = ({
  obj,
  filter,
  depth = 0,
  sourceFilter = obj => (!!obj?.entry ? obj : null)
}) => {
  if (!obj) return null;
  const isArray = Array.isArray(obj);
  const isObject = typeof obj === 'object';

  if (!isObject) {
    return;
  }

  let found = null;

  Object.keys(obj).forEach((k, i) => {
    if (!found) {
      if (isArray) {
        found = find({ obj: obj[i], filter, depth: depth + 1, sourceFilter });
      } else if (Array.isArray(obj[k])) {
        found = find({ obj: obj[k], filter, depth: depth + 1, sourceFilter });
      } else if (typeof obj[k] === 'object') {
        found = find({ obj: obj[k], filter, depth: depth + 1, sourceFilter });
      } else if (filter(obj)) {
        found = obj;
      }
    }
  });

  if (!!found && !found?.source && sourceFilter(obj)) {
    found.source = sourceFilter(obj);
  }

  return found;
};

const flatten = obj => {
  if (!obj) return {};
  const isArray = Array.isArray(obj);
  const isObject = typeof obj === 'object';

  if (!isObject) {
    return;
  }

  const flat = {};

  Object.keys(obj).forEach((k, i) => {
    if (isArray) {
      Object.assign(flat, flatten(obj[i]));
    } else if (Array.isArray(obj[k])) {
      Object.assign(flat, flatten(obj[k]));
    } else {
      flat[k] = obj[k];
    }
  });

  return flat;
};

const searchObjectHelpers = {
  find,
  flatten
};

export default searchObjectHelpers;
