import React, { Children } from 'react';
import styled from 'styled-components';

import { colors } from 'styles';
import FontSizes from './FontSizes';

const MultiAvatar = ({ color, children }) => (
  <Container>
    {Children.map(children, (child, i) =>
      children.length > 4 && i > 2 ? null : (
        <Outline key={i} index={i} isSmall={child.props.small} color={color}>
          <AvatarContainer>{child}</AvatarContainer>
        </Outline>
      )
    )}
    {children && children.length > 4 ? (
      <Outline
        key={'last'}
        index={3}
        isSmall={children[0].props.small}
        color={color}>
        <NumberAvatarContainer isSmall={children[0].props.small}>
          <NumberAvatar
            isSmall={children[0].props.small}
            isOval={children.length - 3 > 99}>
            +{children.length - 3}
          </NumberAvatar>
        </NumberAvatarContainer>
      </Outline>
    ) : null}
  </Container>
);

const Container = styled.div`
  display: flex;
  position: relative;
`;

const NumberAvatar = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  min-width: ${({ isSmall }) => (isSmall ? 2.5 : 5.25)}rem;
  height: ${({ isSmall }) => (isSmall ? 2.5 : 5.25)}rem;
  background-color: transparent;
  border: ${({ isSmall }) =>
    isSmall
      ? `0.125rem solid var(--gray-lighter)`
      : `0.25rem solid var(--gray-lighter)`};
  border-radius: ${({ isSmall }) => (isSmall ? 2.5 : 5.25)}rem;
  font-size: ${({ isSmall }) =>
    isSmall ? FontSizes[7].desktop.fontSize : FontSizes[6].desktop.fontSize}rem;
  color: var(--gray);
  padding-top: ${({ isSmall }) => (isSmall ? '1.5px' : '3px')};
  padding-left: ${({ isOval }) => (isOval ? '0.5rem' : '0rem')};
  padding-right: ${({ isOval }) => (isOval ? '0.5rem' : '0rem')};
`;

const Outline = styled.div`
  display: flex;
  flex-shrink: 0;
  position: relative;
  justify-content: center;
  align-items: center;
  width: ${({ isSmall }) => (isSmall ? 3 : 6)}rem;
  height: ${({ isSmall }) => (isSmall ? 3 : 6)}rem;
  background-color: ${({ color }) => colors[color]};
  border-radius: 50%;
  margin-left: ${({ index, isSmall }) => {
    if (index === 0) {
      return 0;
    } else if (isSmall) {
      return -1;
    }
    return -2;
  }}rem;
`;

const NumberAvatarContainer = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  margin-left: ${({ isSmall }) => (isSmall ? '5px' : '10px')};

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    top: ${({ isSmall }) => (isSmall ? '3.5px' : '7px')};
  }
`;

const AvatarContainer = styled.div`
  display: flex;
`;

MultiAvatar.defaultProps = {
  color: 'white'
};

export default MultiAvatar;
