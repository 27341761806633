const initialState = {
  pathname: null,
  context: {}
};

const navigation = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CONTEXT':
      return {
        ...state,
        pathname: action.pathname
      };

    case 'ADD_CONTEXT':
      return {
        ...state,
        pathname: action.pathname,
        context: {
          ...state.context,
          [action.pathname]: {
            type: action.context.type,
            data: action.context.data
          }
        }
      };

    case 'REMOVE_CONTEXT':
      const newContext = { ...state.context };
      delete newContext[action.pathname];
      const allPathnames = Object.keys(newContext);
      const lastPathname = allPathnames[allPathnames.length - 1];
      return {
        ...state,
        pathname: lastPathname,
        context: {
          ...newContext
        }
      };

    default:
      return state;
  }
};

export default navigation;
