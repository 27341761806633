import { Alert as ReactstrapAlert } from 'reactstrap';
import styled from 'styled-components';
import IntentIcon from 'components/common/IntentIcon';

const StyledAlert = styled(ReactstrapAlert)`
  display: flex;
  align-items: center;
  color: var(--black);
  &.alert-warning {
    background-color: var(--yellow-lighter);
  }
  &.alert-danger {
    background-color: var(--red-lighter);
  }
  &.alert-success {
    background-color: var(--green-lighter);
  }
  &.alert-info {
    background-color: var(--blue-lighter);
  }
  &.alert-primary {
    background-color: var(--secondary);
  }
`;

const StyledContents = styled.div`
  margin-right: 1rem;
  flex: 1;
`;

const StyledIcon = styled.span`
  margin-right: 1rem;
  flex: 0 0 1.5rem;
`;

const Title = styled.span`
  line-height: 1.2;
  display: block;
`;

const Action = styled.div`
  margin-left: auto;
`;

const Alert = ({
  title,
  description,
  color,
  action,
  icon = true,
  children,
  ...props
}) => {
  return (
    <StyledAlert color={color} {...props}>
      {icon && (
        <StyledIcon>
          <IntentIcon intent={color} width="1.5rem" height="1.5rem" />
        </StyledIcon>
      )}
      {description ? (
        <StyledContents>
          {typeof title === 'string' ? <Title>{title}</Title> : title}
          {typeof description === 'string' ? (
            <small>{description}</small>
          ) : (
            description
          )}
        </StyledContents>
      ) : (
        title
      )}
      {action && <Action>{action}</Action>}
      {children}
    </StyledAlert>
  );
};

export default Alert;
