import * as React from 'react';
const SvgResultIncomplete = ({ size = '16', color, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <circle cx={12} cy={12} r={12} fill={color ?? 'currentColor'} />
  </svg>
);
export default SvgResultIncomplete;
