import {
  Modal as ReactstrapModal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from 'reactstrap';
import styled from 'styled-components';
import Loading from './Loading';

const Modal = ReactstrapModal;
Modal.Body = ModalBody;
Modal.Header = styled(ModalHeader)`
  .modal-title {
    font-size: 1.25rem;
  }
  .close {
    opacity: 1;
  }

  ${props =>
    props.tint
      ? `
    background-color: var(--tint-color);
    border-bottom: 1px solid var(--border-color);
    `
      : `
  & + .modal-body {
    padding-top: 0;
  }
  `};
`;

Modal.Footer = styled(ModalFooter)`
  background-color: var(--gray-lightest);
  padding: 0.75rem 2rem;
`;

Modal.Title = styled(({ className, children, toggle, ...props }) => (
  <div className={`modal-header ${className}`} {...props}>
    {children}
    {toggle && <Modal.Close toggle={toggle} />}
  </div>
))`
  position: relative;
  padding-right: 5rem;
  ${props =>
    props.tint
      ? `
    background-color: var(--tint-color);
    border-bottom: 1px solid var(--border-color);
  `
      : `
  & + .modal-body {
    padding-top: 0;
  }
  `};
`;

Modal.Close = styled(({ className, toggle, ...props }) => (
  <button
    type="button"
    onClick={toggle}
    className={`close ${className}`}
    aria-label="Close"
    {...props}>
    <span aria-hidden="true">&times;</span>
  </button>
))`
  position: absolute;
  right: 2rem;
  top: 50%;
  height: 3rem;
  width: 3rem;
  padding: 0.5rem !important;
  margin: -1.5rem 0 0 0 !important;
  color: var(--black) !important;
  opacity: 0.9 !important;
`;

Modal.Loading = () => (
  <Modal isOpen={true}>
    <Modal.Body>
      <Loading />
    </Modal.Body>
  </Modal>
);

export default Modal;
