import { useCallback, useEffect, useRef, useState } from 'react';

function validateFields(fields, rules) {
  const errors = Object.keys(fields).reduce((acc, key) => {
    const rule = rules[key];
    if (rule) {
      const originalValue = fields[key];
      const value =
        typeof originalValue === 'string' ? fields[key].trim() : originalValue;
      const error = rule(value) || undefined;

      return { ...acc, [key]: error };
    }
    return acc;
  }, {});

  const isValid = Object.keys(errors).filter(key => errors[key]).length === 0;

  return {
    errors,
    isValid
  };
}

const FormValidator = ({
  fields = {},
  rules = {},
  onChange = undefined,
  children
}) => {
  const currentFormFields = useRef({});
  const [formState, setFormState] = useState({
    errors: {},
    isValid: true
  });

  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  useEffect(() => {
    if (onChangeRef.current) {
      onChangeRef.current(formState.isValid);
    }
  }, [formState.isValid]);

  useEffect(() => {
    Object.keys(fields).reduce((acc, key) => {
      if (fields[key] !== currentFormFields.current[key]) {
        return { ...acc, [key]: fields[key] };
      }
      return acc;
    }, {});
    currentFormFields.current = fields;
  }, [fields, rules]);

  const childValidate = useCallback(
    passedFields => {
      const newState = validateFields(fields || passedFields, rules);
      setFormState(newState);
      return newState.isValid;
    },
    [fields, rules]
  );

  return children(formState.errors, childValidate, formState.isValid);
};

export default FormValidator;
