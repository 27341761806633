import * as React from 'react';
import styled from 'styled-components';
import { SmallCard } from 'components/dls';
import times from 'lodash.times';

import PlaceholderBar from 'components/placeholders/PlaceholderBar';
import PlaceholderAvatar from 'components/placeholders/PlaceholderAvatar';

const CardPlaceholder = ({ cards = 1, noTitle = false }) => (
  <Wrapper>
    {!noTitle && <PlaceholderBar />}
    {times(cards, idx => (
      <CardContainer key={`placeholder_${idx}`}>
        <SmallCard
          level={0}
          avatarComponent={<PlaceholderAvatar />}
          color={'rgba(52, 51, 52, 0.04)'}
          title={<PlaceholderBar long />}
          subtitle={<PlaceholderBar />}
        />
      </CardContainer>
    ))}
  </Wrapper>
);
export default CardPlaceholder;

const Wrapper = styled.div`
  margin-top: 2rem;
`;

const CardContainer = styled.div`
  margin-top: 0.5rem;
`;
