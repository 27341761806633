const initialState = {
  selections: []
};

const setWork = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_SELECTION':
      return {
        ...state,
        selections: action.selections
      };

    case 'UPDATE_SETTINGS':
      return {
        ...state,
        selections: action.selections,
        globalConfig: action.globalConfig
      };

    case 'CLEAR_SELECTION':
      return initialState;

    default:
      return state;
  }
};

export default setWork;
