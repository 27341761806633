import styled from 'styled-components';

const BlueLoader = ({ isLoading = true, ...props }) =>
  isLoading ? <Loader {...props} /> : null;

const Loader = styled.div`
  height: 80px;
  aspect-ratio: 1;
  display: grid;

  :before,
  :after {
    content: '';
    --c: no-repeat linear-gradient(#4353ff 0 0);
    background: var(--c), var(--c);
    background-size: 25% 50%;
    animation: l4 1.5s infinite linear;
  }
  :after {
    transform: scale(-1);
  }
  @keyframes l4 {
    0%,
    10% {
      background-position: 33.4% 100%, 66.6% 100%;
    }
    40% {
      background-position: 33.4% 0, 100% 100%;
    }
    70% {
      background-position: 0 100%, 66.6% 0;
    }
    100% {
      background-position: 33.4% 100%, 66.6% 100%;
    }
  }
`;

export default BlueLoader;
