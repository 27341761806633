import * as React from 'react';
const SvgAnalytics = ({ size = '16', color, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="m13.832 10.587-1.03-.464a.483.483 0 0 1-.287-.436.48.48 0 0 1 .28-.44l1.023-.48.467-1.014a.482.482 0 0 1 .439-.285.486.486 0 0 1 .443.278l.482 1.017 1.02.464a.484.484 0 0 1 .287.436.479.479 0 0 1-.28.44l-1.023.48-.467 1.014a.483.483 0 0 1-.439.285.487.487 0 0 1-.442-.278l-.473-1.017Zm-9.286 3.016.466-1.024 1.024-.48a.484.484 0 0 0 .28-.44.479.479 0 0 0-.287-.436L5 10.76l-.483-1.007a.474.474 0 0 0-.438-.279.479.479 0 0 0-.433.286l-.467 1.024-1.023.48a.483.483 0 0 0-.202.7c.051.077.123.138.208.176l1.03.463.483 1.008c.167.374.71.37.872-.007Zm4.332-4.856.6-1.352 1.351-.617a.483.483 0 0 0 .28-.44.48.48 0 0 0-.286-.436l-1.361-.597-.62-1.343a.482.482 0 0 0-.443-.278.486.486 0 0 0-.439.285l-.6 1.352-1.351.617a.483.483 0 0 0-.28.44.48.48 0 0 0 .286.436l1.36.596.621 1.344c.168.374.71.37.882-.007Zm12.697.48a.969.969 0 0 0-1.092-.184.968.968 0 0 0-.312.233l-5.873 6.65-3.21-3.143a.965.965 0 0 0-1.054-.2.966.966 0 0 0-.312.21l-5.807 5.87a1.024 1.024 0 0 0 .011 1.444 1.04 1.04 0 0 0 1.453-.011l5.047-5.103 3.172 3.105a.965.965 0 0 0 1.404-.049l6.621-7.513a.95.95 0 0 0-.048-1.309Z"
      fill={color ?? 'currentColor'}
    />
  </svg>
);
export default SvgAnalytics;
