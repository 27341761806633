/* @flow */
import { changeField, setJoinStatus } from 'actions/joinSchoolStepper';
import { deselectSchool } from 'actions/onboarding';
import analytics from 'analytics';
import tracking from 'analytics/tracking';
import { Api } from 'api';
import countries from 'assets/data/countries';
import {
  Alert,
  Button,
  Card,
  Form,
  Input,
  Icon,
  Select,
  toaster
} from 'components/common';
import LongLoading from 'components/common/LongLoading';
import FormValidator from 'components/FormValidator';
import joinStatuses from 'constants/joinStatuses';
import useLoadUserContext from 'hooks/useLoadUserContext';
import is from 'is_js';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

const SchoolAddForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const joinSchoolStepper = useSelector(s => s.joinSchoolStepper);
  const { userId, isCleverUser } = useSelector(s => ({
    userId: s.user.userId,
    isCleverUser: s.user.isCleverUser
  }));
  const { fields, joinStatus, error } = joinSchoolStepper;

  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [zipOrPostCode, setZipOrPostCode] = useState('');
  const [country, setCountry] = useState(
    isCleverUser ? 'United States' : 'United Kingdom'
  );
  const [joinSuccess, setJoinSuccess] = useState(false);
  const { loadUserContext } = useLoadUserContext();

  const searchAgain = useCallback(
    (name, deselect) => {
      if (deselect) dispatch(deselectSchool());
      navigate('/schools', { state: name });
    },
    [dispatch, navigate]
  );

  useEffect(() => {
    if (!fields.name) searchAgain();
  }, [fields, searchAgain]);

  const handleAddSchool = async validate => {
    const name = fields.name;

    if (!validate()) {
      return;
    }

    tracking.track('School Created');
    dispatch(setJoinStatus(joinStatuses.loading));

    try {
      const insertRequest = {
        name,
        country,
        address,
        city,
        zipOrPostCode
      };

      if (isCleverUser) {
        const data = await Api.addCleverSchool(insertRequest);
        const schoolId = data.schoolId;
        dispatch(changeField('schoolId', schoolId));
        analytics.track('School Joined', {
          category: 'School',
          'School ID': schoolId
        });
        await loadUserContext(schoolId);
        toaster.success('Your school has been created');
        setJoinSuccess(true);
        dispatch(setJoinStatus(joinStatuses.added));
        navigate(`/${schoolId}`);
        return;
      }

      const data = await Api.addSchool(insertRequest);
      const schoolId = data.schoolId;
      await Api.joinSchool(schoolId, userId);
      dispatch(changeField('schoolId', schoolId));
      analytics.track('School Joined', {
        category: 'School',
        'School ID': schoolId
      });
      await loadUserContext(schoolId);
      toaster.success('Your school has been created');
      setJoinSuccess(true);
      dispatch(setJoinStatus(joinStatuses.added));
      navigate(`/${schoolId}/classes/new`);
    } catch (e) {
      dispatch(setJoinStatus(joinStatuses.failed, e));
    }
  };

  const renderNewClassForm = (errors, validate) => {
    const name = fields.name;

    const countryList = countries.countriesList.map(country => ({
      value: country.name,
      label: country.name
    }));

    return (
      <Card className="mb-3">
        <Card.Body>
          <Form.Group>
            <Input
              name="name"
              disabled
              error={errors.name}
              value={name}
              placeholder="School name"
            />
          </Form.Group>
          <Form.Group>
            <Input
              name="address"
              error={errors.address}
              value={address}
              onChange={e => setAddress(e.target.value)}
              placeholder="Address"
            />
          </Form.Group>
          <Form.Group>
            <Input
              name="city"
              error={errors.city}
              value={city}
              onChange={e => setCity(e.target.value)}
              placeholder="Town/City"
            />
          </Form.Group>
          <Form.Group>
            <Select
              name="country"
              error={errors.country}
              options={countryList}
              placeholder="Country"
              value={countryList.find(c => c.value === country)}
              onChange={option => {
                setCountry(option.value);
              }}
            />
          </Form.Group>
          <Form.Group>
            <Input
              name="zipOrPostCode"
              error={errors.zipOrPostCode}
              value={zipOrPostCode}
              onChange={e => setZipOrPostCode(e.target.value)}
              placeholder={isCleverUser ? 'Zipcode' : 'Postcode'}
            />
          </Form.Group>
          <Button
            className={joinSuccess && 'disabled'}
            color="primary"
            loading={joinStatus === joinStatuses.loading}
            onClick={() => handleAddSchool(validate)}
            block>
            {isCleverUser ? 'Add school & sync with Clever' : 'Add school'}
          </Button>
        </Card.Body>
      </Card>
    );
  };

  if (isCleverUser && joinStatus === joinStatuses.loading)
    return (
      <LongLoading loadingText="Creating new school & syncing it with Clever... This may take a few moments... ☕" />
    );

  return (
    <>
      <div className="text-center mb-4">
        <h1>Add your school details</h1>
      </div>
      {error && <Alert color="danger" title={error.message} />}
      <FormValidator
        fields={{
          address,
          city,
          country,
          zipOrPostCode
        }}
        rules={{
          address: val => !val && 'Please enter the address',
          city: val => !val && 'Please enter the city',
          country: val => !val && 'Please choose a country',
          zipOrPostCode: val =>
            !(fields.country === 'United Kingdom'
              ? val && is.ukPostCode(val.toUpperCase())
              : val) && 'Please enter valid postcode'
        }}>
        {(errors, validate) => renderNewClassForm(errors, validate)}
      </FormValidator>
      <Button color="link" onClick={() => searchAgain(fields.name, true)}>
        <Icon name="arrowLeft" color="primary" />
        Search again
      </Button>
    </>
  );
};

export default SchoolAddForm;
