import styled from 'styled-components';
import { Info } from 'components/common';

const SwitchButton = styled.button`
  margin-left: auto;
  margin-right: ${({ withLabel }) => (withLabel ? 'unset' : 'auto')};
  display: flex;
  position: relative;
  align-items: center;
  height: 2rem;
  width: 3.5rem;
  border-radius: 1.5rem;
  background-color: ${({ on }) => (!!on ? 'var(--primary)' : 'var(--gray)')};
  outline: none;
  border: 2px solid transparent;

  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: background-color 400ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;

  &:focus {
    outline: none;
    border-color: var(--gray-dark);
  }
`;

const Toggle = styled.div`
  display: flex;
  position: absolute;
  background-color: var(--white);
  width: 1.5rem;
  height: 1.5rem;
  top: 0;
  bottom: 0;
  margin: auto;
  left: ${({ on }) => (on ? '1.6rem' : '0.15rem')};
  border-radius: 50%;
  transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1);
`;

const Label = styled.div`
  display: flex;
  float: left;
  font-weight: 500;
  align-content: center;
  align-self: center;
`;

const InfoTooltip = styled.div`
  display: flex;
  align-self: center;
  margin: 0 0.5rem 0 0.5rem;
`;

const SwitchWrapper = styled.div`
  display: flex;
`;

const Switch = ({
  onClick,
  enabled = false,
  value = false,
  disabled = false,
  label = null,
  tooltip = null,
  name = 'Switch',
  ...props
}) => (
  <SwitchWrapper>
    {label && (
      <Label>
        {label}
        {tooltip && (
          <InfoTooltip>
            <Info id={`tooltip-${name}`} tooltip={tooltip ?? ''} />
          </InfoTooltip>
        )}
      </Label>
    )}
    <SwitchButton
      type="button"
      on={enabled || value ? 'true' : undefined}
      disabled={disabled}
      onClick={onClick}
      withLabel={!!label}
      {...props}>
      <Toggle on={enabled || value ? 'true' : undefined} {...props} />
    </SwitchButton>
  </SwitchWrapper>
);

export default Switch;
