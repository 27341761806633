import styled from 'styled-components';
import { Nav } from 'components/common';

const Tabs = ({ tabs, currentTab, onClick, activecolor = null, ...props }) => {
  return (
    <StyledNav {...props}>
      {tabs.map(tab => {
        if (!tab) {
          return null;
        }
        return (
          <Nav.Item key={tab.id}>
            <SubNavLink
              onClick={() => !tab.disabled && onClick(tab.id)}
              active={currentTab === tab.id}
              disabled={tab.disabled}
              activecolor={activecolor}
              style={tab.style}>
              {tab.value}
              {Number.isInteger(tab.count) && <Count>{tab.count}</Count>}
            </SubNavLink>
          </Nav.Item>
        );
      })}
    </StyledNav>
  );
};

const StyledNav = styled(Nav)`
  border-bottom: 1px solid var(--border-color);
`;

const SubNavLink = styled.a`
  padding: 0.5rem 0.5rem;
  color: var(--gray-dark);
  font-weight: 400;
  display: inline-block;
  border-bottom: 3px solid transparent;
  text-decoration: none;

  ${props =>
    props.active
      ? `
      border-color: ${props.activecolor || 'var(--primary)'};
      color: ${props.activecolor || 'var(--primary)'} !important;
      font-weight: 500;
      &:hover {
        text-decoration: none;
      }`
      : `
    &:hover {
      border-color: ${props.activecolor || 'var(--highlight-color)'} !important;
      color: ${props.activecolor || 'var(--primary)'} !important;
      text-decoration: none;
    }
  `}

  ${props =>
    props.disabled &&
    `
      border: none;
      color: var(--gray) !important;
      cursor: auto;
      &:hover {
        text-decoration: none;
        color: var(--gray) !important;
      }
  `}
`;

const Count = styled.span`
  font-weight: normal;
  margin-left: 0.5rem;
  background-color: rgba(var(--gray-light-rgba), 0.4);
  border-radius: 99rem;
  padding: 0 0.25rem;
  min-width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  text-align: center;
  font-size: 0.85rem;
`;

export default Tabs;
