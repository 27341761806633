import React, { Component } from 'react';
import ReactModal from 'react-responsive-modal';
import styled from 'styled-components';

import Button from './Button';
import { colors } from 'styles';

class FullScreenModal extends Component {
  props;

  render() {
    const {
      onClose,
      isOpen,
      backgroundColor,
      title,
      subtitle,
      children,
      big,
      text,
      footerComponent
    } = this.props;

    return (
      <ReactModal
        styles={modalStyles}
        showCloseIcon={false}
        open={isOpen}
        onClose={onClose || (() => null)}
        {...this.props}>
        <Wrapper>
          {onClose && (
            <CloseButtonContainer>
              <Button onClick={onClose} leftIconName="close" secondary />
            </CloseButtonContainer>
          )}
          {big && (
            <HeaderContainer>
              {title && <h4>{title}</h4>}
              {subtitle && <h5>{subtitle}</h5>}
            </HeaderContainer>
          )}
          <ContentContainer big={big} backgroundColor={backgroundColor}>
            {!big && (
              <ContentHeaderContainer>
                {title && (
                  <ContentTitleContainer>
                    <h5>{title}</h5>
                  </ContentTitleContainer>
                )}
                {subtitle && <p>{subtitle}</p>}
              </ContentHeaderContainer>
            )}
            <Content>{children ? children : <p>{text}</p>}</Content>
          </ContentContainer>
        </Wrapper>
        <FooterWrapper>{footerComponent || null}</FooterWrapper>
      </ReactModal>
    );
  }
}

const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  flex: 3;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ big }) => (big ? '0' : '3rem')};
  width: ${({ big }) => (big ? '100%' : '75%')};
  min-width: ${({ big }) => (big ? '80vw' : '65vw')};
  background-color: ${({ backgroundColor }) =>
    colors[backgroundColor] || 'var(--white)'};
  box-shadow: 0 16px 24px 5px rgba(0, 0, 0, 0.16);
  padding: 2rem;
  align-self: center;
`;

const ContentHeaderContainer = styled.div`
  padding: 1rem 0;
`;

const ContentTitleContainer = styled.div`
  margin-bottom: 1.5rem;
`;

const HeaderContainer = styled.div`
  margin: 0 4rem 2rem 3rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  min-height: 3rem;
`;

const Content = styled.div`
  padding: 3rem 0;
`;

const modalStyles = {
  modal: {
    padding: '0',
    borderRadius: '0.125rem',
    border: 'none',
    background: 'transparent',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    minWidth: '90%'
  },
  overlay: {
    backgroundColor: 'rgba(255, 255, 255, 0.95)'
  }
};

export default FullScreenModal;
