import { Api, useApiQuery } from 'api';
import { AddIcon, TickIcon } from 'assets/icons';
import {
  Page,
  Header,
  Card,
  Row,
  Col,
  Info,
  ProgressBar,
  Badge,
  Table,
  Loading,
  Button,
  Link
} from 'components/common';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { RadialChart } from 'react-vis';
import SetWorkButton from 'components/quizzes/SetWorkButton';
import { getImageUrl } from 'config/settings';
import { setGroup } from 'actions/group';

const weeks = [
  { id: 'last', label: 'Last Week', getWeek: x => x - 1 },
  { id: 'this', label: 'This Week', getWeek: x => x, isCurrentWeek: true },
  { id: 'next', label: 'Next Week', getWeek: x => x + 1 }
];

const Dashboard = () => {
  const schoolId = useSelector(s => s.school.schoolId);
  const groups = useSelector(state => state.school.groups);
  const groupId = useSelector(s => s.group?.groupId);
  const teacherId = useSelector(s => s.school.currentSchool.teacher.teacherId);
  const dispatch = useDispatch();

  const { data: analytics } = useApiQuery(async () => {
    if (!groupId) {
      return null;
    }

    return await Api.getGroupAnalytics({
      schoolId,
      groupId
    });
  }, [groupId, schoolId]);

  const { data: summary, loading: summaryLoading } = useApiQuery(async () => {
    let summary;

    if (groupId) {
      summary = await Api.getGroupCompletionSummary(schoolId, groupId);
      summary = [summary];
    } else {
      summary = await Api.getCompletionSummary(teacherId);
    }

    const mergedSummary = groups?.map(group => {
      const row = summary?.find(s => s.groupId === group.groupId);

      if (!row) {
        return null;
      }

      return {
        ...row,
        ...group
      };
    });

    return mergedSummary;
  }, [teacherId, groups, groupId, schoolId]);

  const misconceptionsResolvedCount =
    analytics?.activities?.misconceptionsResolvedCount ?? 0;
  const misconceptionsIdentifiedCount =
    analytics?.activities?.misconceptionsIdentifiedCount ?? 0;
  const misconceptionsUnresolvedCount =
    misconceptionsIdentifiedCount - misconceptionsResolvedCount;

  return (
    <Page title="Results">
      <Page.Main>
        <Header>
          <Header.Title>
            <h1>Home</h1>
          </Header.Title>
        </Header>
        <Page.Body>
          <Row>
            <Col className="mb-4 d-flex align-items-stretch">
              <Card
                className="w-100"
                style={{
                  background: `var(--green-lighter) url(${getImageUrl(
                    'eedi/images/actions/add-students.png'
                  )}) no-repeat right center`,
                  backgroundSize: 'contain',
                  border: 'none'
                }}>
                <Card.Body style={{ paddingRight: '20rem' }}>
                  <h2>Add students to this class</h2>
                  <p>
                    Add your students to this class so they can begin completing
                    work.
                  </p>
                  <Button color="primary" to={`/${schoolId}/settings/students`}>
                    Add Students
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col className="mb-4 d-flex align-items-stretch">
              <Card
                className="w-100"
                style={{
                  background: `var(--purple-lighter) url(${getImageUrl(
                    'eedi/images/actions/book-a-training-call.png'
                  )}) no-repeat right center`,
                  backgroundSize: 'contain',
                  border: 'none'
                }}>
                <Card.Body style={{ paddingRight: '20rem' }}>
                  <h2>Book a training call</h2>
                  <p>
                    We offer a free training call for you and your department.
                    Learn how to get the most out of Eedi.
                  </p>
                  <Button
                    color="primary"
                    href="https://calendly.com/harriet-walters/onboarding-demo"
                    target="_blank"
                    rel="noopener noreferrer">
                    Book a training call
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {groupId && (
            <Card className="mb-4">
              <Row>
                <Col sm="6">
                  <Card.Body>
                    <Row className="align-items-top">
                      <Col lg="6" xl="3">
                        <h2>All time</h2>
                        <span className="text-muted">
                          Last updated:
                          <br />
                          {moment().startOf('day').fromNow()}
                          <Info
                            className="ml-1"
                            id="lastUpdated"
                            tooltip="This data is updated every 24 hours"
                          />
                        </span>
                      </Col>
                      <Col lg="6" xl="3">
                        <h3 className="display-3 mb-0">
                          {analytics?.activities?.assignmentsCompleted ?? '-'}
                        </h3>
                        <p className="mb-0">Assigned quizzes completed</p>
                      </Col>
                      <Col lg="6" xl="3">
                        <h3 className="display-3 mb-0">
                          {analytics?.activities?.tutorInterventions ?? '-'}
                        </h3>
                        <p className="mb-0">Tutor interventions</p>
                      </Col>
                      <Col lg="6" xl="3">
                        <h3 className="display-3 mb-0">
                          {analytics?.activities?.topicsCompleted ?? '-'}
                        </h3>
                        <p className="mb-0">Independent quizzes completed</p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Col>
                <Col
                  sm="6"
                  style={{ borderLeft: '1px solid var(--border-color)' }}>
                  <Card.Body>
                    <Row className="align-items-center">
                      <Col>
                        <div className="d-flex align-items-center">
                          <RadialChart
                            data={
                              misconceptionsUnresolvedCount
                                ? [
                                    {
                                      angle: misconceptionsResolvedCount ?? 0,
                                      label: 'Resolved by Eedi',
                                      color: 'var(--purple-light)'
                                    },
                                    {
                                      angle: misconceptionsUnresolvedCount ?? 0,
                                      label: 'Needs attention',
                                      color: 'var(--red)'
                                    }
                                  ]
                                : [
                                    {
                                      angle: 1,
                                      label: 'No misconceptions',
                                      color: 'var(--gray-lighter)'
                                    }
                                  ]
                            }
                            colorType="literal"
                            width={125}
                            height={125}
                            className="mr-2"
                          />
                          <div>
                            <h3 className="display-3 mb-0">
                              {misconceptionsIdentifiedCount ?? '-'}
                            </h3>
                            <p className="mb-0">
                              Misconceptions identified by Eedi
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <div className="d-flex align-items-center justify-content-between mb-1">
                            <p className="mb-0">Resolved by Eedi</p>
                            <Badge
                              color="blue-lighter"
                              textcolor="black"
                              rounded>
                              {misconceptionsResolvedCount ?? '-'}
                            </Badge>
                          </div>
                          <ProgressBar
                            currentIndex={misconceptionsResolvedCount}
                            total={misconceptionsIdentifiedCount}
                            color="purple-light"
                            style={{ height: '0.25rem' }}
                          />
                        </div>

                        <div className="mb-3">
                          <div className="d-flex align-items-center justify-content-between mb-1">
                            <p className="mb-0">Needs attention</p>
                            <Badge
                              color="blue-lighter"
                              textcolor="black"
                              rounded>
                              {misconceptionsUnresolvedCount ?? '-'}
                            </Badge>
                          </div>
                          <ProgressBar
                            currentIndex={misconceptionsUnresolvedCount}
                            total={misconceptionsIdentifiedCount}
                            color="red"
                            style={{ height: '0.25rem' }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          )}

          {summaryLoading ? (
            <Loading />
          ) : (
            <Card>
              <Card.Header tint>
                <h3>Class summary</h3>
              </Card.Header>
              <Table>
                <thead>
                  <tr>
                    <th
                      style={{
                        borderTop: 'none'
                      }}>
                      Name
                    </th>
                    <th
                      style={{
                        borderTop: 'none'
                      }}>
                      Year
                    </th>
                    <th
                      style={{
                        borderTop: 'none'
                      }}>
                      Students
                    </th>
                    {weeks?.map(week => (
                      <th
                        key={week.id}
                        className="text-center"
                        style={{
                          width: '120px',
                          borderLeft: '1px solid var(--border-color)',
                          backgroundColor: week.isCurrentWeek
                            ? 'var(--blue-lightest)'
                            : 'transparent',
                          borderTop: week.isCurrentWeek
                            ? '2px solid var(--primary)'
                            : 'none'
                        }}>
                        {week.label}
                      </th>
                    ))}
                    <th
                      style={{
                        borderLeft: '1px solid var(--border-color)',
                        borderTop: 'none'
                      }}>
                      Misconceptions
                    </th>
                    {/*
                  <th
                    style={{
                      borderTop: 'none'
                    }}>
                    Recommended action
                  </th>
                  */}
                  </tr>
                  {summary?.map(row => {
                    if (!row) return null;

                    const currentStatistics = row.weeklyStatistics.find(
                      week => week.week.isCurrentWeek
                    );
                    const currentWeekNumber =
                      currentStatistics?.week.scheduleWeekNumber;

                    const mappedWeeklyStatistics = weeks.map(week => {
                      const weeklyStatistics = row.weeklyStatistics.find(
                        s =>
                          s.week.scheduleWeekNumber ===
                          week.getWeek(currentWeekNumber)
                      );

                      return {
                        week,
                        ...weeklyStatistics
                      };
                    });

                    return (
                      <tr key={row.groupId}>
                        <td>
                          <Link onClick={() => dispatch(setGroup(row))}>
                            {row.code}
                          </Link>
                        </td>
                        <td>{row.yearGroupName}</td>
                        <td>{row.studentCount}</td>
                        {mappedWeeklyStatistics.map(s => (
                          <td
                            key={s.week.scheduleWeekNumber}
                            className="text-center"
                            style={{
                              borderLeft: '1px solid var(--border-color)',
                              backgroundColor: s.week.isCurrentWeek
                                ? 'var(--blue-lightest)'
                                : 'transparent'
                            }}>
                            {s.week.scheduleWeekNumber <= currentWeekNumber &&
                            s.assignmentsCount &&
                            row.studentCount ? (
                              `${Math.floor(
                                (s.completedStudentCount /
                                  (s.assignmentsCount * row.studentCount)) *
                                  100
                              )}%`
                            ) : s.week.scheduleWeekNumber >=
                                currentWeekNumber && s.assignmentsCount > 0 ? (
                              <TickIcon size="24" />
                            ) : s.week.scheduleWeekNumber >=
                                currentWeekNumber &&
                              s.assignmentsCount === 0 ? (
                              <SetWorkButton
                                groupId={row.groupId}
                                preferredDate={s.week.weekStart}
                                rounded
                                icon
                                outline
                                size="small">
                                <AddIcon />
                              </SetWorkButton>
                            ) : (
                              '-'
                            )}
                          </td>
                        ))}
                        <td
                          style={{
                            borderLeft: '1px solid var(--border-color)'
                          }}>
                          {row.allTimeStatistics?.incorrectCount ?? 0}/
                          {row.allTimeStatistics?.misconceptionResolvedCount ??
                            0}
                          <span className="text-muted ml-2">resolved</span>
                        </td>
                      </tr>
                    );
                  })}
                </thead>
              </Table>
            </Card>
          )}
        </Page.Body>
      </Page.Main>
    </Page>
  );
};

export default Dashboard;
