import * as React from 'react';

const SvgPractice = ({ size = '16', color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    {...props}>
    <path
      fill={color ?? 'currentColor'}
      d="M2 10.886a1.026 1.026 0 0 0 1.029 1.029 1.03 1.03 0 0 0 1.038-1.03v-.617c0-1.487 1.02-2.469 2.554-2.469h7.063v1.955c0 .505.318.823.832.823.225 0 .459-.084.636-.233l3.537-2.92c.42-.336.42-.897 0-1.253l-3.537-2.937A1.064 1.064 0 0 0 14.516 3c-.514 0-.832.327-.832.833v1.936H6.817C3.88 5.77 2 7.462 2 10.12v.767Zm8.765 2.507c0-.505-.318-.823-.832-.823-.225 0-.459.084-.637.224L5.76 15.713c-.42.346-.42.898 0 1.253l3.536 2.938c.18.149.404.232.637.234.514 0 .832-.318.832-.824V17.36h6.866c2.938 0 4.818-1.702 4.818-4.35v-.767a1.031 1.031 0 0 0-1.435-.96 1.029 1.029 0 0 0-.633.96v.618c0 1.478-1.019 2.47-2.553 2.47h-7.063v-1.937Z"
    />
  </svg>
);
export default SvgPractice;
