import { Nav as ReactstrapNav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Nav = ReactstrapNav;
Nav.Item = NavItem;
Nav.Link = NavLink;

Nav.Brand = styled(Link)`
  display: flex;
  align-items: center;
  height: 2rem;
  width: min-content;
  margin-right: 1rem;
`;

Nav.BrandNoLink = styled.div`
  width: 2rem;
  margin-right: 1rem;
`;

Nav.Left = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
`;

Nav.Right = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
`;

Nav.Center = styled.div`
  display: flex;
  text-align: center;
  white-space: nowrap;
  justify-content: center;
  text-overflow: ellipsis;
`;

export default Nav;
