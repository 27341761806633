import React from 'react';
import { CloseButton, Modal } from 'components/common';
import styled from 'styled-components';
import { colors, sizes } from 'styles';
import PresentNavigationButton from 'components/present/PresentNavigationButton';

const PresentModal = ({
  isModalOpen = false,
  setIsModalOpen = () => {},
  title = null,
  navigation = null,
  reviewComponent = <></>,
  children = null
}) => {
  if (!children) return null;
  return (
    <ModalContainer
      isOpen={isModalOpen}
      toggle={() => setIsModalOpen(!isModalOpen)}>
      <ModalHeaderNavigation>
        <CloseButton
          foregroundcolor={colors.white}
          onClick={() => setIsModalOpen(!isModalOpen)}>
          <span style={{ fontSize: '1.5rem', marginLeft: '0.3rem' }}>
            Close
          </span>
        </CloseButton>
      </ModalHeaderNavigation>
      <ModalBody>{children}</ModalBody>

      <ModalFooterNavigation hasnavigation={!!navigation}>
        {navigation && (
          <PresentNavigationButton
            direction="prev"
            disabled={!navigation.hasPrev}
            onClick={navigation.onPrev}
          />
        )}

        <div className={'d-flex align-items-center present-middle'}>
          {title && <div className="text-lg strong mr-4">{title}</div>}
          {reviewComponent}
        </div>

        {navigation && (
          <PresentNavigationButton
            style={{ display: 'flex', justifyContent: 'flex-end' }}
            direction="next"
            disabled={!navigation.hasNext}
            onClick={navigation.onNext}
          />
        )}
      </ModalFooterNavigation>
    </ModalContainer>
  );
};

const ModalContainer = styled(Modal)`
  width: 100%;
  height: 100%;
  margin: 0;
  max-width: unset;

  .modal-content {
    border-radius: unset;
    background-color: var(--black);
    color: var(--white);
    padding: 0 2rem;
    height: 100%;
  }

  .modal-body {
    padding: 0;
    .container {
      display: flex;
    }
  }

  .present-middle {
    display: flex;
    justify-content: center;
    @media (max-width: ${sizes.xl}px) {
    }
  }
`;

const ModalHeaderNavigation = styled(Modal.Header)`
  padding: unset;

  .modal-title {
    display: flex;
    justify-content: space-between;
    align-self: center;
    width: 100%;
    height: 90px;
  }
`;

const ModalBody = styled(Modal.Body)`
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 90px - 90px);
  @media (max-width: ${sizes.xl}px) {
    height: 100%;
  }
`;

const ModalFooterNavigation = styled(Modal.Footer)`
  background-color: var(--black);
  border-radius: unset;
  display: flex;
  justify-content: ${props =>
    props.hasnavigation ? 'space-between' : 'center'};
  width: 100%;
  height: 90px;

  ${({ hasnavigation }) =>
    hasnavigation &&
    `display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 !important;`}
`;

export default PresentModal;
