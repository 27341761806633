import { FlowTypes } from './helpers/flowItemHelpers';
import FlowPage from './FlowPage';
import { useSelector } from 'react-redux';
import ChatIcon from 'assets/images/flows/ChatIcon';
import styled from 'styled-components';
import AssistantIcon from 'assets/images/flows/AssistantIcon';
import OpenIntercomIcon from 'assets/images/flows/OpenIntercomIcon';

const DefaultHeader = () => {
  return (
    <FlowHeader>
      <Title>
        <AssistantIcon width={32} height={32} />
        <h2>Eedi Assistant</h2>
      </Title>
      <Buttons>
        <ChatWithChelsea
          onClick={() => {
            window.Intercom('show');
          }}>
          <div>
            <img
              src="https://home-content.eedi.com/web/chelsea.jpg"
              alt="Chelsea"
            />
          </div>
          <span>Chat with Chelsea</span>
          <OpenIntercomIcon />
        </ChatWithChelsea>
      </Buttons>
    </FlowHeader>
  );
};

const AssistantFlowPage = ({ header }) => {
  const botContext = useSelector(store => store.botContext);
  return (
    <StyledFlowPage
      flowType={FlowTypes.teacherAssistant}
      header={header ?? <DefaultHeader />}
      botContext={botContext}
      botAvatar={
        <div>
          <ChatIcon />
        </div>
      }
    />
  );
};

const StyledFlowPage = styled(FlowPage)`
  width: 100%;
  button {
    color: var(--bot-purple);
  }
  button:hover {
    color: var(--bot-purple) !important;
  }
`;

const FlowHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  background: var(--bot-purple-lighter);
  margin: 0 -1rem 0 -1rem;
  padding: 1rem;
  -webkit-box-shadow: 0px 5px 7px -2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 7px -2px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  h2 {
    margin: 0;
    color: var(--bot-purple);
    margin: 0 0 0 0.5rem;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ChatWithChelsea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: var(--bot-purple-light);
  border-radius: 30px;
  padding: 5px;
  :hover {
    background: var(--bot-purple-slightly-lighter);
  }
  div {
    border-radius: 30px;
    overflow: hidden;
    img {
      height: 30px;
      width: auto;
    }
  }
  span {
    font-size: 13px;
    font-weight: 500;
    margin: 0 0.25rem 0 0.25rem;
  }
`;

export default AssistantFlowPage;
