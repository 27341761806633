import * as React from 'react';
const SvgTick = ({ size = '16', color, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.38 6.973c.63.472.73 1.332.224 1.92l-6.829 7.925a1.502 1.502 0 0 1-1.075.509 1.521 1.521 0 0 1-1.12-.417l-4.096-3.962a1.301 1.301 0 0 1 .037-1.93 1.54 1.54 0 0 1 2.07.034l2.943 2.847 5.787-6.717c.507-.588 1.428-.681 2.059-.209Z"
      fill={color ?? 'currentColor'}
    />
  </svg>
);
export default SvgTick;
