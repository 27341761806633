import styled from 'styled-components';
import { Link } from 'react-router-dom';

const List = styled.div`
  border-radius: 1rem;
  background-color: var(--white);
  border: 1px solid var(--border-color);
  overflow: hidden;
`;

List.Item = styled.div`
  border-bottom: solid 1px var(--border-color);
  &:last-child {
    border-bottom: 0;
  }
`;

List.Body = styled.div`
  padding: 1.5rem 2rem;
  align-items: center;
  display: flex;
`;

List.Link = ({ children, ...props }) => {
  return <StyledTag {...props}>{children}</StyledTag>;
};

const Tag = ({ children, ...props }) => {
  return props.to ? (
    <Link {...props}>{children}</Link>
  ) : props.href ? (
    <a {...props}>{children}</a>
  ) : (
    <div {...props}>{children}</div>
  );
};

const StyledTag = styled(Tag)`
  padding: 1.5rem 2rem;
  align-items: center;
  display: flex;
  justify-content: space-between;
  color: var(--black);
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    padding-right: 1rem;
  }
`;

export default List;
