import { Avatar, Icon } from 'components/common';
import useClipboard from 'hooks/useClipboard';
import { useState } from 'react';
import styled from 'styled-components';

const ChatBubble = ({
  fromdirection = 'left',
  backgroundcolour,
  children,
  isAvatar = true,
  userId,
  text = null,
  isClipboard = true,
  botAvatar = null,
  ...props
}) => {
  const { handleClipboardClick } = useClipboard({
    clipboardText: text,
    successMessage: 'Message copied to clipboard!'
  });
  const [isActive, setIsActive] = useState(false);

  return (
    <BubbleWrap
      onClick={() => (!!text && isClipboard ? handleClipboardClick() : null)}
      onMouseEnter={() => setIsActive(isClipboard)}
      onMouseLeave={() => setIsActive(false)}
      onTouchStart={() => setIsActive(isClipboard)}
      onTouchEnd={() => setIsActive(false)}
      className={`message ${fromdirection}`}>
      {isAvatar && fromdirection === 'left' && (
        <StyledAvatar
          fromdirection={fromdirection}
          userId={userId || -1}
          botAvatar={botAvatar}
        />
      )}
      <Bubble
        fromdirection={fromdirection}
        backgroundcolour={backgroundcolour}
        {...props}>
        {!!text && (
          <StyledIcon
            name="copy"
            size={16}
            style={{ opacity: isActive ? '1' : '0' }}
            color={isActive ? '#222' : '#444'}
          />
        )}
        {children}
      </Bubble>
      {isAvatar && fromdirection === 'right' && (
        <StyledAvatar fromdirection={fromdirection} userId={userId ?? -1} />
      )}
    </BubbleWrap>
  );
};

const BubbleWrap = styled.div`
  cursor: pointer;
`;

const StyledIcon = styled(Icon)`
  text-align: right;
  margin-top: -10px;
  display: flex;
  align-self: flex-end;
  svg {
    width: 12px;
    height: 12px;
    size: 12px;
  }
`;

const StyledAvatar = styled(Avatar)`
  margin: ${({ fromdirection }) =>
    fromdirection === 'left' ? '0 0.75rem 0 0' : '0 0 0 0.75rem'};
`;

const Bubble = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  height: min-content;
  text-align: left;
  color: ${({ textcolour }) => textcolour};
  background: ${({ backgroundcolour }) => backgroundcolour};
  border-radius: ${({ fromdirection }) =>
    fromdirection === 'left' ? '0 1rem 1rem 1rem' : '1rem 0 1rem 1rem'};
  transform-origin: ${({ fromdirection }) =>
    fromdirection === 'left' ? '0 0' : '100% 0'};

  margin: 0 0 0.75rem 0;
  -webkit-box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.17);
  box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.17);
  &.animateIn {
    animation: enlarge 0.5s ease;

    @keyframes enlarge {
      0% {
        transform: scale(0);
      }

      100% {
        transform: scale(1);
      }
    }
  }
`;

export default ChatBubble;
