import localForage from 'localforage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import session from 'redux-persist/lib/storage/session';
import auth from './auth';
import feedback from './feedback';
import filters from './filters';
import joinClassStepper from './joinClassStepper';
import joinSchoolStepper from './joinSchoolStepper';
import onboarding from './onboarding';
import school from './school';
import studentImport from './studentImportReducer';
import toasts from './toasts';
import tub from './tub';
import ui from './ui';
import user from './user';
import group from './group';
import setWork from './setWork';
import navigation from './navigation';
import botContext from './botContext';

const appReducer = combineReducers({
  auth,
  user: persistReducer(
    {
      key: 'user',
      storage: localForage
    },
    user
  ),
  school: persistReducer(
    {
      key: 'school',
      storage: localForage,
      whitelist: ['schoolId']
    },
    school
  ),
  onboarding,
  joinSchoolStepper,
  joinClassStepper: persistReducer(
    { key: 'joinClassStepper', storage: session },
    joinClassStepper
  ),
  tub,
  filters,
  ui,
  feedback,
  toasts,
  studentImport: persistReducer(
    { key: 'studentImport', storage: session },
    studentImport
  ),
  group: persistReducer(
    {
      key: 'group',
      storage: localForage
    },
    group
  ),
  setWork,
  navigation,
  botContext
});

export default appReducer;
