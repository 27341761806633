import styled, { keyframes } from 'styled-components';

const PlaceholderBar = ({ size = 3.5, color = 'var(--gray-lighter)' }) => (
  <AvatarPlaceholder size={size} color={color} />
);

export default PlaceholderBar;

const pulse = keyframes`
  0% { opacity: 0.6; }
  50% { opacity: 1}
  100% { opacity: 0.6; }
`;

const AvatarPlaceholder = styled.div`
  width: ${({ size }) => `${size}rem`};
  height: ${({ size }) => `${size}rem`};
  border-radius: ${({ size }) => `${size}rem`};
  background: ${({ color }) => color};
  animation: ${pulse} 2s infinite;
`;
