export const selectSchool = schoolId => ({
  type: 'SELECT_SCHOOL',
  schoolId
});

export const deselectSchool = () => ({
  type: 'DESELECT_SCHOOL'
});

export const setInvitationCode = (invitationCode, invitationCodeProfile) => ({
  type: 'SET_INVITATION_CODE',
  invitationCode,
  invitationCodeProfile
});

export const resetInvitationCode = () => ({
  type: 'RESET_INVITATION_CODE'
});

export const changeForm = isFormDirty => ({
  type: 'CHANGE_FORM',
  isFormDirty
});

export const setOnboardingCodeError = error => ({
  type: 'SET_ONBOARDING_CODE_ERROR',
  error
});

export const changeUserRole = userRole => ({
  type: 'CHANGE_USER_ROLE',
  userRole
});
