import * as React from 'react';
const SvgResultIncorrect = ({ size = '16', color, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <circle cx={12} cy={12} r={12} fill={color ?? 'currentColor'} />
    <path
      d="M8.097 14.147a1.021 1.021 0 1 0 1.445 1.445l2.362-2.362 2.361 2.362a1.021 1.021 0 1 0 1.445-1.445l-2.362-2.362 2.362-2.362a1.022 1.022 0 0 0-1.445-1.444l-2.361 2.362-2.362-2.362a1.022 1.022 0 0 0-1.445 1.444l2.362 2.362-2.362 2.362Z"
      fill="#fff"
    />
  </svg>
);
export default SvgResultIncorrect;
