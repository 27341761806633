import { createContext, useState, useEffect } from 'react';
import CookieBanner from './CookieBanner';

export const cookies = [
  {
    name: 'essential',
    label: 'Essential Cookiess',
    description:
      'These cookies are necessary for the website to function and cannot be switched off.',
    defaultConsent: true,
    disabled: true
  },
  {
    name: 'functional',
    label: 'Functional Cookies',
    description:
      'These cookies enable the website to provide enhanced functionality and personalisation.',
    defaultConsent: false,
    disabled: false,
    services: ['Google Analytics', 'Hotjar']
  },
  {
    name: 'analytics',
    label: 'Analytics Cookies',
    description:
      'These cookies collect information about how you use the website, such as the pages you visit and the links you click on.',
    defaultConsent: false,
    disabled: false,
    services: ['Google Analytics', 'Hotjar']
  }
];

export const CookieContext = createContext();

export const CookieProvider = ({ children }) => {
  const [cookieConsent, setCookieConsent] = useState(null);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    setCookieConsent(JSON.parse(consent));
  }, []);

  const updateConsent = consent => {
    localStorage.setItem('cookieConsent', JSON.stringify(consent));
    setCookieConsent(consent);
  };

  return (
    <CookieContext.Provider value={{ cookieConsent, updateConsent }}>
      {!cookieConsent && <CookieBanner />}
      {children}
    </CookieContext.Provider>
  );
};
