import { Button, Container, Row, Col } from 'components/common';
import { getImageUrl } from 'config/settings';
import { useSelector } from 'react-redux';

const OnboardingIntro = () => {
  const firstName = useSelector(s => s.user.firstName);
  const schoolId = useSelector(s => s.school.schoolId);

  return (
    <Container className="container-lg">
      <div className="text-center mb-5">
        <h1>Welcome {firstName}!</h1>
        <h2 className="subtitle">
          Use Eedi to get the results you want without the workload
        </h2>
      </div>
      <Row>
        <Col sm={4} className="text-center">
          <img
            src={getImageUrl('eedi/onboarding/welcome-image-1.png')}
            alt=""
            className="w-100 mb-4"
          />
          <h3>Save time</h3>
          <p className="text-lg">
            Diagnostics, fluency, teaching resources, intelligent practice and
            problem solving questions all in one place, no more scouring the
            internet!
          </p>
        </Col>
        <Col sm={4} className="text-center">
          <img
            src={getImageUrl('eedi/onboarding/welcome-image-2.png')}
            alt=""
            className="w-100 mb-4"
          />
          <h3>Reduce your workload</h3>
          <p className="text-lg">
            Eedi provides differentiated support for your students so you can
            spend more time with those who need it.
          </p>
        </Col>
        <Col sm={4} className="text-center">
          <img
            src={getImageUrl('eedi/onboarding/welcome-image-3.png')}
            alt=""
            className="w-100 mb-4"
          />
          <h3>Improve every child's results with minimal effort.</h3>
          <p className="text-lg">
            Eedi's best-in-class pedagogy re-teaches concepts to low-achievers
            and reinforces with fluency practice; high-achievers receive
            intelligent practice and problem solving tasks to secure and master
            concepts.
          </p>
        </Col>
      </Row>

      <div className="text-center py-4">
        <Button
          to={!schoolId ? '/schools' : '/'}
          color="primary"
          size="large"
          className="px-5">
          Set up my teacher account
        </Button>
      </div>
    </Container>
  );
};

export default OnboardingIntro;
