import { Button, Input } from 'components/common';
import SendIcon from './SendIcon';
import styled from 'styled-components';
import { FlowItemType } from '../helpers/flowItemHelpers';
import Loading from 'components/common/Loading';
import { useEffect } from 'react';

const InputBar = ({
  onChange,
  value,
  onClick,
  placeholder = '',
  disabled = false,
  currentMessage = null,
  currentFlowItemGroup = null,
  isDispatching = true
}) => {
  useEffect(() => {
    const handleKeyPress = event => {
      if (event.key === 'Enter' || event.keyCode === 13) {
        onClick();
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [onClick]);
  return (
    <InputBarWrapper>
      <StyledInput
        disabled={
          disabled ||
          currentMessage?.flowItemType?.includes('DiagnosticQuestion') ||
          (currentMessage?.flowItemType === FlowItemType.RadioButtonInput &&
            !!currentFlowItemGroup &&
            currentFlowItemGroup === currentMessage?.flowItemGroup)
        }
        name="input"
        autoComplete="off"
        placeholder={placeholder}
        value={value}
        onChange={onChange}></StyledInput>
      <Button outline onClick={onClick} disabled={!value || disabled}>
        {!isDispatching ? (
          <SendIcon />
        ) : (
          <div style={{ width: '24px', height: '24px' }}>
            <Loading />
          </div>
        )}
      </Button>
    </InputBarWrapper>
  );
};

const InputBarWrapper = styled.div`
  display: flex;
  input {
    margin: 0 0.5rem 0 0;
  }
`;

const StyledInput = styled(Input)`
  border: 1px solid #999;
`;

export default InputBar;
