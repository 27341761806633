import * as React from 'react';
const SvgMisconceptionIdentified = ({ size = '16', color, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M11.999 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm-7-2a7 7 0 1 1 14 0 7 7 0 0 1-14 0Zm7-5a5 5 0 1 0 0 10 5 5 0 0 0 0-10ZM0 12a12 12 0 1 1 24 0 12 12 0 0 1-24 0ZM11.999 2a10 10 0 1 0 0 20.001 10 10 0 0 0 0-20Z"
      fill={color ?? 'currentColor'}
    />
  </svg>
);
export default SvgMisconceptionIdentified;
