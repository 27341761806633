import ChatBubble from '../components/ChatBubble';
import FormattedTextContent from './FormattedTextContent';

const TextMessage = ({ message, fromdirection, botAvatar = null }) => {
  return (
    <ChatBubble
      fromdirection={fromdirection}
      userId={message.userId}
      text={message.text}
      botAvatar={botAvatar}>
      <FormattedTextContent content={message.text} />
    </ChatBubble>
  );
};

export default TextMessage;
