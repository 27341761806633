import { Progress } from 'reactstrap';
import styled from 'styled-components';
import Tooltip from './Tooltip';

const ProgressBar = ({
  currentIndex,
  total = 100,
  img,
  imgAlt,
  infoText,
  showFooter = false,
  tooltipText = null,
  color = null,
  style = null,
  ...props
}) => (
  <Container {...props}>
    {infoText && <InfoText id="progressbar-info-text">{infoText}</InfoText>}
    <StyledProgress
      id="progressbar"
      value={(currentIndex / total) * 100}
      color={color}
      style={style}
    />
    {img && <ProgressGoal src={img} alt={imgAlt ?? 'Progress Goal'} />}
    {showFooter && (
      <Footer style={img ? { marginTop: '1rem' } : undefined}>
        <FooterSummary>Current Progress</FooterSummary>
        <FooterTotal>
          {currentIndex}/{total}
        </FooterTotal>
      </Footer>
    )}
    {tooltipText && (
      <Tooltip placement="bottom" target="progressbar">
        {tooltipText}
      </Tooltip>
    )}
  </Container>
);

const Container = styled.div`
  position: relative;
`;

const InfoText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: var(--gray-dark);
`;

const StyledProgress = styled(Progress)`
  box-shadow: none;
  border-radius: 99rem;
  ${({ color }) =>
    color &&
    `
  .progress-bar {
    background-color: var(--${color});
  }
`}
`;

const ProgressGoal = styled.img`
  position: absolute;
  right: -2rem;
  top: 0;
`;

const Footer = styled.div`
  display: flex;
  flex-flow: row wrap;
  color: var(--gray);
  font-size: 14px;
`;

const FooterSummary = styled.div`
  display: flex;
  flex: 1;
  align-self: flex-start;
`;

const FooterTotal = styled.div`
  justify-content: flex-end;
`;

export default ProgressBar;
