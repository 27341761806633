import * as React from 'react';
const SvgResultResolved = ({ size = '16', color, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M9.283 1.125c1.5-1.5 3.933-1.5 5.434 0l8.158 8.158c1.5 1.5 1.5 3.933 0 5.434l-8.158 8.158a3.842 3.842 0 0 1-5.434 0l-8.158-8.158a3.842 3.842 0 0 1 0-5.434l8.158-8.158Z"
      fill={color ?? 'currentColor'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.078 8.62a.873.873 0 0 1 .152 1.29l-4.625 5.33a1.02 1.02 0 0 1-.728.342c-.285.011-.561-.09-.759-.28l-2.775-2.665a.87.87 0 0 1 .025-1.298 1.049 1.049 0 0 1 1.402.023l1.994 1.915 3.92-4.517a1.046 1.046 0 0 1 1.394-.14Z"
      fill="#fff"
    />
  </svg>
);
export default SvgResultResolved;
