/**
 * Returns a number whose value is limited to the given range.
 *
 * @param {number} v value to clamp
 * @param {number} min minimum value to return
 * @param {number} max maximum value to return
 * @return {number} v clamped between min and max
 */
export function clamp(v, min, max) {
  return Math.min(Math.max(v, min), max);
}

export default clamp;
