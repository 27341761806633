export const setJoinStatus = (status, error) => ({
  type: 'SET_JOIN_CLASS_STATUS',
  status,
  error
});

export const changeField = (name, value) => ({
  type: 'CHANGE_JOIN_CLASS_SCHOOL_FIELD',
  name,
  value
});

export const prepareToJoin = fields => ({
  type: 'PREPARE_TO_JOIN_CLASS',
  fields
});

export const prepareToAdd = code => ({
  type: 'PREPARE_TO_ADD_CLASS',
  code
});

export const clearFields = () => ({
  type: 'JOIN_CLASS_STEPPER_SCHOOL_CLEAR'
});

export const clearIsDirtyStatuses = () => ({
  type: 'JOIN_CALSS_CLEAR_IS_DIRTY_STATUSES'
});

export const toggleStudent = student => ({
  type: 'JOIN_CLASS_STEPPER_STUDENT_TOGGLE',
  student
});

export const addStudent = student => ({
  type: 'JOIN_CLASS_STEPPER_STUDENT_ADD',
  student
});

export const updateStudent = student => ({
  type: 'JOIN_CLASS_STEPPER_STUDENT_UPDATE',
  student
});

export const toggleTeacher = teacherId => ({
  type: 'JOIN_CLASS_STEPPER_TEACHER_TOGGLE',
  teacherId
});

export const populateTeachers = teachers => ({
  type: 'JOIN_CLASS_STEPPER_POPULATE_TEACHERS',
  teachers
});

export const addTeacher = teacher => ({
  type: 'JOIN_CLASS_STEPPER_TEACHER_ADD',
  teacher
});
