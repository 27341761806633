import styled from 'styled-components';

import TextAtom from './TextAtom';
import { colors } from 'styles';

const TextXS = ({ fontFamily, color, children, ...props }) => (
  <TextAtom fontFamily={fontFamily} color={color} size={8} {...props}>
    {children}
  </TextAtom>
);

TextXS.defaultProps = {
  color: 'bodyText',
  fontFamily: 'book'
};

TextXS.Link = ({ textColor, underlineColor, children, ...rest }) => (
  <StyledLink textColor={textColor} underlineColor={underlineColor} {...rest}>
    {children}
  </StyledLink>
);

const StyledLink = styled.a`
  cursor: pointer;
  color: ${({ textColor }) => `${colors[textColor]}`};
  border-bottom: ${({ underlineColor }) =>
    underlineColor
      ? `0.125rem solid ${colors[underlineColor]}`
      : `0.125rem solid var(--primary)`};
`;

export default TextXS;
