import * as React from 'react';
import Input from './Input';
import Icon from './Icon';
import styled from 'styled-components';

const SearchInput = ({
  onClear,
  value,
  dataSource = [],
  className,
  ...props
}) => {
  return (
    <Wrapper className={className}>
      <Search>
        <Icon name="search" />
      </Search>
      <StyledInput
        list={dataSource.length > 0 ? 'autocomplete-list' : ''}
        value={value}
        {...props}
      />
      {value && (
        <Clear onClick={onClear}>
          <Icon name="close" />
        </Clear>
      )}
      <datalist id="autocomplete-list">
        {dataSource.map(({ key, value }, i) => (
          <option key={i} value={value}>
            {key}
          </option>
        ))}
      </datalist>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledInput = styled(Input)`
  border-radius: 99rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
`;

const Search = styled.div`
  position: absolute;
  left: 0.75rem;
  top: 0.5rem;
`;

const Clear = styled.div`
  cursor: pointer;
  position: absolute;
  right: 0.75rem;
  top: 0.5rem;
`;

export default SearchInput;
