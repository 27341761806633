import * as React from 'react';
const SvgCheck = ({ size = '16', color, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M8.40833 19.4288C8.40833 19.1257 8.52873 18.835 8.74306 18.6207C8.95739 18.4064 9.24808 18.286 9.55118 18.286H11.8369C12.14 18.286 12.4307 18.4064 12.645 18.6207C12.8593 18.835 12.9798 19.1257 12.9798 19.4288V22.8574C12.9798 23.1605 12.8593 23.4512 12.645 23.6655C12.4307 23.8798 12.14 24.0002 11.8369 24.0002H9.55118C9.24808 24.0002 8.95739 23.8798 8.74306 23.6655C8.52873 23.4512 8.40833 23.1605 8.40833 22.8574V19.4288ZM14.1226 14.8574C14.1226 14.5543 14.243 14.2636 14.4573 14.0493C14.6717 13.8349 14.9624 13.7145 15.2655 13.7145H17.5512C17.8543 13.7145 18.145 13.8349 18.3593 14.0493C18.5736 14.2636 18.694 14.5543 18.694 14.8574V22.8574C18.694 23.1605 18.5736 23.4512 18.3593 23.6655C18.145 23.8798 17.8543 24.0002 17.5512 24.0002H15.2655C14.9624 24.0002 14.6717 23.8798 14.4573 23.6655C14.243 23.4512 14.1226 23.1605 14.1226 22.8574V14.8574ZM19.8369 9.1431C19.8369 8.84 19.9573 8.54931 20.1716 8.33498C20.386 8.12065 20.6767 8.00024 20.9798 8.00024H23.2655C23.5686 8.00024 23.8593 8.12065 24.0736 8.33498C24.2879 8.54931 24.4083 8.84 24.4083 9.1431V22.8574C24.4083 23.1605 24.2879 23.4512 24.0736 23.6655C23.8593 23.8798 23.5686 24.0002 23.2655 24.0002H20.9798C20.6767 24.0002 20.386 23.8798 20.1716 23.6655C19.9573 23.4512 19.8369 23.1605 19.8369 22.8574V9.1431Z"
      fill={color ?? 'currentColor'}
    />
  </svg>
);
export default SvgCheck;
