const onboarding = (state = {}, action) => {
  switch (action.type) {
    case 'SELECT_SCHOOL':
      return {
        ...state,
        schoolId: action.schoolId
      };
    case 'DESELECT_SCHOOL':
      return {
        ...state,
        schoolId: null
      };
    case 'CHANGE_FORM':
      return {
        ...state,
        isFormDirty: action.isFormDirty
      };
    case 'CHANGE_USER_ROLE':
      return {
        ...state,
        role: action.userRole
      };
    case 'SET_INVITATION_CODE':
      return {
        ...state,
        invitationCode: action.invitationCode,
        invitationCodeProfile: action.invitationCodeProfile
      };
    case 'RESET_INVITATION_CODE':
      return {
        ...state,
        invitationCode: null,
        invitationCodeProfile: null
      };
    case 'SET_ONBOARDING_CODE_ERROR':
      return {
        ...state,
        codeError: action.error
      };
    default:
      return state;
  }
};

export default onboarding;
