import * as React from 'react';
const SvgTriangleRight = ({ size = '16', color, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M9.419 5.883 16.759 12l-7.34 6.117V5.883Z"
      fill={color ?? 'currentColor'}
    />
  </svg>
);
export default SvgTriangleRight;
