import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

const Fade = ({ children, timeout = 500, ...props }) => (
  <FadeTransition {...props} timeout={timeout} classNames="fade">
    <div>{children}</div>
  </FadeTransition>
);

const FadeTransition = styled(CSSTransition)`
  .fade-enter {
    opacity: 0;
  }
  .fade-enter-active {
    opacity: 1;
    transition: opacity
      ${({ duration }) => (!!duration ? `${duration}ms` : '1000ms')};
  }
  .fade-exit {
    display: none;
  }

  .fade-exit-active {
    display: none;
  }
`;

export { Fade };
