import { DISABLE_ANALYTICS } from 'config/settings';
import { Intercom } from './providers/Intercom';
import { Api } from './providers/Api';
import { Mixpanel } from './providers/Mixpanel';
import { Sentry } from './providers/Sentry';
//import { LogRocket } from './providers/LogRocket';
import { GoogleAnalytics } from './providers/GoogleAnalytics';
import { AppCues } from './providers/AppCues';

const vendors = [
  new GoogleAnalytics(),
  new Intercom(),
  new Sentry(),
  new Api(),
  new Mixpanel(),
  new AppCues()
  //new LogRocket()
];

let hasInitialised = false;

const init = () => {
  vendors.forEach(vendor => {
    if (typeof vendor.init === 'function') {
      vendor.init();
    }
  });
  hasInitialised = true;
};

// Short hand function to call the corresponding methods in each vendor
const fire = (method, ...args) => {
  const isAnalyticsEnabled = DISABLE_ANALYTICS !== 'true';

  if (!hasInitialised || !isAnalyticsEnabled) {
    return;
  }

  vendors.forEach(vendor => {
    if (typeof vendor[method] === 'function') {
      try {
        vendor[method](...args);
      } catch (e) {
        console.error(e);
      }
    }
  });
};

export const analytics = {
  init: () => init(),
  page: (location = null) => fire('page', location || window.location.pathname),
  identify: user => fire('identify', user),
  set: (props, option) => fire('set', props, option),
  track: (event, data, option) => fire('track', event, data, option),
  // ‘fatal’, ‘error’, ‘warning’, ‘info’, and ‘debug’
  trackError: (error, extra = {}, severity = 'error') =>
    fire('trackError', error, extra, severity),
  trackApi: (event, data) => fire('trackApi', event, data),
  shutdown: () => fire('shutdown'),
  intercom: vendors[1]
};

export default analytics;
