export const IconNames = {
  apple: 'apple',
  android: 'android',
  archive: 'archive',
  add: 'add',
  addFilled: 'addFilled',
  addOutline: 'addOutline',
  arrowLeft: 'arrowLeft',
  arrowRight: 'arrowRight',
  attention: 'attention',
  back: 'back',
  forward: 'forward',
  bin: 'bin',
  calendar: 'calendar',
  camera: 'camera',
  checkboxOff: 'checkboxOff',
  checkboxOn: 'checkboxOn',
  clock: 'clock',
  close: 'close',
  closeOutline: 'closeOutline',
  contextual: 'contextual',
  copy: 'copy',
  down: 'down',
  edit: 'edit',
  eyeOff: 'eyeOff',
  eyeOn: 'eyeOn',
  info: 'info',
  listing: 'listing',
  mail: 'mail',
  menu: 'menu',
  message: 'message',
  minusFilled: 'minusFilled',
  minusOutline: 'minusOutline',
  phone: 'phone',
  pin: 'pin',
  radioButtonOff: 'radioButtonOff',
  radioButtonOn: 'radioButtonOn',
  search: 'search',
  student: 'student',
  teacher: 'teacher',
  tick: 'tick',
  upload: 'upload',
  user: 'user',
  users: 'users',
  web: 'web',
  locker: 'locker',
  link: 'link',
  thumbsUp: 'thumbsUp',
  thumbsDown: 'thumbsDown',
  triangle: 'triangle',
  video: 'video',
  spreadsheet: 'spreadsheet',
  checkboxMinus: 'checkboxMinus',
  invoice: 'invoice',
  misconceptions: 'misconceptions',
  settings: 'settings'
};

export default IconNames;
