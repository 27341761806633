const SvgSort = ({
  size = '9',
  color,
  order = 'desc',
  selected = false,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 9 8"
    {...props}>
    <path
      fill={color ?? 'currentColor'}
      style={{ opacity: selected ? 1 : 0.5 }}
      d="M0.5 0.5H8.5L4.5 7.5"
      transform={order === 'desc' ? 'rotate(180 4 3.5)' : ''}></path>
  </svg>
);

export default SvgSort;
