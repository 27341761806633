import TickInCircleIcon from 'assets/icons/tickInCircle';
import ExclamationInTriangleIcon from 'assets/icons/exclamationInTriangle';
import InfoInCircleIcon from 'assets/icons/infoInCircle';
import SlashInCircleIcon from 'assets/icons/slashInCircle';
import CrossInCircleIcon from 'assets/icons/crossInCircle';

const IntentIcon = ({ color, intent, width, height }) => {
  const renderIcon = () => {
    switch (intent) {
      case 'success':
        return (
          <TickInCircleIcon
            color={color || 'var(--body-color)'}
            style={{ width, height }}
          />
        );
      case 'warning':
        return (
          <ExclamationInTriangleIcon
            color={color || 'var(--body-color)'}
            style={{ width, height }}
          />
        );
      case 'info':
        return (
          <InfoInCircleIcon
            color={color || 'var(--body-color)'}
            width={width}
            height={height}
          />
        );
      case 'danger':
        return (
          <SlashInCircleIcon
            color={color || 'var(--body-color)'}
            style={{ width, height }}
          />
        );
      case 'wrong':
        return (
          <CrossInCircleIcon
            color={color || 'var(--body-color)'}
            style={{ width, height }}
          />
        );
      default:
        return null;
    }
  };

  return renderIcon();
};

export default IntentIcon;
