const colorList = [
  'gray',
  'indigo',
  'blue',
  'green',
  'yellow',
  'orange',
  'pink',
  'red',
  'purple',
  'gold',
  'silver',
  'bronze'
];

const hashStr = str => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i);
    hash += charCode;
  }
  return hash;
};

const stringToColor = (string, colors = []) => {
  if (!string) {
    return 'gray4';
  }
  const hash = hashStr(string);
  colors = colors.length ? colors : colorList;
  const index = hash % colors.length;
  return colors[index];
};

export const getTopicColorFromString = (colorCode, topicUnitId, title) => {
  const colorString = topicUnitId ? topicUnitId.toString() : title;

  if (colorCode) {
    switch (colorCode) {
      case 'unitTypeA':
        return 'blue';
      case 'unitTypeB':
        return 'green';
      case 'unitTypeC':
        return 'red';
      case 'unitTypeD':
        return 'pink';
      case 'holiday':
        return 'gray4';
      case 'empty':
        return 'tint';
      default:
        return 'gray4';
    }
  }

  if (colorString) {
    return stringToColor(colorString);
  }

  return 'gray4';
};

export default stringToColor;
