import ChatBubble from '../components/ChatBubble';
import styled from 'styled-components';
import useVideoPlayer from 'hooks/useVideoPlayer';

const VideoMessage = ({ message, fromdirection, botAvatar = null }) => {
  const { video } = useVideoPlayer({
    useNativeControls: true,
    thumbnailUrl: message.videoUrl.replace('.mp4', '.jpeg'),
    src: message.videoUrl
  });
  return (
    <ChatBubble
      key={`image-${message.id}`}
      fromdirection={fromdirection}
      userId={message.userId}
      text={message.text}
      isClipboard={false}
      botAvatar={botAvatar}
      style={{ width: '100%' }}>
      <ImageWrapper>
        <>{video}</>
      </ImageWrapper>
      {message.text}
    </ChatBubble>
  );
};

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  video {
    width: 100%;
    height: auto;
  }
`;

export default VideoMessage;
