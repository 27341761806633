import { useState } from 'react';
import ChatBubble from '../components/ChatBubble';
import PresentModal from '../components/PresentModal';
import styled from 'styled-components';

const ImageMessage = ({ message, fromdirection, botAvatar = null }) => {
  const [isPresenting, setIsPresenting] = useState(false);
  const [isActive, setIsActive] = useState(false);
  return (
    <ChatBubble
      key={`image-${message.id}-${isActive}`}
      fromdirection={fromdirection}
      userId={message.userId}
      text={message.text}
      isClipboard={!isActive}
      botAvatar={botAvatar}>
      <ImageWrapper
        onMouseEnter={() => setIsActive(true)}
        onMouseLeave={() => setIsActive(false)}
        onTouchStart={() => setIsActive(true)}
        onTouchEnd={() => setIsActive(false)}>
        <ImageOverlay>
          {isActive && <h3 onClick={() => setIsPresenting(true)}>Present</h3>}
          <img
            style={{ maxWidth: '600px' }}
            src={message.imageUrl}
            alt="Oops - source not found"
          />
        </ImageOverlay>
      </ImageWrapper>
      {message.text}
      <PresentModal isModalOpen={isPresenting} setIsModalOpen={setIsPresenting}>
        <PresentContent>
          <img src={message.imageUrl} alt="Oops - source not found" />
          <div>{message.text}</div>
        </PresentContent>
      </PresentModal>
    </ChatBubble>
  );
};

const ImageWrapper = styled.div`
  position: relative;
  h3 {
    font-size: 27px;
    position: absolute;
    color: white;
    z-index: 999;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ImageOverlay = styled.div`
  width: 100%;
  height: 100%;
  z-index: 998;
  img {
    width: 100%;
  }
`;

const PresentContent = styled.div`
  display: flex;
  flex-direction: row;
  div {
    margin: 0 0 0 2rem;
  }
`;

export default ImageMessage;
