import styled from 'styled-components';
import { diagnosticQuestionRadionOptions } from '../helpers/flowItemHelpers';
import { Button } from 'components/common';
import ChatBubble from '../components/ChatBubble';
import FormattedTextContent from './FormattedTextContent';
import { useNavigate } from 'react-router';

const RadioButton = ({
  message,
  sendMessage,
  currentFlowItemGroup,
  isDispatching = false,
  isReadOnly = false,
  botAvatar = null
}) => {
  const navigate = useNavigate();

  if (currentFlowItemGroup !== message.flowItemGroup) return null;
  if (
    message.flowItemType.includes('DiagnosticQuestion') &&
    !message.radioButtons.length
  ) {
    message.radioButtons = diagnosticQuestionRadionOptions;
  }

  const onClick = ({ message, radioButton }) => {
    if (radioButton.value.includes('teacher.eedi.com')) {
      // extract relative url from value
      const relativeUrl = radioButton.value
        .replace('teacher.eedi.com', '')
        .replace('https://', '')
        .replace('http://', '');

      navigate(relativeUrl);
      return;
    }

    if (radioButton.value.includes('http')) {
      window.open(radioButton.value, '_blank');
      return;
    }

    sendMessage({
      flowItemGroup: message.flowItemGroup,
      input: radioButton.value
    });
  };

  return (
    <>
      {message.text && (
        <ChatBubble
          userId={message.userId}
          text={message.text}
          botAvatar={botAvatar}>
          <FormattedTextContent content={message.text} />
        </ChatBubble>
      )}
      <RadioButtonWrapper>
        <div>Choose an option:</div>
        {message.radioButtons.map((radioButton, index) => {
          return (
            <Button
              outline
              color="primary"
              key={index}
              disabled={isDispatching || isReadOnly}
              onClick={() => onClick({ message, radioButton })}>
              {radioButton.label}
            </Button>
          );
        })}
      </RadioButtonWrapper>
    </>
  );
};

const RadioButtonWrapper = styled.div`
  div {
    font-weight: 500;
    font-size: 16px;
  }
  button {
    margin: 0.5rem 0.5rem 0.5rem 0;
  }
`;

export default RadioButton;
