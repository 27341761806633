import * as SentryBrowser from '@sentry/browser';
import { ENVIRONMENT } from 'config/settings';

export class Sentry {
  init() {
    if (ENVIRONMENT === 'development') {
      SentryBrowser.init({
        dsn: 'https://fbbca01316404b85adbe176420d62db7@sentry.io/183620',
        environment: ENVIRONMENT,
        debug: true
      });
    } else {
      SentryBrowser.init({
        dsn: 'https://fbbca01316404b85adbe176420d62db7@sentry.io/183620',
        environment: ENVIRONMENT
      });
    }
  }
  identify(user) {
    SentryBrowser.configureScope(scope => {
      scope.setUser({
        email: user.email,
        id: user.userId
      });
    });
  }
  trackError(error, extra, severity) {
    SentryBrowser.configureScope(scope => {
      scope.setLevel(severity);
      for (let detail in extra) {
        scope.setExtra(detail, extra[detail]);
      }
    });
    SentryBrowser.captureException(error);
  }
}
