import React, { Component } from 'react';
import ReactModal from 'react-responsive-modal';
import styled from 'styled-components';

import TextS from './TextS';
import Button from './Button';
import { colors } from 'styles';

class Modal extends Component {
  props;

  render() {
    const {
      onButtonClick,
      buttonText,
      text,
      isOpen,
      onClose,
      closeButtonSmall,
      header,
      headerText,
      children,
      footer,
      buttonColor,
      backgroundColor,
      big
    } = this.props;
    return (
      <ReactModal
        styles={getStyles(big)}
        showCloseIcon={false}
        open={isOpen}
        onClose={onClose || (() => null)}
        {...this.props}>
        <Wrapper backgroundColor={backgroundColor}>
          <Container big={big}>
            <TopContainer>
              <TextContainer>
                {header ? header : <h4>{headerText}</h4>}
              </TextContainer>
              {onClose && (
                <CloseButtonContainer>
                  <Button
                    small={closeButtonSmall}
                    onClick={onClose}
                    leftIconName="close"
                    color={backgroundColor ? `${backgroundColor}D2` : 'gray2'}
                  />
                </CloseButtonContainer>
              )}
            </TopContainer>
            <Content>{children ? children : <TextS>{text}</TextS>}</Content>
            {footer && footer}
            {!footer && buttonText && (
              <ButtonContainer>
                <Button onClick={onButtonClick} color={buttonColor || 'tint'}>
                  {buttonText}
                </Button>
              </ButtonContainer>
            )}
          </Container>
        </Wrapper>
      </ReactModal>
    );
  }
}

const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 3;
`;

const TextContainer = styled.div`
  display: flex;
  flex: 7;
  margin-right: 3rem;
`;

const Wrapper = styled.div`
  padding: 3rem;
  background-color: red;
  background-color: ${({ backgroundColor }) =>
    colors[backgroundColor] || 'var(--tint-color)'};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ big }) => (big ? '100%' : 'auto')};
  min-width: ${({ big }) => (big ? 'auto' : '18rem')};
  max-width: ${({ big }) => (big ? 'auto' : '42rem')};
`;

const TopContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

const Content = styled.div`
  padding: 3rem 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-self: flex-end;
  margin-top: 3rem;
`;

const getStyles = big => ({
  modal: {
    padding: '0',
    borderRadius: '0.125rem',
    border: 'none',
    boxShadow: '0 16px 24px 1px rgba(0, 0, 0, 0.16)',
    minWidth: big ? '80%' : 'auto'
  },
  overlay: {
    backgroundColor: 'rgba(52, 51, 52, 0.64)',
    alignItems: big ? 'stretch' : 'center'
  }
});

export default Modal;
