import {
  Card as ReactstrapCard,
  CardImg,
  CardBody,
  CardHeader,
  CardFooter
} from 'reactstrap';
import styled from 'styled-components';

const Card = styled(ReactstrapCard)``;

Card.Img = CardImg;

Card.Header = styled(CardHeader)`
  display: flex;
  position: relative;
  padding: 1rem 1.75rem;
  justify-content: space-between;
  align-items: center;
  min-height: 4.625rem;
  border-bottom: none;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  ${props =>
    props.tint
      ? `
    background-color: var(--tint-color);
    border-bottom: 1px solid var(--border-color);
    `
      : `
  & + .card-body {
    padding-top: 0;
  }
  `};
`;

Card.Header.Left = styled.div`
  position: absolute;
  left: 1rem;
`;

Card.Header.Center = styled.div`
  margin: 0 auto;
`;

Card.Header.Right = styled.div`
  position: absolute;
  right: 1rem;
`;

Card.Item = styled(CardBody)`
  border-bottom: solid 1px var(--border-color);
  &:last-child {
    border-bottom: 0;
  }
`;

Card.Body = CardBody;

Card.Footer = styled(CardFooter)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  padding-top: 0;
  div:only-child {
    margin-left: auto;
  }
`;

Card.Title = styled.div`
  padding-bottom: 1rem;
  h1 {
    font-size: 1.5rem;
  }
`;

export default Card;
