function ChallengeBronze() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="3rem"
      height="3rem"
      fill="none"
      viewBox="0 0 47 48">
      <path
        d="M20.7396 1.99221C22.2866 1.09904 24.1926 1.09904 25.7396 1.9922L40.8658 10.7253C42.4128 11.6184 43.3658 13.2691 43.3658 15.0554V32.5215C43.3658 34.3079 42.4128 35.9585 40.8658 36.8517L25.7396 45.5847C24.1926 46.4779 22.2867 46.4779 20.7396 45.5847L5.61352 36.8517C4.06652 35.9585 3.11352 34.3079 3.11352 32.5215V15.0554C3.11352 13.2691 4.06652 11.6184 5.61352 10.7253L20.7396 1.99221Z"
        fill="white"
      />
      <path
        d="M20.7394 5.34767C22.2864 4.45451 24.1924 4.45451 25.7394 5.34767L37.9602 12.4034C39.5072 13.2965 40.4602 14.9472 40.4602 16.7335V30.8449C40.4602 32.6312 39.5072 34.2818 37.9602 35.175L25.7394 42.2307C24.1924 43.1238 22.2864 43.1238 20.7394 42.2307L8.51856 35.175C6.97155 34.2818 6.01856 32.6312 6.01856 30.8449V16.7335C6.01856 14.9472 6.97155 13.2965 8.51856 12.4034L20.7394 5.34767Z"
        fill="#E1AA68"
      />
      <path
        d="M20.7394 8.72463C22.2864 7.83146 24.1924 7.83146 25.7394 8.72463L35.0358 14.0919C36.5828 14.985 37.5358 16.6357 37.5358 18.422V29.1565C37.5358 30.9428 36.5828 32.5934 35.0358 33.4866L25.7394 38.8539C24.1924 39.747 22.2864 39.747 20.7394 38.8539L11.4431 33.4866C9.89609 32.5934 8.9431 30.9428 8.9431 29.1565V18.422C8.9431 16.6357 9.89609 14.985 11.4431 14.0919L20.7394 8.72463Z"
        fill="#EBBC78"
      />
      <path
        d="M12.9674 16.5291L13.3634 14.4411H21.7694V16.2951C20.3114 18.0051 17.5934 21.8031 17.5574 26.7891H14.9654C15.0014 22.2171 17.4674 18.3111 18.9434 16.5291H12.9674ZM23.6884 23.8731L26.0824 23.2071C26.1364 24.1611 26.9284 24.9711 28.0444 24.9711C29.3044 24.9711 30.0784 23.9991 30.0784 22.6851C30.0784 21.5151 29.5024 20.5431 28.1884 20.5431C27.6484 20.5431 26.7304 20.7051 26.2984 21.6951L24.0664 21.2811L24.8224 14.4411H31.9864L31.6264 16.5291H26.6944L26.3344 19.4631C26.8024 18.8691 27.5764 18.4731 28.7824 18.4731C31.4644 18.4731 32.7064 20.5971 32.7064 22.6491C32.7064 25.0971 30.9604 27.0411 28.0084 27.0411C25.3444 27.0411 23.7784 25.4031 23.6884 23.8731Z"
        fill="#B07835"
      />
      <path
        d="M21.2161 36.0306H20.0461L24.4261 28.7506H25.5961L21.2161 36.0306ZM21.9661 30.7706C21.9661 31.6806 21.5361 32.6506 20.2561 32.6506C18.9761 32.6506 18.5461 31.6806 18.5461 30.7706C18.5461 29.8606 18.9761 28.8906 20.2561 28.8906C21.5361 28.8906 21.9661 29.8606 21.9661 30.7706ZM19.5961 30.7706C19.5961 31.3506 19.7961 31.7806 20.2561 31.7806C20.7161 31.7806 20.9161 31.3506 20.9161 30.7706C20.9161 30.1906 20.7161 29.7606 20.2561 29.7606C19.7961 29.7606 19.5961 30.1906 19.5961 30.7706ZM26.9961 34.1506C26.9961 35.0606 26.5661 36.0306 25.2861 36.0306C24.0061 36.0306 23.5761 35.0606 23.5761 34.1506C23.5761 33.2406 24.0061 32.2706 25.2861 32.2706C26.5661 32.2706 26.9961 33.2406 26.9961 34.1506ZM24.6261 34.1506C24.6261 34.7306 24.8261 35.1606 25.2861 35.1606C25.7461 35.1606 25.9461 34.7306 25.9461 34.1506C25.9461 33.5706 25.7461 33.1406 25.2861 33.1406C24.8261 33.1406 24.6261 33.5706 24.6261 34.1506Z"
        fill="#B07835"
      />
    </svg>
  );
}

export default ChallengeBronze;
