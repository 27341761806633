import * as React from 'react';
const SvgTriangleLeft = ({ size = '16', color, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M14.581 18.117 7.241 12l7.34-6.117v12.234Z"
      fill={color ?? 'currentColor'}
    />
  </svg>
);
export default SvgTriangleLeft;
