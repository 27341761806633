import styled from 'styled-components';
import { motion } from 'framer-motion';
import Ellipsis from 'assets/icons/Ellipsis';
import { SchoolIcon } from 'assets/icons';
import { Tooltip } from 'components/common';
import StreakPointsPill from 'pages/classLeague/components/StreakPointsPill';

const LeagueItem = ({
  rank,
  totalPoints,
  name,
  icon = null,
  isSelf = false,
  type = null,
  maxLength = 30,
  fontSize = '1.2rem',
  ...props
}) => {
  const label =
    name?.length <= maxLength ? name : name.substring(0, maxLength - 3) + '...';
  const id = `league-item-${rank}`;
  if (type === 'split')
    return (
      <Split>
        <Ellipsis style={{ textAlign: 'center' }} />
      </Split>
    );

  return (
    <>
      <LeagueCard
        id={id}
        {...props}
        isself={isSelf}
        initial={{ scale: 1 }}
        whileHover={{
          scale: 1.1
        }}
        transition={{
          duration: 0.2,
          type: 'spring',
          stiffness: 400,
          damping: 40
        }}>
        <LeagueRank rank={rank}>{rank}</LeagueRank>
        <Info>
          <Icon>{!icon ? <SchoolIcon name={name} /> : icon}</Icon>
          <Label fontsize={fontSize}>{label}</Label>
        </Info>
        <StreakPointsPill
          points={totalPoints}
          key={`${rank}-${name}`}
          tooltip={`${totalPoints} Total streak points aquired by students through topics and lessons`}
        />
      </LeagueCard>
      <Tooltip disabled={name.length < maxLength} target={id}>
        {name}
      </Tooltip>
    </>
  );
};

const Split = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const LeagueCard = styled(motion.div)`
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: 0.5rem;
  background: ${({ isself }) =>
    !isself ? 'var(--white)' : 'var(--green-light)'};
  border-radius: 60px;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
`;

const LeagueRank = styled.div`
  min-width: 18%;
  font-weight: 500;
  font-size: 1.4rem;
  justify-content: center;
  text-align: center;
  margin: 0 0.5rem 0 0;
  color: ${props => {
    switch (props.rank) {
      case 1:
        return 'var(--gold)';
      case 2:
        return 'var(--silver)';
      case 3:
        return 'var(--bronze)';
      default:
        return 'var(--black)';
    }
  }};
`;

const Info = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
`;

const Icon = styled.div`
  margin: 0 1rem 0 0;
  padding: 0.25rem;
  svg {
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.46));
  }
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 1.2rem;
  ${({ fontsize }) => fontsize && `font-size: ${fontsize};`}
`;

export default LeagueItem;
