import * as React from 'react';
const SvgSettings = ({ size = '16', color, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M12.002 15.098a3.23 3.23 0 0 1-2.252-.908A3.055 3.055 0 0 1 8.818 12c0-.822.335-1.61.932-2.19a3.23 3.23 0 0 1 2.252-.907 3.23 3.23 0 0 1 2.252.907c.597.58.932 1.368.932 2.19 0 .822-.335 1.61-.932 2.19a3.23 3.23 0 0 1-2.252.908Zm6.76-2.24a6.69 6.69 0 0 0 .064-.858c0-.292-.028-.584-.064-.885l1.92-1.443a.438.438 0 0 0 .109-.566l-1.82-3.062a.453.453 0 0 0-.555-.195l-2.265.885c-.473-.345-.965-.646-1.538-.867l-.336-2.345a.445.445 0 0 0-.158-.267.468.468 0 0 0-.297-.105h-3.64a.457.457 0 0 0-.455.372l-.336 2.345c-.573.221-1.065.522-1.538.867L5.588 5.85c-.2-.08-.446 0-.555.195l-1.82 3.062a.428.428 0 0 0 .11.566l1.919 1.443c-.037.3-.064.593-.064.885 0 .292.027.575.064.858l-1.92 1.47a.428.428 0 0 0-.109.566l1.82 3.062c.109.195.354.266.555.195l2.265-.894c.473.354.965.655 1.538.876l.336 2.345a.457.457 0 0 0 .455.372h3.64c.227 0 .418-.16.455-.372l.336-2.345a6.67 6.67 0 0 0 1.538-.876l2.265.894c.2.07.446 0 .555-.195l1.82-3.062a.438.438 0 0 0-.11-.566l-1.92-1.47Z"
      stroke={color ?? 'currentColor'}
      strokeWidth={1.6}
    />
  </svg>
);
export default SvgSettings;
