/* @flow */
const joinStatuses = {
  willAdd: 'will-add',
  willJoin: 'will-join',
  loading: 'loading',
  added: 'added',
  joined: 'joined',
  joinedUnverified: 'joined-unverified',
  failed: 'failed'
};

export default joinStatuses;
