import styled from 'styled-components';
import { RadioButton, Card } from 'components/common';

/**
 * The radio card is specifically designed to accept an image and some content as part of multiple
 * radio cards.
 * @param {string} title Title to show in the Card.Title
 * @param {object} body Body to render in the Card.Body
 * @param {string} image URL to the image
 * @param {boolean} isSelected Set radio selection state of this card
 * @param {function} onClick Callback when the radio button is clicked
 * @param {boolean} hiddenByDefault Hide card body if not selected. Default is `false`
 * @param {boolean} disabled
 */
const RadioCard = ({
  title,
  body,
  image,
  isSelected,
  onClick,
  hiddenByDefault = false,
  disabled,
  name,
  value
}) => {
  return (
    <StyledCard checked={isSelected}>
      <Card.Body>
        <RadioButton
          name={name}
          checked={isSelected}
          onClick={onClick}
          label={title}
          value={value}
          disabled={disabled}
        />
        {image && (
          <ImageWrapper>
            <img src={image} alt={title ?? 'image'} />
          </ImageWrapper>
        )}
        {body && (!hiddenByDefault || isSelected) && (
          <RadioDescription>{body}</RadioDescription>
        )}
      </Card.Body>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  margin-bottom: 2.5rem;
  background-color: ${props =>
    props.checked ? 'var(--tint-color)' : 'var(--white)'};
  label {
    margin: 0;
    font-weight: normal;
    color: var(--black);
  }
`;

const RadioDescription = styled.div`
  border-top: 1px solid var(--border-color);
  margin-top: 1rem;
  padding-top: 1rem;
  color: var(--gray-dark);
`;

const ImageWrapper = styled.div`
  position: absolute;
  right: 1.5rem;
  top: -1.5rem;
  svg,
  img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border: 1px solid var(--border-color);
  }
`;

export default RadioCard;
