function ChallengeGold() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="3rem"
      height="3rem"
      fill="none"
      viewBox="0 0 48 47">
      <path
        fill="#fff"
        d="M21.296 1.768a5 5 0 015 0l15.126 8.733a5 5 0 012.5 4.33v17.466a5 5 0 01-2.5 4.33l-15.126 8.734a5 5 0 01-5 0L6.17 36.628a5 5 0 01-2.5-4.33V14.83a5 5 0 012.5-4.33l15.126-8.733z"></path>
      <path
        fill="#FCCA36"
        d="M21.296 5.123a5 5 0 015 0l12.22 7.055a5 5 0 012.5 4.33V30.62a5 5 0 01-2.5 4.33l-12.22 7.056a5 5 0 01-5 0L9.075 34.95a5 5 0 01-2.5-4.33V16.509a5 5 0 012.5-4.33l12.22-7.056z"></path>
      <path
        fill="#FFD33E"
        d="M20.796 8.296a6 6 0 016 0l8.723 5.036a6 6 0 013 5.196V28.6a6 6 0 01-3 5.196l-8.723 5.036a6 6 0 01-6 0l-8.722-5.036a6 6 0 01-3-5.196V18.528a6 6 0 013-5.196l8.722-5.036z"></path>
      <path
        fill="#BD9006"
        d="M11.08 27.564v-1.71h2.43v-6.36c-.404.3-1.124.525-1.904.555l-.39-1.755c1.065-.12 2.025-.48 2.52-1.08H15.7v8.64h2.22v1.71h-6.84zm16.225-5.145c0 3.015-1.11 5.355-3.975 5.355s-3.975-2.34-3.975-5.355c0-3.015 1.11-5.355 3.975-5.355s3.975 2.34 3.975 5.355zm-2.205 0c0-2.055-.495-3.645-1.77-3.645s-1.77 1.59-1.77 3.645c0 2.055.495 3.645 1.77 3.645s1.77-1.59 1.77-3.645zm11.199 0c0 3.015-1.11 5.355-3.975 5.355s-3.975-2.34-3.975-5.355c0-3.015 1.11-5.355 3.975-5.355s3.975 2.34 3.975 5.355zm-2.205 0c0-2.055-.495-3.645-1.77-3.645s-1.77 1.59-1.77 3.645c0 2.055.495 3.645 1.77 3.645s1.77-1.59 1.77-3.645zM21.773 35.806h-1.17l4.38-7.28h1.17l-4.38 7.28zm.75-5.26c0 .91-.43 1.88-1.71 1.88s-1.71-.97-1.71-1.88c0-.91.43-1.88 1.71-1.88s1.71.97 1.71 1.88zm-2.37 0c0 .58.2 1.01.66 1.01s.66-.43.66-1.01c0-.58-.2-1.01-.66-1.01s-.66.43-.66 1.01zm7.4 3.38c0 .91-.43 1.88-1.71 1.88s-1.71-.97-1.71-1.88c0-.91.43-1.88 1.71-1.88s1.71.97 1.71 1.88zm-2.37 0c0 .58.2 1.01.66 1.01s.66-.43.66-1.01c0-.58-.2-1.01-.66-1.01s-.66.43-.66 1.01z"></path>
    </svg>
  );
}

export default ChallengeGold;
