import * as React from 'react';
const SvgMisconceptionResolved = ({ size = '16', color, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M0 12a12 12 0 1 1 24 0 12 12 0 0 1-24 0ZM11.999 2a10 10 0 1 0 0 20.001 10 10 0 0 0 0-20Z"
      fill={color ?? 'currentColor'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.406 7.487c.468.41.515 1.12.106 1.587l-6.416 7.333a1.125 1.125 0 0 1-1.693 0l-2.917-3.333a1.125 1.125 0 0 1 1.693-1.482l2.07 2.366 5.57-6.365a1.125 1.125 0 0 1 1.587-.106Z"
      fill={color ?? 'currentColor'}
    />
  </svg>
);
export default SvgMisconceptionResolved;
