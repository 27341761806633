import { useDispatch, useSelector } from 'react-redux';
import { SmallCard } from 'components/dls';
import { Button, toaster } from 'components/common';
import {
  prepareToJoin,
  prepareToAdd,
  changeField
} from 'actions/joinClassStepper';
import SearchStep from 'components/stepper/SearchStep';
import { useLocation, useNavigate } from 'react-router';
import { Api, useApiQuery } from 'api';

const ClassSearch = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const schoolId = useSelector(s => s.school.schoolId);
  const demoGroups = useSelector(s => s.school?.demoGroups);

  const { loading, data } = useApiQuery(async () => {
    const groups = await Api.getAllGroupsInSchool(schoolId);
    // filter demo groups out of groups
    const demoGroupIds = demoGroups ? demoGroups.map(g => g.groupId) : [];
    return groups?.filter(g => !demoGroupIds.includes(g.groupId)) || [];
  });

  const handleAddClick = value => {
    if (data.find(i => i.code.toLowerCase() === value.toLowerCase())) {
      toaster.warning(
        `Group with name "${value}" already exists in the school! Enter a new unique group name.`
      );
      return;
    }

    dispatch(prepareToAdd(value));
    dispatch(changeField('code', value));
    navigate(`/${schoolId}/classes/new/new`);
  };

  const handleItemClick = group => {
    dispatch(prepareToJoin(group));
    navigate(`/${schoolId}/classes/new/join`);
  };

  const renderItem = group => {
    return (
      <div className="mb-2" key={group.groupId}>
        <SmallCard
          title={group.code}
          subtitle={group.departmentName}
          rightComponentOne={
            <Button
              onClick={() => handleItemClick(group)}
              color="primary"
              outline>
              Join
            </Button>
          }
          onClick={() => handleItemClick(group)}
          key={group.groupId}
        />
      </div>
    );
  };

  const foundCode = location.state?.text ?? '';
  const items = data?.schoolGroups ?? [];
  const className = !items.find(
    i => i.code.toLowerCase() === foundCode?.toLowerCase()
  )
    ? foundCode
    : '';

  return (
    <>
      <div className="text-center" style={{ marginBottom: '3rem' }}>
        <h1>Next, let's add your class</h1>
      </div>
      <SearchStep
        onAdd={handleAddClick}
        addText={className => (className ? `Add "${className}"` : 'Add')}
        searchBy={group => (group.code ? group.code.toLowerCase() : '')}
        searchInputText={className}
        searchText={'Search for a class'}
        loading={loading}
        itemTitle="class"
        items={data}
        renderItem={renderItem}
        hideAddCardIfNoSearchQuery
      />
    </>
  );
};

export default ClassSearch;
