import styled from 'styled-components';
import { Button, Card } from 'components/common';
import Navigation from 'components/Navigation';
import { useNavigate } from 'react-router';

const FullPageError = ({
  title = 'Something went wrong',
  description = 'Please refresh your browser and try again',
  isLogoutOption = false,
  error = null,
  signup = false,
  info = null
}) => {
  const navigate = useNavigate();

  const truncateString = (str, maxLength) => {
    if (!str || !maxLength) return null;
    if (str.length <= maxLength) {
      return str;
    }
    return str.slice(0, maxLength) + '...';
  };

  return (
    <Container>
      <Navigation />
      <Content>
        <Card>
          <Card.Body>
            <h2>{title}</h2>
            <p>{description}</p>
            {error?.toString() && (
              <ErrorDisplay>
                {error?.toString()}
                <ErrorDetails>
                  {truncateString(info?.componentStack, 255)}
                </ErrorDetails>
              </ErrorDisplay>
            )}{' '}
            {isLogoutOption && (
              <LogoutOption>
                If this issue persists try{' '}
                <LogoutLink onClick={() => navigate('/logout')}>
                  logging out
                </LogoutLink>{' '}
                and back in again
              </LogoutOption>
            )}
            {signup && (
              <Signup>
                <div>Don't have an account?</div>
                <div>
                  <Button to="/signup" color="primary" outline>
                    Sign up
                  </Button>
                </div>
              </Signup>
            )}
          </Card.Body>
        </Card>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 300;
  background-color: var(--tint-color);
`;

const Content = styled.div`
  margin: 0 auto;
  padding: 1.5rem;
  max-width: 30rem;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogoutOption = styled.div`
  margin-top: 1rem;
`;

const Signup = styled.div`
  display: flex;
  margin-top: 1rem;
  border-top: 1px solid var(--gray-lighter);
  padding-top: 1rem;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

const LogoutLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
  color: var(--indigo-dark);
`;

const ErrorDisplay = styled.div`
  padding: 0.5rem;
  border: 1px solid var(--pink-light);
  color: var(--red);
  margin-top: 0.25rem;
  border-radius: 0.25rem;
  background: var(--pink-lighter);
`;

const ErrorDetails = styled.p`
  font-size: 12px;
`;

export default FullPageError;
