import styled from 'styled-components';
import { UncontrolledTooltip } from 'reactstrap';

const Tooltip = props => (
  <StyledTooltip {...props}>{props.children}</StyledTooltip>
);

const StyledTooltip = styled(UncontrolledTooltip)`
  z-index: 999;
`;

export default Tooltip;
