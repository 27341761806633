import styled from 'styled-components';
import { Tooltip } from './';

const Info = ({ id, tooltip, placement = 'top', ...props }) => (
  <>
    <Badge id={id} {...props}>
      ?
    </Badge>
    <Tooltip placement={placement} target={id}>
      {tooltip}
    </Tooltip>
  </>
);

const Badge = styled.span`
  text-transform: none;
  border: 1.5px solid var(--gray);
  color: var(--gray);
  border-radius: 0.625rem;
  width: 1.1rem;
  height: 1.1rem;
  font-size: 0.85rem;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

export default Info;
