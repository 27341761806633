import * as React from 'react';
const SvgStudents = ({ size = '16', color, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M15.699 10.705a2.716 2.716 0 0 0 2.718-2.728 2.716 2.716 0 0 0-2.718-2.727 2.724 2.724 0 0 0-2.727 2.727 2.724 2.724 0 0 0 2.727 2.728Zm-7.273 0a2.716 2.716 0 0 0 2.718-2.728c0-1.509-1.209-2.727-2.718-2.727A2.724 2.724 0 0 0 5.7 7.977a2.724 2.724 0 0 0 2.727 2.728Zm0 1.818c-2.118 0-6.364 1.063-6.364 3.182v1.363c0 .5.41.91.91.91H13.88c.5 0 .909-.41.909-.91v-1.363c0-2.119-4.246-3.182-6.364-3.182Zm7.273 0c-.264 0-.564.018-.882.045.018.01.027.027.036.037 1.037.754 1.755 1.763 1.755 3.1v1.363c0 .318-.064.627-.164.91h4.71c.5 0 .909-.41.909-.91v-1.363c0-2.119-4.246-3.182-6.364-3.182Z"
      fill={color ?? 'currentColor'}
    />
  </svg>
);
export default SvgStudents;
