import styled from 'styled-components';

const Well = styled.div`
  background-color: var(--blue-lightest);
  padding: clamp(1.5rem, 5vw, 5rem);
  border-radius: 1rem;
  width: 100%;
`;

export default Well;
