import { consoleHelper } from 'helpers/consoleHelper';

const initialState = {
  context: '',
  contextSummary: '',
  piiProperties: [],
  promptSuggestions: [],
  dataPromptSuggestions: [],
  contextUrl: ''
};

const botContext = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_BOT_CONTEXT':
      consoleHelper.warn({ source: 'UPDATE_BOT_CONTEXT', action });
      return {
        ...state,
        ...action
      };
    default:
      return state;
  }
};

export default botContext;
