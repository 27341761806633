import BlueLoader from 'components/common/BlueLoader';
import styled from 'styled-components';

const LongLoading = ({
  loading = true,
  loadingText = 'Loading...',
  loader = <BlueLoader isLoading={true} />
}) => {
  if (!loading) return null;
  return (
    <LoadingWrapper>
      <h5>{loadingText}</h5>
      <div style={{ display: 'flex', justifyContent: 'center' }}>{loader}</div>
    </LoadingWrapper>
  );
};

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  flex-direction: column;
`;

export default LongLoading;
