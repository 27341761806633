import * as React from 'react';
const SvgUser = ({ size = '16', color, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M12 2.149a5.185 5.185 0 1 0 0 10.37 5.185 5.185 0 0 0 0-10.37Zm0 8.296a3.111 3.111 0 1 1 0-6.223 3.111 3.111 0 0 1 0 6.223Zm9.333 11.407v-1.038a7.259 7.259 0 0 0-7.259-7.258H9.926a7.259 7.259 0 0 0-7.259 7.258v1.038h2.074v-1.038a5.185 5.185 0 0 1 5.185-5.184h4.148a5.185 5.185 0 0 1 5.185 5.184v1.038h2.074Z"
      fill={color ?? 'currentColor'}
    />
  </svg>
);
export default SvgUser;
