import { useState, useEffect, useRef, useCallback } from 'react';

const useApiQuery = (query, deps = []) => {
  const queryRef = useRef(query);
  queryRef.current = query;

  // This has to be set to true initially, otherwise causes issues loading the data.
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [reloadFlag, setReloadFlag] = useState({});

  const reload = useCallback(() => setReloadFlag({}), []);

  useEffect(() => {
    let isMounted = true;
    async function fetchData() {
      try {
        setLoading(true);
        const data = await queryRef.current();
        if (isMounted) {
          setData(data);
        }
      } catch (e) {
        if (isMounted) {
          setError(e);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    }

    fetchData();
    return () => (isMounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadFlag, ...deps]);

  return {
    data,
    loading,
    error,
    reload
  };
};

export default useApiQuery;
