export const setSchools = schools => ({
  type: 'SET_SCHOOLS',
  schools
});

export const resetSchool = () => ({
  type: 'RESET_SCHOOL'
});

export const switchSchool = schoolId => ({
  type: 'SWITCH_SCHOOL',
  schoolId
});

export const updateSchool = school => ({
  type: 'UPDATE_SCHOOL',
  school
});

export const setGroups = (groups = []) => ({
  type: 'SET_GROUPS',
  groups
});

export const setDemoGroups = (demoGroups = []) => ({
  type: 'SET_DEMO_GROUPS',
  demoGroups
});
