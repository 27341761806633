const initialState = {
  toast: null
};

const toasts = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_TOAST':
      return {
        ...state,
        toast: action.payload
      };
    case 'HIDE_TOAST':
      return {
        ...state,
        toast: null
      };
    default:
      return state;
  }
};

export default toasts;
