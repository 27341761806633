const initialState = {
  userSchools: [],
  currentSchool: null,
  schoolId: null,
  teacherId: null,
  groups: null,
  demoGroups: null
};

const schools = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SCHOOLS':
      return {
        ...state,
        userSchools: action.schools
      };

    case 'RESET_SCHOOL':
      return initialState;

    case 'SWITCH_SCHOOL':
      // find this school in schools
      const school = state.userSchools.find(
        s => parseInt(s.schoolId, 10) === parseInt(action.schoolId, 10)
      );

      return {
        ...state,
        schoolId: action.schoolId ?? state.schoolId,
        teacherId: school?.teacher?.teacherId ?? state.teacherId,
        currentSchool: school ?? state.currentSchool
      };

    case 'SET_GROUPS':
      return {
        ...state,
        groups: action.groups
      };

    case 'SET_DEMO_GROUPS':
      return {
        ...state,
        demoGroups: action.demoGroups
      };

    case 'LOGOUT':
      return initialState;

    case 'UPDATE_SCHOOL':
      return {
        ...state,
        currentSchool: { ...state.currentSchool, ...action.school }
      };

    default:
      return state;
  }
};

export default schools;
