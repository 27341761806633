import styled from 'styled-components';
import CampfirePoints1 from 'assets/images/campfire-points.svg';
import { Tooltip } from 'components/common';

const StreakPointsPill = ({ key, id, points, tooltip, ...props }) => {
  return (
    <div>
      <PointsContainer id={id ?? `streak-${points}-${key}`} {...props}>
        <img src={CampfirePoints1} alt="fire" />
        <div>{points ?? 0}</div>
      </PointsContainer>
      <Tooltip target={id ?? `streak-${points}-${key}`}>
        {tooltip ??
          (!!points ? (
            <>{points} Streak points aquired through topics and lessons</>
          ) : (
            <>
              Student has not earned any streak points through topics or lessons
            </>
          ))}
      </Tooltip>
    </div>
  );
};

const PointsContainer = styled.div`
  cursor: pointer;
  display: flex;
  background: var(--gray-lightest);
  border-radius: 3rem;
  padding: 0.5rem;
  z-index: 1;
  align-items: center;
  height: 2.5rem;
  min-width: 4.5rem;
  align-content: center;
  justify-content: space-between;
  flex-direction: row;
  font-weight: 500;
  margin: 0 0.5rem 0;
  img {
    top: -2px;
    position: relative;
    height: 100%;
    min-height: 2rem;
    user-select: none;
  }
`;

export default StreakPointsPill;
