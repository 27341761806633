const initialState = {};

const group = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_GROUP':
      if (!action.group) {
        return initialState;
      }

      return {
        ...state,
        ...action.group
      };

    case 'SWITCH_SCHOOL': {
      return initialState;
    }

    case 'LOGOUT': {
      return initialState;
    }

    default:
      return state;
  }
};

export default group;
