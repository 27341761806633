const CrossInCircleIcon = ({ color, width = '1.5rem', height = '1.5rem' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ width, height }} fill="none">
    <path
      fill={`${color || 'var(--gray-light)'}`}
      fillRule="evenodd"
      d="M3 12a9 9 0 1118 0 9 9 0 01-18 0zm9-11C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1zM7.293 16.707a1 1 0 010-1.414L10.586 12 7.293 8.707a1 1 0 011.414-1.414L12 10.586l3.293-3.293a1 1 0 111.414 1.414L13.414 12l3.293 3.293a1 1 0 01-1.414 1.414L12 13.414l-3.293 3.293a1 1 0 01-1.414 0z"
      clipRule="evenodd"></path>
  </svg>
);

export default CrossInCircleIcon;
