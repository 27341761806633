import styled from 'styled-components';

const SwitchButton = styled.button`
  display: flex;
  position: relative;
  align-items: center;
  height: 2rem;
  width: 4rem;
  border-radius: 1.5rem;
  background-color: ${({ on }) => (on ? 'var(--green)' : 'var(--gray)')};
  outline: none;
  border: none;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  -webkit-transition: background-color 2.5s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: background-color 400ms cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: background-color 400ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: background-color 400ms cubic-bezier(0.23, 1, 0.32, 1);

  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
`;

const Toggle = styled.div`
  display: flex;
  position: absolute;
  background-color: var(--white);
  width: 1rem;
  height: 1rem;
  top: 0;
  bottom: 0;
  margin: auto;
  left: ${({ on }) => (on ? '2.5rem' : '0.50rem')};
  border-radius: 50%;

  -webkit-transition: all 2.5s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1);
`;

const Switch = ({ onClick, ...props }) => (
  <SwitchButton {...props} onClick={onClick}>
    <Toggle {...props} />
  </SwitchButton>
);

export default Switch;
