import Navigation from 'components/Navigation';
import { Button, Container } from 'components/common';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router';
import styled from 'styled-components';

const Schools = () => {
  const existingSchool = useSelector(s => s.school.userSchools);
  return (
    <>
      <Navigation
        top
        rightComponent={
          existingSchool && existingSchool.length ? (
            <Button to="/" color="link">
              Exit
            </Button>
          ) : (
            <Button to="/logout" color="link">
              Logout
            </Button>
          )
        }
      />
      <Body>
        <Container className="container-xs">
          <Outlet />
        </Container>
      </Body>
    </>
  );
};

const Body = styled.div`
  min-height: 100vh;
  padding-top: 9rem;
`;

export default Schools;
