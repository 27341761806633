import styled from 'styled-components';
import { lighten } from 'polished';

import { colors } from 'styles';
import FontSizes from './FontSizes';

const renderIcon = name => {
  switch (name) {
    case 'eedi':
      return (
        <svg
          width="80px"
          height="80px"
          viewBox="0 0 40 40"
          version="1.1"
          xmlns="//www.w3.org/2000/svg">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect fill="#FFD9E3" x="0" y="0" width="40" height="40" rx="20" />
              <polygon fill="#675AEA" points="22 22 22 10 26 10 26 22" />
              <polygon
                fill="#675AEA"
                points="14 22 14 10 18.0000413 10 18.0000413 22"
              />
              <polygon
                fill="#FF7256"
                points="30 30 10 30 10 25.999169 30 25.999169"
              />
            </g>
          </g>
        </svg>
      );

    case 'class':
      return (
        <svg
          width="80px"
          height="80px"
          viewBox="0 0 40 40"
          version="1.1"
          xmlns="//www.w3.org/2000/svg">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fill="#FFFFFF"
                opacity="0"
                x="0"
                y="0"
                width="40"
                height="40"
              />
              <rect fill="#FFD9E3" x="0" y="0" width="40" height="40" rx="20" />
              <rect fill="#675AEA" x="10" y="13" width="20" height="14" />
              <path
                d="M10,13 L10,27 L30,27 L30,13 L10,13 Z M10,11 L30,11 C31.1045695,11 32,11.8954305 32,13 L32,27 C32,28.1045695 31.1045695,29 30,29 L10,29 C8.8954305,29 8,28.1045695 8,27 L8,13 C8,11.8954305 8.8954305,11 10,11 Z"
                fill="#FFFFFF"
                fillRule="nonzero"
              />
              <path
                d="M24,24 L27,24"
                stroke="#FFFFFF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </g>
        </svg>
      );

    case 'attention':
      return (
        <svg
          width="80px"
          height="80px"
          viewBox="0 0 40 40"
          version="1.1"
          xmlns="//www.w3.org/2000/svg">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fill="#FFFFFF"
                opacity="0"
                x="0"
                y="0"
                width="40"
                height="40"
              />
              <rect fill="#FFE15D" x="0" y="0" width="40" height="40" rx="20" />
              <path
                d="M21.32,13 L18.28,13 L18.56,22.36 L21.04,22.36 L21.32,13 Z M18.08,25.48 C18.08,26.44 18.78,27.2 19.8,27.2 C20.82,27.2 21.52,26.44 21.52,25.48 C21.52,24.46 20.82,23.78 19.8,23.78 C18.78,23.78 18.08,24.46 18.08,25.48 Z"
                fill="#FFFFFF"
              />
            </g>
          </g>
        </svg>
      );

    case 'report':
      return (
        <svg
          width="80px"
          height="80px"
          viewBox="0 0 40 40"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg">
          <defs>
            <rect id="path-1" x="0" y="0" width="40" height="40" rx="20" />
            <rect id="path-3" x="8" y="14" width="24" height="33" />
            <filter
              x="-25.0%"
              y="-18.2%"
              width="150.0%"
              height="136.4%"
              filterUnits="objectBoundingBox"
              id="filter-4">
              <feOffset
                dx="0"
                dy="0"
                in="SourceAlpha"
                result="shadowOffsetOuter1"
              />
              <feGaussianBlur
                stdDeviation="2"
                in="shadowOffsetOuter1"
                result="shadowBlurOuter1"
              />
              <feColorMatrix
                values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.24 0"
                type="matrix"
                in="shadowBlurOuter1"
              />
            </filter>
          </defs>
          <g
            id="Icons"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd">
            <g id="Avatar---report-neutral">
              <rect fill="#F4F4F4" x="0" y="0" width="40" height="40" rx="20" />
              <rect
                id="Area"
                fill="#FFFFFF"
                opacity="0"
                x="0"
                y="0"
                width="40"
                height="40"
              />
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1" />
              </mask>
              <g id="Circle" />
              <g id="paper" mask="url(#mask-2)">
                <use
                  fill="black"
                  fillOpacity="1"
                  filter="url(#filter-4)"
                  xlinkHref="#path-3"
                />
                <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-3" />
              </g>
              <rect
                id="note-line-1"
                fill="#777677"
                mask="url(#mask-2)"
                x="12"
                y="20"
                width="16"
                height="2"
              />
              <rect
                id="note-line-2"
                fill="#777677"
                mask="url(#mask-2)"
                x="12"
                y="24"
                width="16"
                height="2"
              />
              <rect
                id="note-line-2-copy"
                fill="#777677"
                mask="url(#mask-2)"
                x="12"
                y="28"
                width="16"
                height="2"
              />
              <rect
                id="note-line-2-copy-2"
                fill="#777677"
                mask="url(#mask-2)"
                x="12"
                y="32"
                width="8"
                height="2"
              />
            </g>
          </g>
        </svg>
      );

    case 'mobile':
      return (
        <svg
          width="80px"
          height="80px"
          viewBox="0 0 40 40"
          version="1.1"
          xmlns="//www.w3.org/2000/svg">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fill="#FFFFFF"
                opacity="0"
                x="0"
                y="0"
                width="40"
                height="40"
              />
              <rect fill="#FFD9E3" x="0" y="0" width="40" height="40" rx="20" />
              <path
                d="M31,36.7066607 L31,13 C31,11.8954305 30.1045695,11 29,11 L11,11 C9.8954305,11 9,11.8954305 9,13 L9,36.7065775 C12.1557922,38.788406 15.9363662,40 20,40 C24.0640674,40 27.844482,38.7883515 31,36.7066607 L31,36.7066607 Z"
                fill="#FFFFFF"
              />
              <path
                d="M29,37.8659558 L29,16 L11,16 L11,37.8658596 C13.7051891,39.2310938 16.7627764,40 20,40 C23.2375097,40 26.2950131,39.2310874 29,37.8659558 L29,37.8659558 Z"
                fill="#675AEA"
              />
              <polygon fill="#FF7256" points="17 30 17 24 19 24 19 30" />
              <polygon fill="#FF7256" points="21 30 21 24 23 24 23 30" />
              <polygon fill="#FFD955" points="25 34 15 34 15 32 25 32" />
            </g>
          </g>
        </svg>
      );

    default:
      return null;
  }
};

const Avatar = props => (
  <StyledAvatar {...props}>
    {!props.imageUrl && props.initials && (
      <Text {...props}>{props.initials && props.initials.slice(0, 2)}</Text>
    )}
    {!props.imageUrl && !props.initials && props.name && renderIcon(props.name)}
  </StyledAvatar>
);

const StyledAvatar = styled.div`
  display: flex;
  flex-shrink: 0;
  padding: 0.25rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--white);
  background: ${({ color, imageUrl, lightenColor }) =>
    lightenColor
      ? `${lighten(0.08, `${colors[color]}`)} url(${imageUrl})`
      : `${colors[color]} url(${imageUrl})`};
  background-size: cover;
  background-position: center;
  width: ${({ small }) => (small ? 3.5 : 6)}rem;
  height: ${({ small }) => (small ? 3.5 : 6)}rem;
  border-radius: 50%;
  border: ${({ status }) => {
    if (status === 'online') {
      return `0.25rem solid var(--green)`;
    } else if (status === 'pending') {
      return `0.25rem solid var(--primary)`;
    } else if (status === 'offline') {
      return `0.25rem solid var(--gray-lighter)`;
    }
    return `0.25rem solid transparent`;
  }};
  background-clip: content-box;
`;

// TODO: remove padding-top once the font is fixed,
// currently there's some padding in the bottom of the font by default
const Text = styled.span`
  padding-top: ${({ small }) => (small ? '1px' : '3px')};
  font-size: ${({ small }) =>
    small ? FontSizes[7].desktop.fontSize : FontSizes[5].desktop.fontSize}rem;
  color: var(--white);
`;

Avatar.defaultProps = {
  small: false,
  color: 'primary',
  imageUrl: '',
  initials: '',
  status: 'none'
};

export default Avatar;
