import { Badge as ReactstrapBadge } from 'reactstrap';
import styled from 'styled-components';

const StyledBadge = styled(ReactstrapBadge)`
  padding: 0.25rem 0.375rem;
  font-size: 0.85rem;
  font-weight: 400;

  ${({ color }) =>
    color &&
    `
  background-color: var(--${color});
`}
  color: ${({ textcolor }) =>
    textcolor ? `var(--${textcolor})` : 'var(--white)'};
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};

  ${({ rounded }) =>
    rounded &&
    `
  border-radius: 99rem;
  `}
`;

const Badge = ({ color = 'secondary', ...props }) => (
  <StyledBadge color={color} {...props}></StyledBadge>
);
export default Badge;
