import { Button, Modal } from 'components/common';
import { useState } from 'react';

const ConfirmModal = ({
  title,
  message,
  onConfirm,
  onCancel = () => {},
  confirmButtonText,
  cancelButtonText,
  children,
  isOpen = true,
  loading = false
}) => {
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const confirmOnce = async () => {
    if (wasSubmitted) {
      return;
    }
    setWasSubmitted(true);
    await onConfirm();
    setWasSubmitted(false);
  };

  return (
    <Modal isOpen={isOpen} toggle={onCancel}>
      <Modal.Header toggle={onCancel}>{title}</Modal.Header>
      <Modal.Body>
        {message}
        {children}
      </Modal.Body>

      <Modal.Footer>
        <Button color="link" className="text-dark" onClick={onCancel}>
          {cancelButtonText ?? 'No'}
        </Button>{' '}
        <Button
          type="submit"
          color="danger"
          loading={loading}
          onClick={confirmOnce}
          disabled={wasSubmitted}>
          {confirmButtonText ?? 'Yes'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
