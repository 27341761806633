import { useSearchParams } from 'react-router-dom';

const useQueryParams = () => {
  const [searchParams] = useSearchParams();

  const obj = {};

  for (const [key, value] of searchParams) {
    if (obj[key]) continue;

    obj[key] = value;
  }

  return obj;
};

export default useQueryParams;
