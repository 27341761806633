import styled from 'styled-components';

const ChevronLeft = ({ size = 70, color = 'white' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M41.6505 52.3495L23.9728 34.6719L41.6505 16.9942"
      stroke={color}
      strokeWidth="5"
    />
    <rect
      x="-2.5"
      y="2.5"
      width="65"
      height="65"
      rx="32.5"
      transform="matrix(-1 0 0 1 65 0)"
      stroke={color}
      strokeWidth="5"
    />
  </svg>
);

const ChevronRight = ({ size = 70, color = 'white' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.8357 52.5115L46.5134 34.8339L28.6777 16.9981"
      stroke={color}
      strokeWidth="5"
    />
    <rect
      x="2.5"
      y="2.5"
      width="65"
      height="65"
      rx="32.5"
      stroke={color}
      strokeWidth="5"
    />
  </svg>
);

const PresentNavigationButton = ({
  disabled,
  direction,
  onClick,
  ...props
}) => {
  const icon =
    direction === 'prev'
      ? ChevronLeft
      : direction === 'next'
      ? ChevronRight
      : null;

  return (
    <StyledWrapper
      {...props}
      onClick={() => {
        if (!disabled) onClick();
      }}>
      {icon &&
        icon({ size: 50, color: disabled ? 'var(--gray)' : 'var(--white)' })}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  cursor: pointer;
`;

export default PresentNavigationButton;
