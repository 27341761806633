import * as React from 'react';
const SvgQuizzes = ({ size = '16', color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    {...props}>
    <path
      fill={color ?? 'currentColor'}
      d="M13.125 11.82h1.35c0-.435.045-.754.135-.957.09-.202.3-.468.63-.798.45-.45.75-.814.9-1.092.15-.277.225-.603.225-.978 0-.675-.236-1.226-.71-1.653-.471-.428-1.09-.642-1.855-.642-.615 0-1.151.172-1.608.518a2.683 2.683 0 0 0-.957 1.372l1.215.495c.135-.375.319-.656.552-.844.232-.187.498-.281.798-.281.36 0 .652.101.877.303.226.203.338.477.338.822 0 .21-.06.409-.18.596-.12.188-.33.424-.63.709-.495.435-.799.776-.91 1.023-.114.248-.17.717-.17 1.407Zm.675 2.88a.91.91 0 0 0 .664-.28.91.91 0 0 0 .281-.665.91.91 0 0 0-.28-.664.91.91 0 0 0-.665-.281.907.907 0 0 0-.663.28.909.909 0 0 0-.282.665c0 .255.094.476.282.664.187.187.408.281.663.281Zm-7.2 2.7V3H21v14.4H6.6Zm1.8-1.8h10.8V4.8H8.4v10.8ZM3 21V6.6h1.8v12.6h12.6V21H3Z"
    />
  </svg>
);
export default SvgQuizzes;
