const ChatIcon = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#8D34FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8731 9.63135H26.3866C28.1778 9.63135 29.6299 11.0834 29.6299 12.8746V26.3881C29.6299 28.1793 28.1778 29.6313 26.3866 29.6313H12.8731C11.0819 29.6313 9.62988 28.1793 9.62988 26.3881V12.8746C9.62988 11.0834 11.0819 9.63135 12.8731 9.63135ZM12.8731 11.253C11.9775 11.253 11.2515 11.979 11.2515 12.8746V26.3881C11.2515 27.2837 11.9775 28.0097 12.8731 28.0097H26.3866C27.2822 28.0097 28.0083 27.2837 28.0083 26.3881V12.8746C28.0083 11.979 27.2822 11.253 26.3866 11.253H12.8731Z"
        fill="white"
      />
      <g clip-path="url(#clip0_12625_79339)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.5659 13.9567L16.4375 13.9567L16.4375 20.3418L18.5659 20.3418L18.5659 13.9567ZM22.8226 13.9567L20.6943 13.9567L20.6943 20.3418L22.8226 20.3418L22.8226 13.9567Z"
          fill="white"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7236 24.7592C13.7234 24.7591 13.7232 24.7591 14.0745 23.705C14.4259 22.6509 14.4257 22.6508 14.4254 22.6507L14.4365 22.6543C14.4484 22.658 14.4686 22.6644 14.4968 22.6729C14.5533 22.6899 14.6418 22.7156 14.7601 22.7471C14.9969 22.8103 15.3526 22.8967 15.8102 22.9838C16.7262 23.1583 18.0447 23.3346 19.6301 23.3346C21.2154 23.3346 22.5339 23.1583 23.45 22.9838C23.9075 22.8967 24.2633 22.8103 24.5 22.7471C24.6184 22.7156 24.7069 22.6899 24.7633 22.6729C24.7915 22.6644 24.8117 22.658 24.8237 22.6543L24.8351 22.6506C24.8349 22.6507 24.8343 22.6509 25.1856 23.705C25.537 24.7591 25.5368 24.7591 25.5365 24.7592L25.5347 24.7598L25.5316 24.7608L25.523 24.7637L25.4961 24.7723C25.4738 24.7794 25.443 24.789 25.404 24.8007C25.3259 24.8242 25.2147 24.8564 25.0726 24.8943C24.7886 24.97 24.3804 25.0688 23.8658 25.1668C22.8373 25.3627 21.378 25.5568 19.6301 25.5568C17.8821 25.5568 16.4228 25.3627 15.3944 25.1668C14.8797 25.0688 14.4716 24.97 14.1875 24.8943C14.0455 24.8564 13.9343 24.8242 13.8562 24.8007C13.8171 24.789 13.7864 24.7794 13.7641 24.7723L13.7371 24.7637L13.7285 24.7608L13.7254 24.7598L13.7236 24.7592Z"
        fill="white"
      />
      <defs>
        <clipPath id="clip0_12625_79339">
          <rect
            width="11.3514"
            height="11.3514"
            fill="white"
            transform="translate(13.9546 25.3081) rotate(-90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChatIcon;
