import jwt_decode from 'jwt-decode';

export const getUserRole = token => {
  if (token === null) return null;

  try {
    const claims = jwt_decode(token);

    if (typeof claims !== 'object') throw new Error('Invalid token claims');

    return (
      Object.keys(claims)
        .map(key => {
          if (key.toLowerCase().includes('role')) {
            return claims[key];
          }
          return null;
        })
        .find(role => role !== null) || null
    );
  } catch (e) {
    console.error('Unable to parse token!', e);
  }

  return null;
};
