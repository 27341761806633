import * as React from 'react';

const SvgPrepare = ({ size = '16', color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    {...props}>
    <path
      fill={color ?? 'currentColor'}
      d="M19.694 9.467 6.116 22.147l5.975-9.424-7.335-.194L16.512.99l-4.178 8.33 7.36.146Z"
    />
  </svg>
);
export default SvgPrepare;
