import styled from 'styled-components';

import Icon from './Icon';
import TextS from './TextS';

const Pill = ({ iconName, label, onClick, ...props }) => (
  <Container onClick={onClick} iconName={iconName} {...props}>
    <Icon name={iconName} />
    <LabelContainer iconName={iconName}>
      <TextS color="gray">{label}</TextS>
    </LabelContainer>
  </Container>
);

const Container = styled.div`
  display: flex;
  align-items: center;
  border-radius: 2rem;
  padding: ${({ iconName }) =>
    iconName ? '0rem 0.875rem 0rem 0.5rem' : '0rem 0.875rem 0rem 0.875rem'};
  border: 2px solid
    ${({ selected, dark }) => {
      if (selected) {
        return 'var(--gray)';
      } else if (!selected && dark) {
        return 'var(--gray-lighter)';
      }
      return 'var(--gray-lighter)';
    }};
  height: 2.5rem;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};

  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
`;

const LabelContainer = styled.div`
  white-space: nowrap;
  padding: ${({ iconName }) => (iconName ? '0rem 0rem 0rem 0.5rem' : 0)};

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    padding: ${({ iconName }) => (iconName ? '0rem 1.5rem 0rem 0.5rem' : 0)};
  }
  ${
    '' /* TODO: remove the top padding once the font is fixed */
  } padding-top: 0.125rem;
`;

export default Pill;
