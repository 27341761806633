const initialState = {
  answersFilter: [],
  selectedStudents: [],
  assignmentsSortBy: null,
  questionsSortBy: {
    param: 'lastName',
    order: 'desc'
  }
};

const filters = (state = initialState, action) => {
  const { answersFilter, selectedStudents } = state;

  switch (action.type) {
    case 'SET_ANSWERS_FILTER':
      if (answersFilter.includes(action.payload)) {
        return {
          ...state,
          answersFilter: answersFilter.filter(a => a !== action.payload)
        };
      }
      return { ...state, answersFilter: [...answersFilter, action.payload] };
    case 'SET_ASSIGNMENTS_SORT':
      return {
        ...state,
        assignmentsSortBy: action.payload
      };
    case 'SET_QUESTIONS_SORT':
      const order =
        state.questionsSortBy.param === action.payload &&
        state.questionsSortBy.order === 'desc'
          ? 'asc'
          : 'desc';
      return {
        ...state,
        questionsSortBy: {
          param: action.payload,
          order
        }
      };
    case 'TOGGLE_SELECT_STUDENT':
      if (selectedStudents.includes(action.payload)) {
        return {
          ...state,
          selectedStudents: selectedStudents.filter(a => a !== action.payload)
        };
      }
      return {
        ...state,
        selectedStudents: [...selectedStudents, action.payload]
      };
    case 'SELECT_ALL_STUDENTS':
      return {
        ...state,
        selectedStudents: action.payload
      };
    case 'DESELECT_ALL_STUDENTS':
      return {
        ...state,
        selectedStudents: []
      };
    default:
      return state;
  }
};

export default filters;
