export const setJoinStatus = (status, error) => ({
  type: 'SET_JOIN_STATUS',
  status,
  error
});

export const changeField = (name, value) => ({
  type: 'CHANGE_JOIN_SCHOOL_FIELD',
  name,
  value
});

export const prepareToJoin = fields => ({
  type: 'PREPARE_TO_JOIN',
  fields
});

export const prepareToAdd = name => ({
  type: 'PREPARE_TO_ADD',
  name
});

export const clearFields = () => ({
  type: 'JOIN_STEPPER_SCHOOL_CLEAR'
});
