import * as React from 'react';
const SvgHome = ({ size = '16', color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    {...props}>
    <path fill={color ?? 'currentColor'} d="M4 21V9l8-6 8 6v12h-6v-7h-4v7H4Z" />
  </svg>
);
export default SvgHome;
