import * as React from 'react';
const SvgDashboard = ({ size = '16', color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    {...props}>
    <path
      fill={color ?? 'currentColor'}
      d="M12 0c6.628 0 12 5.372 12 12s-5.372 12-12 12S0 18.628 0 12 5.372 0 12 0Zm0 2.4a9.6 9.6 0 1 0 0 19.2 9.6 9.6 0 0 0 0-19.2Zm4.6 4.004a.714.714 0 0 1 1.12.523.708.708 0 0 1-.13.47c-2.615 3.655-4.055 5.613-4.317 5.876a1.802 1.802 0 0 1-2.546 0 1.802 1.802 0 0 1 0-2.546c.449-.448 2.406-1.889 5.873-4.323Zm2 4.396a1.2 1.2 0 1 1 0 2.4 1.2 1.2 0 0 1 0-2.4Zm-13.2 0a1.2 1.2 0 1 1 0 2.4 1.2 1.2 0 0 1 0-2.4Zm2.782-4.315a1.2 1.2 0 1 1-1.7 1.697 1.2 1.2 0 0 1 1.7-1.697ZM12 4.2a1.2 1.2 0 1 1 0 2.4 1.2 1.2 0 0 1 0-2.4Z"
    />
  </svg>
);
export default SvgDashboard;
