import tracking from 'analytics/tracking';
import { Button } from 'components/common';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

const SetWorkButton = ({
  location = null,
  tag = null,
  preferredDate = null,
  shortCode = null,
  groupId = null,
  children,
  ...props
}) => {
  const schoolId = useSelector(s => s.school.schoolId);
  const l = useLocation();

  const buttonText = 'Set work';
  const Component = tag ?? Button;

  const handleClick = async () => {
    tracking.track(tracking.eventNames.SetWorkClicked, {
      location
    });

    localStorage.setItem('returnUri', `${l.pathname}${l.search}`);
  };

  return (
    <Component
      to={`/${schoolId}/quizzes${
        shortCode?.length ? `/${shortCode}` : ''
      }?groupId=${groupId}&date=${preferredDate}&returnUri=${l.pathname}${
        l.search
      }`}
      onClick={handleClick}
      {...props}>
      {children ?? buttonText}
    </Component>
  );
};

export default SetWorkButton;
