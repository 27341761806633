import React, { Component } from 'react';
import Popover from './Popover';
import DropdownList from './DropdownList';

class Dropdown extends Component {
  constructor(props) {
    super(props);
    let selectedId = props.selectedId;
    if (props.autoSelect && !selectedId && props.options.length > 0) {
      selectedId = props.options[0].id;
    }
    this.state = {
      selectedId,
      isOpen: false
    };
  }

  componentDidMount() {
    if (this.props.selectedId !== this.state.selectedId) {
      this.props.onSelect(
        this.props.options.find(o => o.id === this.state.selectedId)
      );
    }
  }

  open = () => this.setState({ isOpen: true });

  handleSelect = id => {
    const { options, onSelect } = this.props;

    this.setState({
      selectedId: id,
      isOpen: false
    });

    const option = options.find(o => o.id === id);
    onSelect(option);
  };

  render() {
    // TODO: at the moment the Popover component errors out
    // when the selected option is being changed. This doesn't break the app though
    // (https://github.com/littlebits/react-popover/issues/53)
    const { selectedId, isOpen } = this.state;
    const { options, excludeSelectedOption, children } = this.props;

    return (
      <Popover
        isOpen={isOpen}
        overlayComponent={
          <DropdownList
            handleSelect={this.handleSelect}
            options={options}
            selectedId={selectedId}
            excludeSelectedOption={excludeSelectedOption}
          />
        }>
        {children}
      </Popover>
    );
  }
}

export default Dropdown;
