import * as React from 'react';

const SvgChevronRight = ({ size = '24', color = '#050038', ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      d="m10.713 5.626 5.667 5.668a1 1 0 0 1 0 1.415l-5.667 5.67A1 1 0 1 1 9.3 16.962l4.96-4.961-4.96-4.962a1 1 0 0 1 1.414-1.414"
      fill={color}
    />
  </svg>
);

export default SvgChevronRight;
