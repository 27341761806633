const ThinChevron = ({
  color = '#010002',
  size = '30px',
  onClick = () => console.warn('chevron has no click event!'),
  direction = 'right',
  ...props
}) => {
  return (
    <div {...props} onClick={onClick} id="thin-chevron">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 185.343 185.343"
        style={{
          height: size,
          minHeight: size,
          cursor: 'pointer',
          display: 'block',
          transform: direction === 'left' ? 'scale(-1,1)' : null
        }}>
        <g>
          <g>
            <path
              style={{ fill: color }}
              d="M51.707,185.343c-2.741,0-5.493-1.044-7.593-3.149c-4.194-4.194-4.194-10.981,0-15.175
              l74.352-74.347L44.114,18.32c-4.194-4.194-4.194-10.987,0-15.175c4.194-4.194,10.987-4.194,15.18,0l81.934,81.934
              c4.194,4.194,4.194,10.987,0,15.175l-81.934,81.939C57.201,184.293,54.454,185.343,51.707,185.343z"
            />
          </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    </div>
  );
};

export default ThinChevron;
