import Checkbox from '../Checkbox';
import SortIndicator from './SortIndicator';

const Header = ({
  headerGroups,
  enableBulkSelection,
  isAllSelected,
  setAllSelectedState
}) => {
  return (
    <thead>
      <>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {enableBulkSelection && (
              <th>
                <Checkbox
                  id="data-table-select-all"
                  checked={isAllSelected}
                  onChange={e => setAllSelectedState(e.target.checked)}
                />
              </th>
            )}
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>
                {!column.disableSortby ? (
                  <span className="sortable" {...column.getSortByToggleProps()}>
                    {column.render('Header')}
                    {column.isSorted && (
                      <SortIndicator isSortedDesc={column.isSortedDesc} />
                    )}
                  </span>
                ) : (
                  column.render('Header')
                )}
              </th>
            ))}
          </tr>
        ))}
      </>
    </thead>
  );
};

export default Header;
