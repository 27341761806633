const SvgSpanner = ({ size = '16', color, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.5618 4.67458C24.5944 3.29062 20.9541 3.82334 18.5047 6.27274C15.3745 9.40294 15.3745 14.478 18.5047 17.6082C21.6349 20.7384 26.71 20.7384 29.8402 17.6082C32.2897 15.1586 32.8224 11.518 31.438 8.55046L25.5616 14.4268L21.6856 10.5508L27.5618 4.67458Z"
      fill={color ?? 'currentColor'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.1116 17.9738L18.1387 13.0009L3.36604 27.7735C1.9928 29.1467 1.9928 31.3732 3.36604 32.7465C4.73929 34.1197 6.96576 34.1197 8.33901 32.7465L23.1116 17.9738ZM4.53631 31.5764C5.04119 32.0813 5.85974 32.0813 6.36461 31.5764C6.86949 31.0716 6.86949 30.253 6.36461 29.7481C5.85974 29.2433 5.04119 29.2433 4.53631 29.7481C4.03144 30.253 4.03144 31.0716 4.53631 31.5764Z"
      fill={color ?? 'currentColor'}
    />
  </svg>
);

export default SvgSpanner;
