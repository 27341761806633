const FontSizes = {
  1: {
    desktop: { lineHeight: 9, fontSize: 8 },
    tablet: { lineHeight: 7, fontSize: 6 },
    mobile: { lineHeight: 3.5, fontSize: 3 }
  },
  2: {
    desktop: { lineHeight: 5, fontSize: 4 },
    tablet: { lineHeight: 3.5, fontSize: 3 },
    mobile: { lineHeight: 2.5, fontSize: 2 }
  },
  3: {
    desktop: { lineHeight: 4, fontSize: 3 },
    tablet: { lineHeight: 3.25, fontSize: 2.5 },
    mobile: { lineHeight: 2.25, fontSize: 1.5 }
  },
  4: {
    desktop: { lineHeight: 2.75, fontSize: 2 },
    tablet: { lineHeight: 2, fontSize: 1.5 },
    mobile: { lineHeight: 2, fontSize: 1.25 }
  },
  5: {
    desktop: { lineHeight: 2, fontSize: 1.5 },
    tablet: { lineHeight: 1.75, fontSize: 1.25 },
    mobile: { lineHeight: 1.75, fontSize: 1.125 }
  },
  6: {
    desktop: { lineHeight: 2, fontSize: 1.25 },
    tablet: { lineHeight: 1.5, fontSize: 1 },
    mobile: { lineHeight: 1.25, fontSize: 0.875 }
  },
  7: {
    desktop: { lineHeight: 1.5, fontSize: 1 },
    tablet: { lineHeight: 1.5, fontSize: 1 },
    mobile: { lineHeight: 1.25, fontSize: 0.875 }
  },
  8: {
    desktop: { lineHeight: 1.5, fontSize: 0.875 },
    tablet: { lineHeight: 1.5, fontSize: 0.875 },
    mobile: { lineHeight: 1.25, fontSize: 0.75 }
  }
};

export default FontSizes;
