import styled from 'styled-components';

import { colors } from 'styles';

const SideIndicator = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0.5rem;
  background-color: ${({ color }) => colors[color]};
  border-radius: 0.125rem 0rem 0rem 0.125rem;
  z-index: 1;
`;

SideIndicator.displayName = 'SideIndicator';

export default SideIndicator;
