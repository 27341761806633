import styled from 'styled-components';

const Chevron = ({ size = '16', color, angle = 0, ...props }) => (
  <StyledChevron
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color ?? 'currentColor'}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-chevron-right"
    {...props}>
    <polyline transform={`rotate(${angle} 12 12)`} points="9 18 15 12 9 6" />
  </StyledChevron>
);

const StyledChevron = styled.svg`
  cursor: ${props => props.cursor};
  color: ${props => props.fill};
`;

export default Chevron;
