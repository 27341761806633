import styled from 'styled-components';

const Loading = ({ ...props }) => {
  const icon = (
    <Svg viewBox="0 0 66 66" {...props}>
      <Circle
        fill="none"
        strokeWidth="6"
        strokeLinecap="round"
        cx="33"
        cy="33"
        r="30"
        {...props}
      />
    </Svg>
  );

  if (props.noContainer) {
    return icon;
  }

  return <LoadingContainer {...props}>{icon}</LoadingContainer>;
};

const LoadingContainer = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const Svg = styled.svg`
  width: ${props => (props.size === 'small' ? '1rem' : '3.5rem')};
  height: ${props => (props.size === 'small' ? '1rem' : '3.5rem')};
  animation: rotator 1.4s linear infinite;
  @keyframes rotator {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(270deg);
    }
  }
`;

const Circle = styled.circle`
  stroke-dasharray: 187px;
  stroke-dashoffset: 0px;
  transform-origin: center;
  animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;

  @keyframes dash {
    0% {
      stroke-dashoffset: 187px;
    }
    50% {
      stroke-dashoffset: 46.75px;
      transform: rotate(135deg);
    }
    100% {
      stroke-dashoffset: 187px;
      transform: rotate(450deg);
    }
  }

  @keyframes colors {
    0% {
      stroke: ${props => (props.color ? props.color : 'var(--primary)')};
    }
    100% {
      stroke: ${props => (props.color ? props.color : 'var(--primary)')};
    }
  }
`;

export default Loading;
