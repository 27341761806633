import styled from 'styled-components';

const DataTableHorizontalScroll = ({ children, ...props }) => (
  <HorizontalScroll {...props}>{children}</HorizontalScroll>
);

const HorizontalScroll = styled.div`
  /* Enable scrolling only along the X-axis */
  overflow-x: auto;
  overflow-y: hidden;

  /* Enable momentum scrolling on iOS devices */
  -webkit-overflow-scrolling: touch;

  /* Hide the scrollbar, but still allow scrolling */
  -ms-overflow-style: none; /* Hide scrollbar on IE and Edge */
  scrollbar-width: none; /* Hide scrollbar on Firefox */

  /* Add a custom scrollbar for non-touch devices */
  &::-webkit-scrollbar {
    height: 6px;
    margin: 0 0 1rem 0;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--gray-lightest);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-dark);
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--gray);
  }
`;

export default DataTableHorizontalScroll;
