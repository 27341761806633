const pluralize = (str = '', numeral, suffix = 's') => {
  if (!str) return '';
  const lastLetter = str.charAt(str.length - 1);
  return numeral === 1
    ? str
    : lastLetter === 's'
    ? `${str}es`
    : `${str}${suffix}`;
};

export default pluralize;
