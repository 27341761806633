import ProgressiveImage from 'react-progressive-image';
import EediSquiggle from 'assets/images/EediSquiggle';
import styled from 'styled-components';
import { getImageUrl } from 'config/settings';
import { Loading } from 'components/common';

/**
 * @desc - Returns the imageUrl parsed against the environment variables.
 * @param string $src - The src = image url to be parsed.
 * @param string $alt - The alt tag text string used in the image element.
 * @param object $styledImageContainer - A 'styled-components' derrived container used
 * to wrap the image.
 * @param bool $loadingImage - If true the 'EediSquiggle' image is used.
 * If false the 'Spinner' is used.
 * @param function $onClick - Associated onClick function. Remember to bind this if you need to.
 * @return string - The parsed imageUrl.
 */
const Image = ({
  src = null,
  alt = 'Image Loading...',
  styledImageContainer = DefaultStyledImageContainer,
  loadingImage = true,
  onClick = undefined,
  placeholder = null,
  onLoad,
  ...props
}) => {
  const StyledImageContainer = styledImageContainer;
  return (
    <StyledImageContainer onClick={onClick} {...props}>
      <ProgressiveImage src={getImageUrl(src)} placeholder={placeholder}>
        {(src, loading) => {
          if (loading) {
            return loadingImage ? (
              <LoadingImage>
                <EediSquiggle />
              </LoadingImage>
            ) : (
              <Loading />
            );
          }
          return <img src={src} alt={alt} onLoad={onLoad} />;
        }}
      </ProgressiveImage>
    </StyledImageContainer>
  );
};

const DefaultStyledImageContainer = styled.div`
  svg,
  img {
    width: 100%;
    height: auto;
  }
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
  overflow: hidden;
`;

const LoadingImage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--gray-lightest);
  aspect-ratio: 1 / 0.75;
  padding: 2rem;
`;

export default Image;
