const initialState = {
  isNavOpen: false,
  loading: {},
  modal: 0
};

const ui = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_NAV':
      return {
        ...state,
        isNavOpen: !state.isNavOpen
      };
    case 'SET_LOADING_STATE':
      return {
        ...state,
        loading: {
          ...state.loading,
          ...action.loadingState
        }
      };
    case 'CLOSED_SEARCH_PARAM_MODAL':
      return {
        ...state,
        modal: state.modal + 1
      };
    default:
      return state;
  }
};

export default ui;
