import styled from 'styled-components';

const Divider = ({ message }) => {
  return (
    <DividerWrapper>
      <DividerLeft />
      <DividerText>{message.text}</DividerText>
      <DividerRight />
    </DividerWrapper>
  );
};

const DividerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  margin: 0 0 0.75rem 0;
`;

const DividerLeft = styled.div`
  border-bottom: 3px solid #ddd;
  height: 3px;
  display: flex;
  align-self: center;
  margin: 0 0.5rem 0 0;
  flex-grow: 1;
`;

const DividerText = styled.div`
  color: #777;
`;

const DividerRight = styled.div`
  border-bottom: 3px solid #DDD;
  height: 3px;
  display: flex;
  align-self: center;
  flex-grow: 1
  margin: 0 0 0 0.5rem;
`;

export default Divider;
