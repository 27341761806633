import FlowApi from 'api/FlowApi';
import { FlowTypes } from './flowItemHelpers';

const createFlowSession = async ({
  flowType,
  templateSlug,
  topicPathwayQuizId,
  userId,
  flowGeneratorSessionId,
  quizSessionId,
  isRedirect = true,
  isFetchExistingSession = true,
  context,
  token,
  questionSequence = 1
}) => {
  let session = null;
  let navigateTo = null;

  if (flowGeneratorSessionId) {
    session = await FlowApi.GetFlowGeneratorSession(
      flowGeneratorSessionId,
      token
    );
    navigateTo = `/topic-session/${session.flowGeneratorSessionId}`;
  } else if (quizSessionId) {
    session = await FlowApi.GetFlowGeneratorSessionForQuizSession(
      quizSessionId,
      token
    );
    navigateTo = `/topic-session/${session.flowGeneratorSessionId}`;
  } else {
    switch (flowType.toLowerCase()) {
      case FlowTypes.retrievalPracticeAssignment.toLowerCase():
      case FlowTypes.topicPathwayQuiz.toLowerCase():
        session = await FlowApi.CreateFlowGeneratorSessionForTopicPathwayQuiz(
          userId,
          topicPathwayQuizId,
          { templateSlug, isTeacher: true, isFetchExistingSession },
          token
        );
        navigateTo = `/topic-session/${session.flowGeneratorSessionId}`;
        break;
      case FlowTypes.challenge.toLowerCase():
        session = await FlowApi.createFlowGeneratorSessionForChallenge(
          userId,
          topicPathwayQuizId,
          templateSlug,
          isFetchExistingSession,
          token
        );
        navigateTo = `/topic-session/${session.flowGeneratorSessionId}`;
        break;
      case FlowTypes.topicPathwayQuizQuestion.toLowerCase():
        session =
          await FlowApi.createFlowGeneratorSessionForTopicPathwayQuizQuestion(
            userId,
            topicPathwayQuizId,
            questionSequence,
            quizSessionId,
            templateSlug,
            isFetchExistingSession,
            token
          );
        navigateTo = `/topic-session/${session.flowGeneratorSessionId}`;
        break;
      case FlowTypes.onDemandSupport.toLowerCase():
        session = await FlowApi.createFlowGeneratorSessionForOnDemand(
          userId,
          templateSlug,
          token
        );
        navigateTo = `/topic-session/${session.flowGeneratorSessionId}`;
        break;
      case FlowTypes.createFlowGeneratorSessionForChatWithTutor:
        session = await FlowApi.createFlowGeneratorSessionForChatWithTutor(
          userId,
          templateSlug,
          token
        );
        navigateTo = `/topic-session/${session.flowGeneratorSessionId}`;
        break;
      case FlowTypes.generic.toLowerCase():
        session = await FlowApi.CreateGeneralFlowGeneratorSession(
          userId,
          templateSlug,
          { isTeacher: true, isFetchExistingSession },
          token
        );
        navigateTo = `/topic-session/${session.flowGeneratorSessionId}`;
        break;
      case 'TeacherAssistant'.toLowerCase():
        session = await FlowApi.StartTeacherConversation(null, context);
        break;
      default:
        throw new Error(`Invalid flow type: ${flowType}`);
    }

    if (!!session) {
      session.userId = userId;
    }
  }

  if (!session) throw new Error('Unable to create session!');

  if (flowType === FlowTypes.teacherAssistant) {
    //
  } else if (session.userId === userId) {
    const oldSessionFlowItems = session.flowItems;
    session = await FlowApi.UpdateFlowGeneratorSession(
      session.flowGeneratorSessionId,
      {
        input: '',
        flowItemGroup: 'Start!'
      },
      token
    );

    session.flowItems = [...session.flowItems, ...oldSessionFlowItems].reduce(
      (acc, cur) => {
        if (!acc.find(x => x.flowItemId === cur.flowItemId)) {
          acc.push(cur);
        }
        return acc;
      },
      []
    );
  }

  if (token) {
    navigateTo += '?token=' + token;
  }

  return { session, navigateTo: isRedirect ? navigateTo : null };
};

export default createFlowSession;
