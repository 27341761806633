import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { Api } from 'api';
import { ENVIRONMENT } from 'config/settings';
import { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';

export const SignalRConnectionContext = createContext();

const GetHubInfo = async (hubName, userId, userRole = 'Teacher') => {
  switch (hubName) {
    case 'LessonHub':
      return await Api.getLessonHubSignalRConnectionInfo(
        userId,
        hubName,
        userRole
      );
    case 'SignalRHub':
      return await Api.getSignalRConnectionInfo(userId, hubName, userRole);
    default:
      throw new Error(`${hubName} not implemented`);
  }
};

export const SignalRProvider = ({ hubName, children, context }) => {
  const { userId, userRole } = useSelector(state => state.user);
  const [connection, setConnection] = useState(null);
  const [connectionInfo, setConnectionInfo] = useState(null);

  useEffect(() => {
    let wasCancelled = false;

    async function createSignalRConnection() {
      try {
        if (userRole !== 'Teacher') return;

        if (userId && connectionInfo?.userId !== userId) {
          let info = await GetHubInfo(hubName, userId, userRole);

          const options = {
            accessTokenFactory: async () => {
              const token = jwt_decode(info.accessToken);
              const expiry = new Date(token.exp * 1000);
              const msDifference = expiry - new Date();
              const minutes = Math.floor(msDifference / 1000 / 60);

              // If we're less than 30 minutes from expiry, refetch the token
              if (minutes <= 30) {
                info = await GetHubInfo(hubName, userId, userRole);
              }

              return info.accessToken;
            }
          };

          const newConnection = new HubConnectionBuilder()
            .withUrl(info.url, options)
            .withAutomaticReconnect()
            .configureLogging(
              ENVIRONMENT === 'prod' ? LogLevel.Error : LogLevel.Debug
            )
            .build();

          if (!wasCancelled) {
            await newConnection.start();
          }

          if (!wasCancelled) {
            setConnectionInfo({ ...info, userId, connectionDate: new Date() });
            setConnection(newConnection);
          }
        }
      } catch (e) {
        console.error(e);
      }
    }

    if (userRole === 'Teacher') createSignalRConnection();

    return () => (wasCancelled = true);
  }, [connectionInfo, hubName, userId, userRole]);

  const Context = context || SignalRConnectionContext;

  return <Context.Provider value={connection}>{children}</Context.Provider>;
};
