import {
  Dropdown as ReactstrapDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  UncontrolledDropdown
} from 'reactstrap';
import styled from 'styled-components';

const Dropdown = props => {
  props = { ...props, disabled: !!props.disabled };
  if (props.isOpen && props.toggle) {
    return <ReactstrapDropdown {...props} />;
  }
  return <UncontrolledDropdown {...props} />;
};

Dropdown.Toggle = ({ children = null, caret = false, ...props }) => {
  if (!children) return null;
  return (
    <DropdownToggle {...props}>
      {children}
      {caret && (
        <svg
          width="18"
          height="18"
          aria-hidden="true"
          viewBox="0 0 20 20"
          style={{ marginLeft: '0.5rem', marginRight: 0 }}>
          <path
            fill="currentColor"
            d="M4.516 7.548c.436-.446 1.043-.481 1.576 0L10 11.295l3.908-3.747c.533-.481 1.141-.446 1.574 0 .436.445.408 1.197 0 1.615-.406.418-4.695 4.502-4.695 4.502a1.095 1.095 0 01-1.576 0S4.924 9.581 4.516 9.163s-.436-1.17 0-1.615z"></path>
        </svg>
      )}
    </DropdownToggle>
  );
};
Dropdown.Item = DropdownItem;
Dropdown.Menu = DropdownMenu;

Dropdown.Container = styled.div`
  padding: 0.5rem 1.5rem;
`;

export default Dropdown;
