import { useSelector } from 'react-redux';
import useLoadUserContext from './useLoadUserContext';
import { useMatch } from 'react-router';
import { useApiQuery } from 'api';

const useSetUserContext = () => {
  const { loadUserContext } = useLoadUserContext();
  const match = useMatch('/:schoolId');
  const { userId, schoolId } = useSelector(s => ({
    userId: s.user?.userId,
    schoolId: s.school?.schoolId
  }));

  const { loading } = useApiQuery(async () => {
    if (!userId) {
      return;
    }

    const schoolIdParam = parseInt(match?.params?.schoolId, 10);
    return await loadUserContext(schoolIdParam || schoolId || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading
  };
};

export default useSetUserContext;
