import styled from 'styled-components';

const CloseButton = ({ children, ...props }) => (
  <StyledButton {...props}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 6L6 18"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6L18 18"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    {children}
  </StyledButton>
);

const StyledButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.75rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  line-height: 1rem;
  color: ${props => props.foregroundcolor || 'var(--gray-light)'};
  &:hover {
    text-decoration: none;
    color: var(--gray);
  }
`;

export default CloseButton;
