import styled from 'styled-components';

const Information = ({ message }) => {
  return <InformationWrapper>{message.text}</InformationWrapper>;
};

const InformationWrapper = styled.div`
  display: flex;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  border-radius: 15px;
  background: #72ab9e;
  color: #fff;
  font-style: italic;
  margin: 0 0 0.75rem 0;
  -webkit-box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.17);
  box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.17);
  &.animateIn {
    animation: enlarge 0.5s ease;

    @keyframes enlarge {
      0% {
        transform: scale(0);
      }

      100% {
        transform: scale(1);
      }
    }
  }
`;

export default Information;
