import * as React from 'react';
const SvgAdmin = ({ size = '16', color, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M21 10H19V4H20V2H4V4H5V10H3C2.73478 10 2.48043 10.1054 2.29289 10.2929C2.10536 10.4804 2 10.7348 2 11V20H22V11C22 10.7348 21.8946 10.4804 21.7071 10.2929C21.5196 10.1054 21.2652 10 21 10ZM14 18V14H10V18H7V4H17V18H14Z"
      fill={color ?? 'currentColor'}
    />
    <path
      d="M9 6H11V8H9V6ZM13 6H15V8H13V6ZM9 10H11V12H9V10ZM13 10H15V12H13V10Z"
      fill={color ?? 'currentColor'}
    />
  </svg>
);
export default SvgAdmin;
