export const prepareInitials = name =>
  !name
    ? ''
    : name
        .trim()
        .split(' ')
        .map(item => item && item.trim().charAt(0))
        .join('')
        .toUpperCase()
        .substring(0, 2); // we could change this if we want more than two letters in the initials

export const prepareInitialsFromNames = (...names) =>
  [...names]
    .filter(item => item)
    .map(item => item && item.trim().charAt(0))
    .join('')
    .toUpperCase()
    .substring(0, 2); // we could change this if we want more than two letters in the initials

export default prepareInitials;
