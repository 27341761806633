const studentImportReducer = (
  state = {
    studentsToImport: [],
    studentsReadyToImport: []
  },
  action
) => {
  switch (action.type) {
    case 'SET_STUDENTS_TO_IMPORT':
      return {
        ...state,
        studentsToImport: action.studentsToImport
      };
    case 'SET_STUDENTS_READY_TO_IMPORT':
      return {
        ...state,
        studentsReadyToImport: action.studentsReadyToImport
      };
    case 'CLEAR_ALL_STUDENTS_TO_IMPORT':
      return {
        ...state,
        studentsReadyToImport: [],
        studentsToImport: []
      };
    default:
      return state;
  }
};

export default studentImportReducer;
