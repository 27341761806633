import FlowApi from 'api/FlowApi';
import { testFlowItems } from './helpers/flowItemHelpers';
import { toaster } from 'components/common';
import { useNavigate, useParams } from 'react-router';
import useQueryParams from 'hooks/useQueryParams';
import { useSelector } from 'react-redux';
import useStateReducer from 'hooks/useCustomReducer';
import { useCallback, useEffect, useRef, useState } from 'react';
import useHubAction from 'hooks/useHubAction';
import { useSignalROn } from 'hooks/useSignalROn';
import createFlowSession from './helpers/createFlowSession';
import jwtDecode from 'jwt-decode';

const guestUserIds = [2957983, 2957982];

const useManageFlowSession = ({
  templateSlug = '',
  flowType = null,
  isRedirect = true,
  topicPathwayQuizId = null,
  quizSessionId = null,
  botContext = null,
  isFetchExistingSession = true
}) => {
  const {
    flowGeneratorSessionId,
    questionSequence: paramQuestionSequence,
    templateSlug: paramTemplateSlug,
    quizSessionId: paramQuizSessionId,
    topicPathwayQuizId: paramTopicId,
    flowType: paramFlowType
  } = useParams();

  templateSlug = paramTemplateSlug ?? templateSlug;
  const queryParams = useQueryParams() ?? {};
  const { test, topicId } = queryParams;

  // Token bits
  let token = queryParams?.token;
  let userId = useSelector(s => s.user.userId);
  if (token && !userId) {
    const decodedToken = jwtDecode(token);
    if (decodedToken) {
      userId = decodedToken?.UserId;
    }
  }

  const [session, setSession] = useState(null);
  const loadingRef = useRef(false);
  const navigate = useNavigate();

  const filterFlowItems = ({ flowItems, newFlowItems = [], userId }) => {
    if (!flowItems?.length) return [];
    let delay = 0;
    return flowItems
      .map(flowItem => {
        flowItem.isSessionUser = flowItem.userId === userId;
        return flowItem;
      })
      .sort((a, b) => new Date(a.dateDispatched) - new Date(b.dateDispatched))
      .map(flowItem => {
        if (newFlowItems.find(m => m.flowItemId === flowItem.flowItemId)) {
          flowItem.isNew = true;
          flowItem.secondsDelay = delay;
          delay += 0.4;
        }
        return flowItem;
      });
  };

  const { state, setState } = useStateReducer({
    stateName: 'FlowState',
    initialState: {
      flowGeneratorSessionId: null,
      topicId: null,
      input: null,
      messages: session?.flowItems || [],
      session: null,
      isGPTLoading: false,
      isDispatching: false
    }
  });

  const initSession = useCallback(async () => {
    try {
      const { session, navigateTo } = await createFlowSession({
        flowType: paramFlowType ?? flowType,
        templateSlug,
        topicPathwayQuizId: paramTopicId ?? topicId ?? topicPathwayQuizId,
        userId,
        flowGeneratorSessionId,
        quizSessionId: paramQuizSessionId ?? quizSessionId,
        isRedirect,
        context: botContext?.context,
        isFetchExistingSession,
        token,
        questionSequence: paramQuestionSequence ?? 1
      });

      if (!session) throw new Error('Unable to init session!');

      if (navigateTo) navigate(navigateTo);

      session.flowItems = filterFlowItems(session);

      if (!!test) {
        session.flowItems = testFlowItems;
      }

      setSession(session);
      setState({
        flowGeneratorSessionId: session.flowGeneratorSessionId,
        messages: session.flowItems
      });
    } catch (e) {
      console.error(e);
      toaster.danger('Error loading flow');
    }
  }, [
    botContext?.context,
    flowGeneratorSessionId,
    flowType,
    isFetchExistingSession,
    isRedirect,
    navigate,
    paramFlowType,
    paramQuestionSequence,
    paramQuizSessionId,
    paramTopicId,
    quizSessionId,
    setState,
    templateSlug,
    test,
    token,
    topicId,
    topicPathwayQuizId,
    userId
  ]);

  const { action: joinGroup } = useHubAction(
    'JoinClientFlowSessionGroup',
    'LessonHub',
    session?.flowGeneratorSessionId
  );

  joinGroup();

  useEffect(() => {
    if (!loadingRef.current && !session) {
      loadingRef.current = true;
      initSession();
      loadingRef.current = false;
    }
  }, [initSession, session]);

  useSignalROn(
    `DispatchGPTTextLoading:${state.flowGeneratorSessionId}`,
    isLoading => {
      if (!state.flowGeneratorSessionId) return;

      const isGPTLoading = isLoading.toLowerCase() === 'true';
      setState({ isGPTLoading });
    }
  );

  useSignalROn(
    `DispatchGPTFlowItem:${state.flowGeneratorSessionId}`,
    flowItem => {
      try {
        if (!state.flowGeneratorSessionId) return;

        const gptFlowItem = JSON.parse(flowItem);
        gptFlowItem['flowItemType'] = 'Text';
        const filteredMessages = state.messages.filter(
          m => m.flowItemId !== gptFlowItem.flowItemId
        );
        filteredMessages.push(gptFlowItem);

        setState({
          messages: filteredMessages.sort(
            (a, b) => new Date(a.dateDispatched) - new Date(b.dateDispatched)
          )
        });
      } catch (e) {
        console.error(e);
      }
    }
  );

  useSignalROn(`DispatchFlowItem:${state.flowGeneratorSessionId}`, flowItem => {
    if (
      state.messages.find(message => message.flowItemId === flowItem.flowItemId)
    )
      return;

    setState({
      messages: filterFlowItems({
        flowItems: [...state.messages, flowItem],
        userId
      })
    });
  });

  const sendMessage = useCallback(
    async ({ input, flowItemGroup }) => {
      if (!input || !state.flowGeneratorSessionId || state.isDispatching)
        return;

      setState({ input: null, isDispatching: true });
      try {
        if (flowItemGroup) {
          const session = await FlowApi.UpdateFlowGeneratorSession(
            state.flowGeneratorSessionId,
            {
              input,
              flowItemGroup
            },
            token
          );
          session.flowItems = filterFlowItems({
            flowItems: [...state.messages, ...session.flowItems],
            newFlowItems: session.flowItems,
            userId: session.userId
          });
          setSession(session);
          setState({
            messages: session.flowItems
          });
        } else {
          await FlowApi.SendTeacherConversationMessage(
            state.flowGeneratorSessionId,
            input,
            botContext?.context
          );
        }
      } catch (e) {
        console.error(e);
        toaster.danger(`${e}`);
      }
      setState({ isDispatching: false });
    },
    [
      botContext?.context,
      setState,
      state.flowGeneratorSessionId,
      state.isDispatching,
      state.messages,
      token
    ]
  );

  const currentFlowItemGroup = session?.flowItemGroupInputs?.sort(
    (a, b) => b.flowItemGroupInputId - a.flowItemGroupInputId
  )[0]?.flowItemGroup;

  const currentMessage = [
    ...state.messages?.filter(
      m =>
        (!currentFlowItemGroup || m.flowItemGroup === currentFlowItemGroup) &&
        m.userId <= 0
    )
  ]?.sort((a, b) => new Date(b.dateDispatched) - new Date(a.dateDispatched))[0];

  return {
    conversation: session,
    loading: loadingRef.current || !session,
    state,
    setState,
    userId,
    sendMessage,
    placeholder: !state.messages?.filter(m => m.userId === userId)
      ? 'How can I help?'
      : '',
    isReadOnly:
      state.isDispatching ||
      state.isGPTLoading ||
      (guestUserIds.indexOf(session?.userId) === -1 &&
        session?.userId !== userId &&
        !token),
    isDispatching: state.isDispatching,
    currentFlowItemGroup,
    currentMessage
  };
};

export default useManageFlowSession;
