import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ children, ...props }) => (
  <Crumbs {...props}>{children}</Crumbs>
);

Breadcrumb.Item = ({ children, to, ...props }) => {
  return (
    <Item {...props}>
      {to ? <Link to={to}>{children}</Link> : <>{children}</>}
    </Item>
  );
};

const Crumbs = styled.div`
  padding: 1rem 0;
`;

const Item = styled.span`
  display: inline-flex;
  color: var(--gray);
  ${({ active }) =>
    !active &&
    `
    font-weight: 500;
  `}

  margin-right: 0.5rem;

  &:after {
    content: '/';
    margin-left: 0.5rem;
    color: var(--border-color);
  }

  &:last-child {
    &:after {
      content: '';
    }
  }
`;

export default Breadcrumb;
