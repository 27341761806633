const idFields = {
  Assignment: o => o.assignmentId,
  Charge: o => o.chargeId,
  Child: o => o.childId,
  Department: o => o.departmentId,
  Discount: o => o.discountId,
  Group: o => o.groupId,
  HolidayUnit: o => o.holidayUnitId,
  Insight: o => o.assignmentId,
  MisconceptionQuestion: o => o.questionId,
  PaymentSource: o => o.sourceId,
  Profile: o => `${o.userId}-${o.teacherId}`,
  Quiz: o => o.quizId,
  QuizAssignment: o => o.assignmentId,
  QuizQuestion: o => o.questionId,
  QuizQuestionAnswer: o => o.answerId,
  QuizResource: o => o.resourceId,
  QuizSession: o => o.quizSessionId,
  QuizSessionQuestion: o => o.questionId,
  ResourceRating: o => `${o.resourceId}-${o.userId}`,
  Scheme: o => o.schemeId,
  SchemeBase: o => o.schemeId,
  SchemeGroup: o => o.groupId,
  SchemeLabel: o => o.schemeOfWorkLabelId,
  SchemeQuiz: o => o.topicUnitQuizId,
  School: o => o.schoolId,
  Student: o => o.studentId,
  StripeCustomer: o => o.customerId,
  Subject: o => o.subjectId,
  SubjectMisconceptions: o => o.subjectId,
  Subscription: o => o.userId,
  SubtopicsMisconceptions: o => o.subjectId,
  Teacher: o => o.teacherId,
  TopicsMisconceptions: o => o.subjectId,
  TopicUnit: o => o.topicUnitId,
  TopicUnitBase: o => o.topicUnitId,
  User: o => o.userId,
  UserPlan: o => o.userId,
  YearGroup: o => o.yearGroupId,
  Plan: o => o.planId
};

export default idFields;
