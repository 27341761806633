import joinStatuses from 'constants/joinStatuses';

const defaultFields = {
  schoolId: null,
  name: '',
  address: '',
  city: '',
  zipOrPostCode: '',
  country: null
};

const initialState = {
  fields: defaultFields,
  joinStatus: null
};

const joinSchoolStepper = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_JOIN_STATUS':
      return {
        ...state,
        joinStatus: action.status,
        error: action.error
      };

    case 'CHANGE_JOIN_SCHOOL_FIELD':
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.name]: action.value
        }
      };

    case 'PREPARE_TO_JOIN':
      return {
        ...state,
        fields: action.fields,
        joinStatus: joinStatuses.willJoin
      };

    case 'PREPARE_TO_ADD':
      return {
        ...state,
        fields: {
          ...state.fields,
          name: action.name
        },
        joinStatus: joinStatuses.willAdd
      };

    case 'JOIN_STEPPER_SCHOOL_CLEAR':
      return {
        ...state,
        fields: defaultFields
      };

    default:
      return state;
  }
};

export default joinSchoolStepper;
