import { useState, useEffect, useCallback } from 'react';

const useQuery = query => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const queryCallback = useCallback(async () => await query(), [query]);

  useEffect(() => {
    async function fetch() {
      if (loading && !data) {
        try {
          const data = await queryCallback();
          setData(data);
        } catch (e) {
          setError(e);
        }
        setLoading(false);
      }
    }
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data,
    loading,
    error,
    reload: () => setLoading(true)
  };
};

export default useQuery;
