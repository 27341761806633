import * as React from 'react';
const SvgFix = ({ size = '16', color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    {...props}>
    <path
      fill={color ?? 'currentColor'}
      d="M3 10.945h13.607l-5.285-5.284L12.982 4l8.12 8.12-8.12 8.12-1.66-1.661 5.285-5.285H3v-2.349Z"
    />
  </svg>
);
export default SvgFix;
