import { Api } from 'api';
import EmptyState from 'components/EmptyState';
import {
  Card,
  Header,
  Info,
  Loading,
  Select,
  toaster
} from 'components/common';
import { motion } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { sizes } from 'styles';
import LeagueItem from './LeagueItem';
import useInterval from 'hooks/useInterval';
import { setGroup } from 'actions/group';
import GroupIcon from 'assets/icons/GroupIcon';

const dateTypes = {
  CurrentWeek: { label: 'This Week', value: 'CurrentWeek' },
  CurrentMonth: { label: 'This Month', value: 'CurrentMonth' },
  CurrentYear: { label: 'This Year', value: 'CurrentYear' },
  AllTime: { label: 'All Time', value: 'AllTime' }
};

const GroupLeague = () => {
  const dispatch = useDispatch();
  const { schoolId, groupId, groups } = useSelector(s => ({
    schoolId: s.school.schoolId,
    groups: s.school.groups,
    groupId: s.group.groupId
  }));
  const [state, setState] = useState({
    dateType: dateTypes.AllTime.value,
    data: null,
    yearGroups: [{ label: 'All Year Groups', value: 0 }],
    yearGroupId: 0,
    loaded: false,
    loading: false
  });
  const navigate = useNavigate();

  const getYearGroups = useCallback(async () => {
    try {
      if (state.yearGroups.length > 1) return;
      const yearGroups = await Api.getSchoolYearGroups(schoolId);
      setState(s => ({
        ...s,
        yearGroups: [
          ...s.yearGroups,
          ...yearGroups.map(yg => ({
            label: yg.name,
            value: yg.yearGroupId
          }))
        ]
      }));
    } catch (e) {
      console.error(e);
    }
  }, [schoolId, state.yearGroups.length]);

  const fetchGroupLeague = useCallback(
    async ({ dateType, yearGroupId }) => {
      if (!schoolId || state.loading) return;
      setState(s => ({ ...s, loading: true }));
      let data = null;
      try {
        let dateFrom = null;

        switch (dateType) {
          case dateTypes.CurrentWeek.value:
            dateFrom = new Date();
            // get most recent monday
            dateFrom.setHours(0, 0, 0, 0);
            dateFrom.setDate(
              dateFrom.getDate() - ((dateFrom.getDay() + 6) % 7)
            );
            break;
          case dateTypes.CurrentMonth.value:
            dateFrom = new Date();
            dateFrom.setHours(0, 0, 0, 0);
            dateFrom.setDate(1);
            break;
          case dateTypes.CurrentYear.value:
            dateFrom = new Date();
            dateFrom.setHours(0, 0, 0, 0);
            dateFrom.setMonth(0);
            dateFrom.setDate(1);
            break;
          default:
            break;
        }

        data = await Api.getClassLeagueInsights({
          schoolId,
          dateFrom: dateFrom ? dateFrom.toISOString() : null,
          yearGroupIds: yearGroupId ? [yearGroupId] : []
        });
        if (data?.groups?.length) {
          data.groups = data.groups.map(s => ({
            ...s,
            name: s.code ?? '-'
          }));
        }
      } catch (e) {
        toaster.danger(e.message);
        console.error(e);
        navigate(`/${schoolId}/students`);
      }

      await getYearGroups();

      setState(s => ({
        ...s,
        yearGroupId,
        loading: false,
        loaded: true,
        data,
        dateType
      }));
    },
    [getYearGroups, navigate, schoolId, state]
  );

  useEffect(() => {
    const runEffect = async () => {
      if (!state.data && !state.loaded && !state.loading)
        await fetchGroupLeague(state);
    };
    runEffect();
  }, [fetchGroupLeague, groupId, state]);

  useInterval(async () => {
    await fetchGroupLeague(state);
  }, 1000 * 60 * 30);

  if (!state.loaded) return <Loading />;

  return (
    <>
      <Header>
        <Header.Title>
          <h1>Class League</h1>
          <p className="text-right text-muted">
            Updated automatically every 30 minutes
            <Info
              className="ml-1"
              id="lastUpdated"
              tooltip="School league results are updated / this page refreshes automatically every 30 minutes."
            />
          </p>
        </Header.Title>
      </Header>

      <Background>
        <Filters>
          {state.yearGroups.length ? (
            <FilterBox>
              <Select
                label="Year:"
                isSearchable={false}
                options={state.yearGroups}
                value={state.yearGroups.find(
                  yg => yg.value === state.yearGroupId
                )}
                onChange={async e =>
                  await fetchGroupLeague({
                    dateType: state.dateType,
                    yearGroupId: e.value
                  })
                }
              />
            </FilterBox>
          ) : null}
          <FilterBox>
            <Select
              label="Period:"
              isSearchable={false}
              options={Object.values(dateTypes)}
              value={dateTypes[state.dateType]}
              onChange={async e =>
                await fetchGroupLeague({
                  yearGroupId: state.yearGroupId,
                  dateType: e.value
                })
              }
            />
          </FilterBox>
        </Filters>

        {state.loading ? (
          <Loading />
        ) : !state?.data?.groups?.length ? (
          <EmptyState
            title={`No results found for "${dateTypes[state.dateType].label}"`}
          />
        ) : (
          <List
            variants={{
              hidden: { opacity: 0 },
              show: {
                opacity: 1,
                transition: {
                  staggerChildren: 0.1,
                  delayChildren: 0.3
                }
              }
            }}
            initial="hidden"
            animate="show">
            {state?.data?.groups
              ?.sort((a, b) => a.rank - b.rank)
              ?.map((s, i) => (
                <motion.div
                  key={i}
                  className="grid-item"
                  variants={{
                    hidden: { opacity: 0, scale: 0.3, x: -150 },
                    show: { opacity: 1, scale: 1, x: 0 }
                  }}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    const currentGroup = groups.find(
                      g => g.groupId === s.groupId
                    );
                    if (!!currentGroup) {
                      dispatch(setGroup(currentGroup));
                      navigate(`/${schoolId}/motivate/class-league`);
                    }
                  }}>
                  <LeagueItem
                    {...s}
                    icon={<GroupIcon groupId={s.code} />}
                    isSelf={groupId === s.groupId}
                  />
                </motion.div>
              ))}
          </List>
        )}
      </Background>
    </>
  );
};

const Background = styled.div`
  background-color: #eee;
  background: url(https://images.diagnosticquestions.com/eedi-home/assets/backgrounds/school-league-background.svg)
    1% 0% / cover repeat;
  padding: 2rem;
`;

const FilterBox = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  min-width: 230px;
  margin: 0 0 0 0.5rem;
  label {
    margin: 0 0.5rem 0 0;
    @media (max-width: ${sizes.md}px) {
      display: none;
    }
  }
  input {
    min-width: 80px;
  }
  @media (max-width: ${sizes.md}px) {
    width: 100%;
  }
`;

const Filters = styled.div`
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  @media (max-width: ${sizes.md}px) {
    flex-direction: column;
    // first div child
    div {
      margin: 0;
    }
    & > div:first-child {
      margin: 0 0 0.5rem 0;
    }
  }
`;

const List = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  width: 100%;
  align-items: center;
  .grid-item {
    width: 100%;
    max-width: 600px;
    margin: 0 0 1.25rem 0;
  }
`;

export default GroupLeague;
