import React, { Component } from 'react';
import styled from 'styled-components';
import Tappable from 'react-tappable';

class DropdownList extends Component {
  render() {
    const { options, selectedId, excludeSelectedOption } = this.props;
    return (
      <div>
        {options && options.length > 0 ? (
          <List>
            {excludeSelectedOption
              ? options
                  .filter(({ id }) => id !== selectedId)
                  .map(({ id, value }) => (
                    <Tappable
                      key={id}
                      onTap={() => this.props.handleSelect(id)}>
                      <Item>{value}</Item>
                    </Tappable>
                  ))
              : options.map(({ id, value }) => (
                  <Tappable key={id} onTap={() => this.props.handleSelect(id)}>
                    <Item>{value}</Item>
                  </Tappable>
                ))}
          </List>
        ) : null}
      </div>
    );
  }
}

const List = styled.ul`
  padding: 0.75rem 0rem;
  min-width: 9rem;
  cursor: pointer;
`;

const Item = styled.li`
  padding: 0.75rem 1.5rem;
  cursor: pointer;

  &:hover {
    background-color: var(--tint-color);
  }
`;

export default DropdownList;
