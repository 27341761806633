import { Button as CommonButton, Icon } from 'components/common';

function Button({
  leftIconName = '',
  rightIconName = '',
  small = false,
  secondary = false,
  tertiary = false,
  link = false,
  loading = false,
  disabled = false,
  children,
  onClick,
  ...props
}) {
  return (
    <CommonButton
      color={
        link
          ? 'link'
          : secondary
          ? 'secondary'
          : tertiary
          ? 'secondary'
          : 'primary'
      }
      loading={loading}
      disabled={disabled}
      size={small ? 'small' : null}
      onClick={onClick}
      {...props}>
      {leftIconName && <Icon name={leftIconName} />}
      {children}
      {rightIconName && <Icon name={rightIconName} color="inherit" />}
    </CommonButton>
  );
}

export default Button;
