import styled from 'styled-components';
import Button from './Button';

const Filter = ({
  filters,
  currentFilter,
  disabled,
  onClick,
  style,
  ...props
}) => (
  <ButtonGroup style={style} {...props}>
    {filters.map((filter, i) => {
      if (!filter) {
        return null;
      }

      // To support filter by multiple filters make sure we always have an array
      if (!Array.isArray(currentFilter)) {
        currentFilter = [currentFilter];
      }

      const id = filter.id || i;
      const value = filter.value || filter;
      const isActive = currentFilter.includes(filter.id);
      const isDisabled = filter.disabled || disabled;

      const ButtonComponent =
        isActive && !isDisabled ? ActiveButton : InactiveButton;

      return (
        <ButtonComponent
          key={id}
          //active={isActive}
          onClick={() => onClick(id)}
          outline
          disabled={filter.disabled || disabled}>
          {value}
          {Number.isInteger(filter.count) && <Count>{filter.count}</Count>}
        </ButtonComponent>
      );
    })}
  </ButtonGroup>
);

const ButtonGroup = styled.div`
  background-color: rgba(var(--black-rgba), 0.1);
  border-radius: 0.5rem;
  box-shadow: 0 0 0 0.125rem rgba(var(--black-rgba), 0.1);
  display: inline-block;
`;

const InactiveButton = styled(Button)`
  background-color: transparent;
  border-color: transparent;
  color: var(--gray-dark);
  box-shadow: none;
  font-weight: 400;

  &:hover,
  &:active {
    background-color: transparent !important;
    border-color: transparent !important;
    color: var(--black) !important;
    box-shadow: none !important;
  }

  &.disabled,
  &:disabled {
    background-color: transparent;
    border-color: transparent;
  }
`;

const ActiveButton = styled(Button)`
  border-color: var(--gray-light);
  background-color: var(--white);
  color: var(--gray-dark);
  box-shadow: none;
  font-weight: 500;

  &:hover,
  &:active {
    border-color: var(--black) !important;
    background-color: var(--white) !important;
    color: var(--black) !important;
    box-shadow: none !important;
  }
`;

const Count = styled.span`
  font-weight: normal;
  margin-left: 0.5rem;
  background-color: rgba(var(--gray-light-rgba), 0.4);
  border-radius: 99rem;
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  text-align: center;
`;

export default Filter;
