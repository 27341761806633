import IntercomAppProvider from './IntercomAppProvider';
import AnalyticsProvider from 'analytics/AnalyticsProvider';
import LoadingPage from 'layouts/LoadingPage';
import ConsentGate from 'components/cookies/ConsentGate';
import useSetUserContext from 'hooks/useSetUserContext';
import { LessonHubSignalRProvider } from 'providers/LessonHubSignalRProvider';

const AppProviders = ({ children }) => {
  const { loading } = useSetUserContext();

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <LessonHubSignalRProvider>
      {children}
      <IntercomAppProvider />
      <ConsentGate policy="analytics">
        <AnalyticsProvider />
      </ConsentGate>
    </LessonHubSignalRProvider>
  );
};

export default AppProviders;
