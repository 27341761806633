import styled from 'styled-components';

const Indicator = styled.span`
  display: inline-block;
  margin: 0 0.5rem;
  color: currentColor;
`;

export const SortIndicator = ({ isSortedDesc }) => (
  <Indicator>{isSortedDesc ? '▲' : '▼'}</Indicator>
);

export default SortIndicator;
