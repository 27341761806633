import * as React from 'react';
const SvgMisconceptionUnresolved = ({ size = '16', color, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M0 12a12 12 0 1 1 24 0 12 12 0 0 1-24 0ZM11.999 2a10 10 0 1 0 0 20.001 10 10 0 0 0 0-20Z"
      fill={color ?? 'currentColor'}
    />
    <path
      d="M7.607 14.974a1.18 1.18 0 0 0 1.667 1.668l2.727-2.727 2.726 2.727a1.18 1.18 0 0 0 1.668-1.668l-2.726-2.726 2.726-2.727a1.18 1.18 0 0 0-1.668-1.667l-2.726 2.726-2.727-2.726A1.18 1.18 0 0 0 7.607 9.52l2.726 2.727-2.726 2.726Z"
      fill={color ?? 'currentColor'}
    />
  </svg>
);
export default SvgMisconceptionUnresolved;
