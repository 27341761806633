import ReactLinkify from 'react-linkify';
import styled from 'styled-components';

const FormattedTextContent = ({ content = '' }) => {
  let extractedSections = {};

  const formattedParagraphs = content
    .replaceAll('\\n', '\n')
    .split('\n')
    .filter(x => !!x && x !== '\\[' && x !== '\\]')
    .map(str => (extractedSections[str] ? extractedSections[str] : str));

  return (
    <StyledLinkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={decoratedHref}
          key={key}>
          {decoratedText}
        </a>
      )}>
      {formattedParagraphs.map((str, i) => (
        <Paragraph key={i}>
          {/* latext here... */}
          {str}
        </Paragraph>
      ))}
    </StyledLinkify>
  );
};

const StyledLinkify = styled(ReactLinkify)``;

const Paragraph = styled.p`
  word-break: break-word;
  margin: 0;

  a {
    color: white;
    text-decoration: underline;
  }
  & + & {
    margin-top: 1rem;
  }
`;

export default FormattedTextContent;
