import { FlowItemType } from '../helpers/flowItemHelpers';
import Divider from '../messageTypes/Divider';
import HighlightedText from '../messageTypes/HighlightedText';
import ImageMessage from '../messageTypes/ImageMessage';
import Information from '../messageTypes/Information';
import RadioButton from '../messageTypes/RadioButton';
import TextMessage from '../messageTypes/TextMessage';
import VideoMessage from '../messageTypes/VideoMessage';

const messageFactory = ({
  message,
  userId,
  key,
  sendMessage = () => {},
  currentFlowItemGroup = null,
  isDispatching = false,
  isReadOnly = false,
  botAvatar = null
}) => {
  const fromdirection =
    `${userId}` === `${message.userId}` || message.isSessionUser
      ? 'right'
      : 'left';
  switch (message.flowItemType) {
    case FlowItemType.TextInput:
    case FlowItemType.Text:
    case FlowItemType.PinnedText:
      if (!message.text) return null;
      return (
        <TextMessage
          key={key}
          message={message}
          fromdirection={fromdirection}
          botAvatar={botAvatar}
        />
      );
    case FlowItemType.HighlightedText:
      if (!message.text) return null;
      return (
        <HighlightedText
          key={key}
          message={message}
          fromdirection={fromdirection}
          botAvatar={botAvatar}
        />
      );
    case FlowItemType.Information:
      if (!message.text) return null;
      return <Information key={key} message={message} />;
    case FlowItemType.Image:
    case FlowItemType.PinnedImage:
      if (!message.imageUrl && !message.text) return null;
      return (
        <ImageMessage
          key={key}
          message={message}
          fromdirection={fromdirection}
          botAvatar={botAvatar}
        />
      );
    case FlowItemType.Video:
    case FlowItemType.PinnedVideo:
      if (!message.videoUrl && !message.text) return null;
      return (
        <VideoMessage
          key={key}
          message={message}
          fromdirection={fromdirection}
          botAvatar={botAvatar}
        />
      );
    case FlowItemType.RadioButtonInput:
    case FlowItemType.DiagnosticQuestionAnswerInput:
    case FlowItemType.DiagnosticQuestionRetryInput:
      return (
        <RadioButton
          key={key}
          message={message}
          sendMessage={sendMessage}
          currentFlowItemGroup={currentFlowItemGroup}
          isDispatching={isDispatching}
          isReadOnly={isReadOnly}
          botAvatar={botAvatar}
        />
      );
    case FlowItemType.Divider:
      if (!message.text) return null;
      return <Divider key={key} message={message} />;
    default:
      return null;
  }
};
export default messageFactory;
