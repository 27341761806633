import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SmallCard } from 'components/dls';
import { Badge, Icon } from 'components/common';
import { selectSchool } from 'actions/onboarding';
import { prepareToAdd, prepareToJoin } from 'actions/joinSchoolStepper';
import SearchStep from 'components/stepper/SearchStep';
import { useNavigate } from 'react-router';
import { Api, useApiQuery } from 'api';
import useDebounce from 'hooks/useDebounce';
import { FilledCircle, SchoolIcon } from 'assets/icons';
import { colors } from 'styles';

const PAGE_SIZE = 50;

const SchoolSearch = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [schoolName, setSchoolName] = useState('');
  const debouncedSchoolName = useDebounce(schoolName, 500);

  const { loading, data } = useApiQuery(async () => {
    if (!debouncedSchoolName.length) return null;

    return Api.searchSchools(debouncedSchoolName, 1, PAGE_SIZE);
  }, [debouncedSchoolName]);

  const renderItem = school => {
    return (
      <div className="mb-2" key={school.schoolId}>
        <SmallCard
          title={school.name}
          subtitle={[school.stateProvinceCounty, school.city]
            .filter(item => item)
            .join(', ')}
          avatarComponent={
            <FilledCircle size={3.5} color={colors.grayLightest}>
              <SchoolIcon size="2.5rem" name={school.name} />
            </FilledCircle>
          }
          rightComponentOne={
            <Badge color="grayLighter">
              <Icon name="users" />
              {school.studentCount.toString()}
            </Badge>
          }
          onClick={() => {
            dispatch(selectSchool(school.schoolId));
            dispatch(prepareToJoin(school));
            navigate('/schools/join');
          }}
        />
      </div>
    );
  };

  const handleSearchInputChange = nextSchoolName => {
    setSchoolName(nextSchoolName);
  };

  const handleAddClick = value => {
    dispatch(prepareToAdd(value));
    navigate('/schools/add');
  };

  const items = data?.data ?? [];
  const total = data?.total ?? 0;

  return (
    <>
      <div className="text-center" style={{ marginBottom: '3rem' }}>
        <h1>Let's join or create a school</h1>
      </div>
      <h2>Enter your school's name</h2>
      <SearchStep
        onAdd={handleAddClick}
        addText={schoolName => (schoolName ? `Add "${schoolName}"` : 'Add')}
        searchBy={school => (school.name ? school.name.toLowerCase() : '')}
        searchInputText={schoolName}
        searchText="Your school"
        loading={loading}
        items={items}
        renderItem={renderItem}
        hideAddCardIfNoSearchQuery
        hideAddCardIfSearchEqualToField
        onSearchInputChange={handleSearchInputChange}
        noFilters
        //loadMore={loadMoreSchools}
        //hasMore={schools.hasNext}
        total={total}
      />
    </>
  );
};

export default SchoolSearch;
