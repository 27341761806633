import styled from 'styled-components';
import { keyframes } from 'styled-components';

const PulseIcon = ({ colour, radius, ...props }) => (
  <StyledPulse colour={colour} radius={radius} {...props}></StyledPulse>
);

const animate_pulse = props => keyframes`
    0% {
        box-shadow: 0 0 0 0 ${props.transparent};
    }
    35% {
        box-shadow: 0 0 0 ${props.radius * 0.8}px transparent;
    }
    50% {
        box-shadow: 0 0 0 ${props.radius}px transparent;
    }
    100% {
        box-shadow: 0 0 0 0 transparent;
    }
`;

const StyledPulse = styled.div`
  width: ${props => props.radius * 2}px;
  height: ${props => props.radius * 2}px;
  color: ${props => props.colour};
  background-color: ${props => props.colour};
  border-radius: 50%;
  animation: ${animate_pulse} 3s linear infinite;
`;

export default PulseIcon;
