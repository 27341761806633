import * as React from 'react';
const SvgPresent = ({ size = '16', color, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M11 16h2v-4.15l1.6 1.575L16.025 12 12 8l-4 4 1.425 1.4L11 11.825V16Zm-9 4V4h20v16H2Zm2-2h16V6H4v12Zm0 0V6v12Z"
      fill={color ?? 'currentColor'}
    />
  </svg>
);
export default SvgPresent;
