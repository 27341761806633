import styled from 'styled-components';

const Header = styled.div``;

Header.Title = styled.div`
  padding: 2rem 1.75rem 0 1.75rem;
  min-height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1 {
    margin-bottom: 0;
  }
`;

export default Header;
