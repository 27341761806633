import { useSelector } from 'react-redux';
import messageFactory from './MessageFactory';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { TypingIndicator } from './TypingIndicator';

const MessageBox = ({
  messages = [],
  sendMessage = () => {},
  currentMessage = null,
  currentFlowItemGroup = null,
  isDispatching = false,
  isReadOnly = false,
  disableScrollToBottom = false,
  botAvatar
}) => {
  const bottomRef = useRef();
  const userId = useSelector(s => s.user.userId);
  const [activeMessage, setActiveMessage] = useState(null);
  const [messageLength, setMessageLength] = useState(0);

  const scrollToBottom = useCallback(
    () =>
      !disableScrollToBottom &&
      bottomRef.current &&
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' }),
    [disableScrollToBottom]
  );

  useEffect(() => {
    if (currentMessage?.text !== activeMessage?.text) {
      setActiveMessage(currentMessage);
    }
    if (messages?.length !== messageLength) {
      setMessageLength(messages?.length);
    }
    setTimeout(scrollToBottom, 200);
  }, [
    activeMessage,
    activeMessage?.text,
    currentMessage,
    messageLength,
    messages?.length,
    scrollToBottom
  ]);

  return (
    <MessageGrid>
      <AnimatePresence>
        {messages
          .map((message, index) => ({
            message,
            component: messageFactory({
              message,
              userId,
              key: `${message.text}-${message.flowItemId}-${index}`,
              isLastItem: index === messages.length - 1,
              sendMessage,
              currentFlowItemGroup,
              isDispatching,
              isReadOnly,
              botAvatar
            })
          }))
          .filter(m => !!m.component)
          .map((p, i) => {
            const { message: m, component } = p;
            return (
              <MotionWrapper
                key={`${m.flowItemId}-${i}`}
                initial={{ opacity: 0, scale: 0.7 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ scale: 0, opacity: 0 }}
                transition={{
                  delay: m.secondsDelay || 0,
                  duration: 0.3,
                  type: 'spring'
                }}>
                {component}
              </MotionWrapper>
            );
          })}
      </AnimatePresence>
      {isDispatching && <TypingIndicator />}
      <div id="bottom" ref={bottomRef} />
    </MessageGrid>
  );
};

const MotionWrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  scale: 1;
`;

const MessageGrid = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  align-items: flex-start;
  align-content: flex-start;
  flex-grow: 1;
  margin: 0 0 1rem 0;
  overflow-y: scroll;

  /* Enable momentum scrolling on iOS devices */
  -webkit-overflow-scrolling: touch;

  /* Hide the scrollbar, but still allow scrolling */
  -ms-overflow-style: none; /* Hide scrollbar on IE and Edge */
  scrollbar-width: none; /* Hide scrollbar on Firefox */

  /* Add a custom scrollbar for non-touch devices */
  &::-webkit-scrollbar {
    width: 6px;
    margin: 0 0 1rem 0;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  border-radius: 5px;
  padding: 1rem;
  background-color: #fff;

  .message {
    display: flex;
    color: #fff;
  }

  .left {
    align-self: flex-start;
    div {
      background-color: var(--gray-lightest);
      color: rgb(5, 0, 56);
      p {
        font-weight: 400;
      }
    }
    width: 70%;
  }

  .right {
    align-self: flex-end;
    div {
      background-color: var(--green-lighter);
      color: rgb(5, 0, 56);
      p {
        font-weight: 400;
      }
    }
    justify-content: flex-end;
    width: 70%;
  }
`;

export default MessageBox;
