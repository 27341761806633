import { FormFeedback, Input as ReactstrapInput, Label } from 'reactstrap';

const Input = ({ label, name, error, size, disabled, ...props }) => {
  return (
    <>
      {label && <Label for={name}>{label}</Label>}
      {props.optional && (
        <span className="text-muted float-right">(Optional)</span>
      )}
      <ReactstrapInput
        {...props}
        name={name}
        invalid={!!error}
        optional={props.optional ? 'true' : 'false'}
        value={props.value || ''}
        disabled={disabled}
        bsSize={size === 'large' ? 'lg' : size === 'small' ? 'sm' : undefined}
      />
      {error && <FormFeedback>{error}</FormFeedback>}
    </>
  );
};

export default Input;
