import stringToColor from 'helpers/stringToColor';

const GroupIcon = ({
  groupId = null,
  width = 32,
  height = 32,
  fill = null
}) => {
  const groupColors = [
    '#91AFB7',
    '#CD82FE',
    '#74E0C6',
    '#5D5BCD',
    '#B56C45',
    '#9BA768',
    '#42CBD3',
    '#FAE950',
    '#EF56BB',
    '#F53838',
    '#09C18A',
    '#7661F9',
    '#36ABFF',
    '#FEAC06'
  ];
  fill = !fill
    ? !!groupId
      ? stringToColor(`${groupId}`, groupColors)
      : '#000'
    : fill;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="16" fill={fill} />
      <path
        d="M23.5686 20.246C24.519 20.246 25.2896 19.5268 25.2896 18.6397C25.2896 17.7525 24.519 17.0334 23.5686 17.0334C22.6181 17.0334 21.8475 17.7525 21.8475 18.6397C21.8475 19.5268 22.6181 20.246 23.5686 20.246Z"
        fill="white"
      />
      <path
        d="M22.7454 20.7052C21.6162 20.7052 20.7008 21.6206 20.7008 22.7497V23.7925C20.7008 24.1152 20.9624 24.3767 21.285 24.3767H25.8534C26.176 24.3767 26.4376 24.1152 26.4376 23.7925V22.7497C26.4376 21.6206 25.5222 20.7052 24.393 20.7052H22.7454Z"
        fill="white"
      />
      <path
        d="M23.5686 10.8376C24.519 10.8376 25.2896 10.1184 25.2896 9.23129C25.2896 8.34416 24.519 7.625 23.5686 7.625C22.6181 7.625 21.8475 8.34416 21.8475 9.23129C21.8475 10.1184 22.6181 10.8376 23.5686 10.8376Z"
        fill="white"
      />
      <path
        d="M22.7454 11.2968C21.6162 11.2968 20.7008 12.2122 20.7008 13.3414V14.3842C20.7008 14.7068 20.9624 14.9683 21.285 14.9683H25.8534C26.176 14.9683 26.4376 14.7068 26.4376 14.3842V13.3414C26.4376 12.2122 25.5222 11.2968 24.393 11.2968H22.7454Z"
        fill="white"
      />
      <path
        d="M15.9987 20.246C16.9492 20.246 17.7197 19.5268 17.7197 18.6397C17.7197 17.7525 16.9492 17.0334 15.9987 17.0334C15.0482 17.0334 14.2776 17.7525 14.2776 18.6397C14.2776 19.5268 15.0482 20.246 15.9987 20.246Z"
        fill="white"
      />
      <path
        d="M15.1755 20.7052C14.0463 20.7052 13.1309 21.6206 13.1309 22.7497V23.7925C13.1309 24.1152 13.3925 24.3767 13.7151 24.3767H18.2835C18.6061 24.3767 18.8677 24.1152 18.8677 23.7925V22.7497C18.8677 21.6206 17.9523 20.7052 16.8231 20.7052H15.1755Z"
        fill="white"
      />
      <path
        d="M15.9987 10.8376C16.9492 10.8376 17.7197 10.1184 17.7197 9.23129C17.7197 8.34416 16.9492 7.625 15.9987 7.625C15.0482 7.625 14.2776 8.34416 14.2776 9.23129C14.2776 10.1184 15.0482 10.8376 15.9987 10.8376Z"
        fill="white"
      />
      <path
        d="M15.1755 11.2968C14.0463 11.2968 13.1309 12.2122 13.1309 13.3414V14.3842C13.1309 14.7068 13.3925 14.9683 13.7151 14.9683H18.2835C18.6061 14.9683 18.8677 14.7068 18.8677 14.3842V13.3414C18.8677 12.2122 17.9523 11.2968 16.8231 11.2968H15.1755Z"
        fill="white"
      />
      <path
        d="M8.42632 20.246C9.37682 20.246 10.1473 19.5268 10.1473 18.6397C10.1473 17.7525 9.37682 17.0334 8.42632 17.0334C7.47582 17.0334 6.7053 17.7525 6.7053 18.6397C6.7053 19.5268 7.47582 20.246 8.42632 20.246Z"
        fill="white"
      />
      <path
        d="M7.60316 20.7052C6.47398 20.7052 5.55859 21.6206 5.55859 22.7497V23.7925C5.55859 24.1152 5.82013 24.3767 6.14276 24.3767H10.7112C11.0338 24.3767 11.2953 24.1152 11.2953 23.7925V22.7497C11.2953 21.6206 10.38 20.7052 9.25078 20.7052H7.60316Z"
        fill="white"
      />
      <path
        d="M8.42632 10.8376C9.37682 10.8376 10.1473 10.1184 10.1473 9.23129C10.1473 8.34416 9.37682 7.625 8.42632 7.625C7.47582 7.625 6.7053 8.34416 6.7053 9.23129C6.7053 10.1184 7.47582 10.8376 8.42632 10.8376Z"
        fill="white"
      />
      <path
        d="M7.60316 11.2968C6.47398 11.2968 5.55859 12.2122 5.55859 13.3414V14.3842C5.55859 14.7068 5.82013 14.9683 6.14276 14.9683H10.7112C11.0338 14.9683 11.2953 14.7068 11.2953 14.3842V13.3414C11.2953 12.2122 10.38 11.2968 9.25078 11.2968H7.60316Z"
        fill="white"
      />
    </svg>
  );
};

export default GroupIcon;
