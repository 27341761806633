import styled from 'styled-components';

// Temp fix for https://bugs.chromium.org/p/chromium/issues/detail?id=596223
const StyledSpan = styled.span`
  @media not screen and (min-device-pixel-ratio: 2),
    not screen and (min-resolution: 192dpi) {
    span.emoji {
      margin-right: 5px;
    }
  }
`;

const EmojiSpan = ({ ariaLabel, emoji, children, ...props }) => {
  return (
    <StyledSpan {...props} role="img" aria-label={ariaLabel}>
      {emoji || children}
    </StyledSpan>
  );
};

export default EmojiSpan;
