import { Image, ProgressBar } from 'components/common';
import useStateReducer from 'hooks/useCustomReducer';
import { useRef } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import playIcon from 'assets/icons/svg/playIcon.svg';
import { useEffect } from 'react';

const useVideoPlayer = ({
  isEnabled = true,
  onFinished = () => {},
  thumbnailUrl = null,
  src = null,
  useNativeControls = false,
  ...props
}) => {
  const { state, setState } = useStateReducer({
    stateName: 'videoState',
    initialState: {
      played: 0,
      playing: false,
      duration: 0,
      isThumbnailExist: undefined
    }
  });
  const videoRef = useRef(null);

  const onProgress = state => {
    if (state.played === 1) {
      onFinished();
    }
    setState(state);
  };

  const onError = state => {
    console.error('onError', state);
    onFinished();
    setState(state);
  };

  useEffect(() => {
    if (thumbnailUrl && state.isThumbnailExist === undefined) {
      setState({ isThumbnailExist: true });
    }
  }, [setState, state.isThumbnailExist, thumbnailUrl]);

  if ((!isEnabled && state.played !== 1) || !src)
    return {
      video: null,
      state: {
        ...state,
        notLoaded: true
      }
    };

  return {
    video: (
      <Root>
        <ReactPlayer
          {...props}
          playing={state.playing}
          ref={videoRef}
          onClickPreview={() => setState({ playing: true })}
          light={
            state.isThumbnailExist ? (
              <Image src={thumbnailUrl} alt="Thumbnail" />
            ) : (
              false
            )
          }
          url={src}
          controls={useNativeControls}
          width="100%"
          height="100%"
          onProgress={onProgress}
          onDuration={onProgress}
          onError={onError}
          volume={0.7}
          playIcon={
            <PlayIcon>
              <img src={playIcon} alt="play" />
            </PlayIcon>
          }
        />
        {!useNativeControls && (state.played > 0 || state.playing) && (
          <ProgressBarWrapper>
            <ProgressBar
              style={{ width: '100%' }}
              currentIndex={state.played * 100}
              total={100}
            />
          </ProgressBarWrapper>
        )}
      </Root>
    ),
    state
  };
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const PlayIcon = styled.div`
  width: 80px;
  height: 80px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 80px;
    height: 80px;
  }
`;

const ProgressBarWrapper = styled.div`
  #progressbar {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export default useVideoPlayer;
