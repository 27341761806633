import { Outlet } from 'react-router';
import Navigation from 'components/Navigation';
import { useLocation } from 'react-router-dom';
import { Button, Container, EediPlusLogo, Logo, Nav } from 'components/common';
import { MARKETING_SITE_URL } from 'config/settings';
import { Suspense } from 'react';
import { colors } from 'styles';
import styled from 'styled-components';

const SignupLayout = () => {
  const location = useLocation();
  const pathParts = location.pathname.split('/');
  const isLoginPage = pathParts[1] !== 'signup';

  return (
    <Background>
      <Navigation
        leftComponent={
          <Nav.Brand to={MARKETING_SITE_URL}>
            <Logo color={colors.white} />
            <EediPlusLogo className="ml-2" text="Beta" lightTheme />
          </Nav.Brand>
        }
        rightComponent={
          <div className="text-right text-light d-flex align-items-center">
            {isLoginPage ? (
              <>
                <span className="mr-3">Don't have an account?</span>
                <Button to="/signup" color="primary" outline>
                  Sign up
                </Button>
              </>
            ) : (
              <>
                <span className="mr-3">Already have an account?</span>
                <Button to="/login" color="primary" outline>
                  Login
                </Button>
              </>
            )}
          </div>
        }
      />
      <Container className="container-xs">
        <Suspense>
          <Outlet />
        </Suspense>
      </Container>
    </Background>
  );
};

const Background = styled.div`
  min-height: 100vh;
  background: linear-gradient(
    308.66deg,
    var(--indigo-light) 3.96%,
    var(--purple-light) 100%
  );
  background-size: cover;
`;

export default SignupLayout;
