import React, { Component } from 'react';
import styled from 'styled-components';
import Popover from 'react-popover';
import Pill from './Pill';
import TextXS from './TextXS';

class Select extends Component {
  static defaultProps = {
    underline: true,
    canAdd: true,
    multiselect: true
  };

  state = { input: '', inputFocused: false };

  handleKeydown = e => {
    if (e.keyCode === 9 || e.keyCode === 13) {
      this.addNewItem();
    } else if (e.keyCode === 8 && !this.state.input) {
      this.removeLastSelected();
    } else if (e.keyCode === 27) this.blur();
  };

  removeLastSelected = () => {
    const selected = this.props.options.filter(o => o.isSelected);
    const l = selected.length;
    if (l < 1) return;
    this.handleSelect(selected[l - 1]);
  };

  removeItem = item => {
    this.handleSelect(item);
  };

  handleChange = e => {
    this.setState({ input: e.target.value });
  };

  addNewItem = () => {
    const { canAdd, onAdd, multiselect } = this.props;
    const { input } = this.state;
    const trimmed = input.trim();
    if (!canAdd || !trimmed.length) return;
    if (onAdd && typeof onAdd === 'function') {
      const found = this.props.options
        .filter(o => !o.isSelected)
        .find(o => o.label === trimmed);
      if (found) {
        this.handleSelect(found);
      } else {
        const newItem = {
          id: Date.now(),
          label: this.state.input,
          isSelected: true
        };
        onAdd(newItem);
        if (!multiselect) {
          this.blur();
        } else {
          this.focus();
        }
      }
      this.setState({ input: '' });
    }
  };

  handleSelect = item => {
    this.props.onSelect(item);
    if (!this.props.multiselect) {
      this.blur();
    } else {
      this.focus();
    }
  };

  focus = () => this._input.focus();

  blur = () => {
    this._input.blur();
    this.setState({ inputFocused: false, input: '' });
  };

  render() {
    const { placeholder, options, label, canAdd, error, popoverWidth } =
      this.props;
    const { input, inputFocused } = this.state;
    const selectedOptions = options.filter(o => o.isSelected);
    const unselectedOptions = options.filter(o => !o.isSelected);
    const dropdownOptions = unselectedOptions.filter(o =>
      o.label.toLowerCase().includes(input.toLowerCase())
    );
    const showAddOption =
      canAdd && input && !dropdownOptions.some(o => o.label === input);
    return (
      <div>
        <Wrapper error={error}>
          <TextXS color="grayLight">{label}</TextXS>
          <Pop
            place="below"
            isOpen={inputFocused}
            tipSize={0.1}
            onOuterAction={this.blur}
            width={popoverWidth}
            body={
              <OptionsContainer>
                {dropdownOptions.map(o => (
                  <Option key={o.id} onClick={() => this.handleSelect(o)}>
                    <span>{o.label}</span>
                  </Option>
                ))}
                {showAddOption && (
                  <Option onClick={this.addNewItem}>
                    <span>{`Press enter or click here to add ${input}`}</span>
                  </Option>
                )}
              </OptionsContainer>
            }>
            <InputWrapper>
              {selectedOptions.map(o => (
                <PillContainer key={o.id}>
                  <Pill
                    iconName="closeOutline"
                    selected
                    label={o.label}
                    onClick={() => this.removeItem(o)}
                  />
                </PillContainer>
              ))}
              <Input
                innerRef={ref => (this._input = ref)}
                value={input}
                placeholder={
                  selectedOptions.length
                    ? ''
                    : placeholder ||
                      `Start typing to search ${canAdd ? 'or add' : ''}`
                }
                onKeyDown={this.handleKeydown}
                onChange={this.handleChange}
                onFocus={() => this.setState({ inputFocused: true })}
                selectedOptions={selectedOptions}
              />
            </InputWrapper>
          </Pop>
        </Wrapper>
        {error && <TextXS color="red">{error}</TextXS>}
      </div>
    );
  }
}

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  border-bottom: ${props =>
    `2px solid ${props.error ? 'var(--red)' : 'var(--gray-lighter)'}`};
`;

const Input = styled.input`
  padding: 0.25rem 0rem 0.25rem 0rem;
  background: transparent;
  display: flex;
  flex: 1;
  font-size: 1rem;
  color: var(--gray-dark);
  outline: none;
  border: 0;
  border-radius: 0;
  margin: ${props =>
    `1rem 0rem 0.75rem ${props.selectedOptions.length ? '0.5rem' : '0'}`};

  /* Cross browser compatibility */
  &::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: var(--gray);
  }
  -webkit-appearance: none;

  &::-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: var(--gray);
    opacity: 1;
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: var(--gray);
    opacity: 1;
  }
  &::-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--gray);
  }
`;

const PillContainer = styled.div`
  margin: 0.5rem 0.25rem 0 0;
`;

const InputWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const OptionsContainer = styled.div`
  box-shadow: 0rem 0.25rem 0.625rem 0rem rgba(55, 52, 65, 0.1);
  border-radius: 0.125rem;
  background: var(--white);
  z-index: 100;
`;

const Option = styled.div`
  padding: 0.5rem 1rem;
  background: white;
  cursor: pointer;
  &:hover {
    background-color: var(--tint-color);
  }
`;

const Pop = styled(Popover)`
  z-index: 100;
  width: ${props => props.width};
`;

export default Select;
