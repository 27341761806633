import reducers from 'reducers';
import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore } from 'redux-persist';
import ReduxThunk from 'redux-thunk';
import Cache from 'cache/Cache';

const middleware = [ReduxThunk];

if (process.env.NODE_ENV === 'development') {
  const { createLogger } = require('redux-logger');
  const logger = createLogger({ collapsed: true });
  middleware.push(logger);
}

const configureStore = onComplete => {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    reducers,
    {},
    composeEnhancers(applyMiddleware(...middleware))
  );
  const persistor = persistStore(store, null, onComplete);

  return { store, persistor, cache: new Cache() };
};

export default configureStore;
