function ChallengeSilver() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="3rem"
      height="3rem"
      fill="none"
      viewBox="0 0 47 48">
      <path
        d="M21.1947 1.99221C22.7417 1.09904 24.6477 1.09904 26.1947 1.9922L41.3208 10.7253C42.8679 11.6184 43.8209 13.2691 43.8209 15.0554V32.5215C43.8209 34.3079 42.8679 35.9585 41.3209 36.8517L26.1947 45.5847C24.6477 46.4779 22.7417 46.4779 21.1947 45.5847L6.0686 36.8517C4.5216 35.9585 3.5686 34.3079 3.5686 32.5215V15.0554C3.5686 13.2691 4.52159 11.6184 6.0686 10.7253L21.1947 1.99221Z"
        fill="white"
      />
      <path
        d="M21.1945 5.34767C22.7415 4.45451 24.6474 4.45451 26.1944 5.34767L38.4153 12.4034C39.9623 13.2965 40.9153 14.9472 40.9153 16.7335V30.8449C40.9153 32.6312 39.9623 34.2818 38.4153 35.175L26.1944 42.2307C24.6474 43.1238 22.7415 43.1238 21.1944 42.2307L8.97364 35.175C7.42663 34.2818 6.47364 32.6312 6.47364 30.8449V16.7335C6.47364 14.9472 7.42663 13.2965 8.97364 12.4034L21.1945 5.34767Z"
        fill="#C8C8C8"
      />
      <path
        d="M20.6948 8.52111C22.5512 7.44932 24.8384 7.44932 26.6948 8.52111L35.4173 13.557C37.2737 14.6288 38.4173 16.6096 38.4173 18.7532V28.825C38.4173 30.9686 37.2737 32.9493 35.4173 34.0211L26.6948 39.057C24.8384 40.1288 22.5512 40.1288 20.6948 39.057L11.9724 34.0211C10.116 32.9493 8.97239 30.9686 8.97239 28.825V18.7532C8.97239 16.6096 10.116 14.6288 11.9724 13.557L20.6948 8.52111Z"
        fill="url(#paint0_linear_12282_23345)"
      />
      <path
        d="M17.8325 28.0411C14.7365 28.0411 13.1885 26.2411 13.1885 24.4411C13.1885 22.6951 14.3585 21.6511 15.4925 21.3451C14.4665 20.8951 13.5485 19.9951 13.5485 18.5731C13.5485 16.8451 15.0245 15.1891 17.8325 15.1891C20.6405 15.1891 22.1165 16.8451 22.1165 18.5731C22.1165 19.9951 21.1985 20.8951 20.1725 21.3451C21.3065 21.6511 22.4765 22.6951 22.4765 24.4411C22.4765 26.2411 20.9285 28.0411 17.8325 28.0411ZM19.5965 18.8071C19.5965 17.7811 18.7865 17.2231 17.8325 17.2231C16.8785 17.2231 16.0685 17.7811 16.0685 18.8071C16.0685 19.8691 16.9325 20.3911 17.8325 20.3911C18.7325 20.3911 19.5965 19.8691 19.5965 18.8071ZM17.8325 25.9891C19.0385 25.9891 19.8845 25.3231 19.8845 24.2071C19.8845 23.0911 19.0385 22.4431 17.8325 22.4431C16.6265 22.4431 15.7805 23.1091 15.7805 24.2071C15.7805 25.3051 16.6265 25.9891 17.8325 25.9891ZM24.1435 24.8731L26.5375 24.2071C26.5915 25.1611 27.3835 25.9711 28.4995 25.9711C29.7595 25.9711 30.5335 24.9991 30.5335 23.6851C30.5335 22.5151 29.9575 21.5431 28.6435 21.5431C28.1035 21.5431 27.1855 21.7051 26.7535 22.6951L24.5215 22.2811L25.2775 15.4411H32.4415L32.0815 17.5291H27.1495L26.7895 20.4631C27.2575 19.8691 28.0315 19.4731 29.2375 19.4731C31.9195 19.4731 33.1615 21.5971 33.1615 23.6491C33.1615 26.0971 31.4155 28.0411 28.4635 28.0411C25.7995 28.0411 24.2335 26.4031 24.1435 24.8731Z"
        fill="#8A8A8A"
      />
      <path
        d="M21.6712 36.0306H20.5012L24.8812 28.7506H26.0512L21.6712 36.0306ZM22.4212 30.7706C22.4212 31.6806 21.9912 32.6506 20.7112 32.6506C19.4312 32.6506 19.0012 31.6806 19.0012 30.7706C19.0012 29.8606 19.4312 28.8906 20.7112 28.8906C21.9912 28.8906 22.4212 29.8606 22.4212 30.7706ZM20.0512 30.7706C20.0512 31.3506 20.2512 31.7806 20.7112 31.7806C21.1712 31.7806 21.3712 31.3506 21.3712 30.7706C21.3712 30.1906 21.1712 29.7606 20.7112 29.7606C20.2512 29.7606 20.0512 30.1906 20.0512 30.7706ZM27.4512 34.1506C27.4512 35.0606 27.0212 36.0306 25.7412 36.0306C24.4612 36.0306 24.0312 35.0606 24.0312 34.1506C24.0312 33.2406 24.4612 32.2706 25.7412 32.2706C27.0212 32.2706 27.4512 33.2406 27.4512 34.1506ZM25.0812 34.1506C25.0812 34.7306 25.2812 35.1606 25.7412 35.1606C26.2012 35.1606 26.4012 34.7306 26.4012 34.1506C26.4012 33.5706 26.2012 33.1406 25.7412 33.1406C25.2812 33.1406 25.0812 33.5706 25.0812 34.1506Z"
        fill="#8A8A8A"
      />
      <defs>
        <linearGradient
          id="paint0_linear_12282_23345"
          x1="12.4133"
          y1="9.48278"
          x2="28.7915"
          y2="41.8297"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E2E2E2" />
          <stop offset="1" stopColor="#D3D3D3" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default ChallengeSilver;
