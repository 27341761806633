import { CustomInput } from 'reactstrap';
import styled from 'styled-components';

const Checkbox = ({ id, label, name, ...props }) => {
  return (
    <StyledCustomInput
      id={id}
      name={name}
      type="checkbox"
      label={label}
      {...props}
      value={props.value || ''}
    />
  );
};

const StyledCustomInput = styled(CustomInput)`
  ${({ disabled }) => (disabled ? `opacity: 0.25;` : '')}
`;

export default Checkbox;
