import { Container } from 'reactstrap';
import styled from 'styled-components';

const NotificationContainer = styled.div`
  z-index: 100;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--white);
  box-shadow: 0px -0.5rem 1.5rem rgba(5, 0, 56, 0.1);
  padding: 1rem 0;
  max-height: 8rem;
  ${({ zIndex }) => (zIndex !== undefined ? `z-index: ${zIndex};` : '')}

  transition: 0.5s;
  transform: translateY(100%);

  ${({ show }) => (show ? 'transform: none;' : '')}
`;

/* The 4rem is because we assume we're in a
 `<main>` which has a bottom padding of 4rem, ergo
 8rem - 4rem = 4rem */
const Spacer = styled.div`
  height: 0;
  transition: 0.5s;
  ${({ show }) => (show ? 'height: 4rem;' : '')}
`;
export const BottomNotification = ({
  show,
  children,
  zIndex,
  reserveSpace = false
}) => {
  return (
    <>
      {reserveSpace && <Spacer show={show} />}
      <NotificationContainer show={show} zIndex={zIndex}>
        <Container>{children}</Container>
      </NotificationContainer>
    </>
  );
};

export default BottomNotification;
