import styled from 'styled-components';
import { colors } from 'styles';

const FilledCircle = styled.div`
  display: flex;
  flex-shrink: 0;
  width: ${({ size }) => size}rem;
  height: ${({ size }) => size}rem;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background-color: ${({ color }) => colors[color]};
`;

export default FilledCircle;
