import Checkbox from '../Checkbox';

export const TableRows = ({
  rows,
  prepareRow,
  enableBulkSelection = true,
  selectedRowIds = [],
  setRowSelectedState = () => {}
}) =>
  rows.map(row => {
    prepareRow(row);
    return (
      <tr {...row.getRowProps()} key={row.id}>
        {enableBulkSelection && (
          <td>
            <Checkbox
              id={`data-table-select-${row.id}`}
              checked={selectedRowIds.indexOf(row.id) !== -1}
              onChange={e => setRowSelectedState(row.id, e.target.checked)}
            />
          </td>
        )}

        {row.cells.map((cell, i) => {
          const content = cell.render('Cell');
          const id = `row-${row.id}-cell-${i}`;
          const isContentString = typeof cell.value === 'string';
          return (
            <td
              {...cell.getCellProps()}
              id={id}
              key={id}
              className={isContentString ? 'hide-overflow' : ''}
              style={{
                ...cell.getCellProps()?.style,
                ...cell.column?.cellStyle
              }}>
              {content}
            </td>
          );
        })}
      </tr>
    );
  });

export default TableRows;
