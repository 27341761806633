import { css } from 'styled-components';

export const sizes = {
  xxl: 1400,
  xl: 1200,
  lg: 992,
  md: 768,
  sm: 576
};
// iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((accumulator, label) => {
  const size = sizes[label];
  accumulator[label] = (...args) => css`
    @media (min-width: ${size}px) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});
