import { Input as ReactstrapInput, FormFeedback, Label } from 'reactstrap';
import Info from './Info';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';

const DateInput = ({
  label,
  name,
  error,
  value,
  tooltip,
  disabled,
  ...props
}) => {
  const calendar = (
    <Calendar
      defaultValue={value}
      showDateInput={false}
      showToday={false}
      disabledDate={props.disabledDate}
    />
  );

  return (
    <>
      {label && (
        <Label for={name}>
          {label}
          {tooltip && (
            <>
              {' '}
              <Info id={`tooltip${name}`} tooltip={tooltip ?? ''} />
            </>
          )}
        </Label>
      )}
      {disabled ? (
        <ReactstrapInput
          disabled={true}
          value={value.format('Do MMMM YYYY')}
          placeholder={props.placeholder}
        />
      ) : (
        <DatePicker calendar={calendar} value={value} {...props}>
          {date => {
            return (
              <ReactstrapInput
                value={date?.value?.format('Do MMMM YYYY') ?? '-'}
                placeholder={props.placeholder}
              />
            );
          }}
        </DatePicker>
      )}

      {error && <FormFeedback>{error}</FormFeedback>}
    </>
  );
};

export default DateInput;
