const initialState = {
  tab: 'Upcoming',
  type: null,
  groupIds: [],
  selectedGroup: '',
  selectedAssignmentStatus: 'Done'
};

const filters = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DASHBOARD_TAB':
      return {
        ...state,
        tab: action.payload
      };
    case 'SET_DASHBOARD_TYPE':
      return {
        ...state,
        type: action.payload
      };
    case 'SET_DASHBOARD_FILTERS':
      return {
        ...state,
        groupIds: handleGroupSelect(state.groupIds, action.payload)
      };
    case 'CLEAR_DASHBOARD_FILTERS':
      return { ...state, groupIds: [] };
    case 'SET_SELECTED_GROUP':
      return {
        ...state,
        selectedGroup: action.payload
      };
    case 'SET_SELECTED_ASSIGNMENT_STATUS':
      return {
        ...state,
        selectedAssignmentStatus: action.payload
      };
    case 'SET_QUIZ_DATE_FILTER':
      return {
        ...state,
        quizDate: action.payload
      };
    case 'SET_SEARCH_TEXT':
      return {
        ...state,
        searchText: action.payload
      };
    default:
      return state;
  }
};

export default filters;

const handleGroupSelect = (groupIds, group) => {
  if (group.isSelected) return groupIds.filter(id => id !== group.id);
  return [...groupIds, group.id];
};
