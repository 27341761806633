import { Container } from 'components/common';
import { Outlet } from 'react-router';
import styled from 'styled-components';

const ExternalQuizPage = () => {
  return (
    <Body>
      <Container>
        <Outlet />
      </Container>
    </Body>
  );
};

const Body = styled.div`
  background: #faf3ff;
  padding: 0.5rem;
`;

export default ExternalQuizPage;
