import styled from 'styled-components';
import ChatBubble from '../components/ChatBubble';
import FormattedTextContent from './FormattedTextContent';

const HighlightedText = ({ message, fromdirection, botAvatar = null }) => {
  return (
    <ChatBubble
      fromdirection={fromdirection}
      userId={message.userId}
      text={message.text}
      botAvatar={botAvatar}>
      <Quote>
        <FormattedTextContent content={message.text} />
      </Quote>
    </ChatBubble>
  );
};

const Quote = styled.div`
  border-left: 0.2rem solid #aaa;
  padding-left: 1rem;
`;

export default HighlightedText;
