export const login = refreshToken => ({
  type: 'LOGIN',
  refreshToken
});

export const setGoogleLoginData = ({
  googleToken,
  firstName,
  lastName,
  email,
  title,
  userRole
}) => ({
  type: 'SET_GOOGLE_DATA',
  data: { googleToken, firstName, lastName, email, title, userRole }
});

export const signupStart = () => ({ type: 'SIGNUP_START' });

export const signupError = message => ({
  type: 'SIGNUP_ERROR',
  message
});

export const logout = () => ({ type: 'LOGOUT' });
